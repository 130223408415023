import React from "react";
import Utilitario from "../Utilitario";
import Uniformes from "../Uniformes";
import FormEditCondicionesLaborales from "./EditForms/FormEditCondicionesLaborales";
import FormEditDatosPersonales from "./EditForms/FormEditDatosPersonales";
import FormEditInformacionNomina from "./EditForms/FormEditInformacionNomina";
import FormEditSaludLaboral from "./EditForms/FormEditSaludLaboral";
import Vacaciones from "../Vacaciones";
import ExpedienteDigital from "../ExpedienteDigital";
import PermisosLaborales from "../PermisosLaborales";
import Incapacidades from "../Incapacidades";
import PrestacionesOrdinarias from "../Prestaciones/PrestacionesOrdinarias";
import DescuentosSalario from '../DescuentosSalario';
import DescuentosInfonativt from '../DescuentosInfonavit';
import ActasAdministrativas from '../ActasAdministrativas'
import SimuladorSalarial from "../SimuladorSalarial/SimuladorSalarial";
import Promociones from "../Promociones";

const EditForms = React.memo(({ form = 0, disabled = false }) => {
  let component = null;
  switch (form) {
    case 1:
      component = <FormEditDatosPersonales disabled={disabled} />;
      break;
    case 2:
      component = <FormEditCondicionesLaborales disabled={disabled} />;
      break;
    case 3:
      component = <FormEditInformacionNomina disabled={disabled} />;
      break;
    case 4:
      component = <DescuentosSalario disabled={disabled} />;
      break;
    case 5:
      component = <Utilitario disabled={disabled} />;
      break;
    case 6:
      component = <Uniformes disabled={disabled} />;
      break;
    case 7:
      component = <ExpedienteDigital disabled={disabled} />;
      break;
    case 8:
      component = <Vacaciones disabled={disabled} />;
      break;
    case 9:
      component = <PermisosLaborales disabled={disabled} />;
      break;
    case 10:
      component = <Incapacidades disabled={disabled} />;
      break;
    case 11:
      component = <FormEditSaludLaboral disabled={disabled} />;
      break;
    case 12:
      component = <PrestacionesOrdinarias disabled={disabled} />;
      break;
    case 13:
      component = <SimuladorSalarial disabled={disabled} />;
      break;
    case 14:
      component = <ActasAdministrativas disabled={disabled} />;
      break;
    case 15:
      component = <DescuentosInfonativt disabled={disabled} />;
      break;
    case 16:
      component = <Promociones disabled={disabled} />;
      break;
    default:
      component = null;
  }
  return <>{component}</>;
});

export default EditForms;
