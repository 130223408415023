import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, InputGroupAddon, InputGroupText, InputGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TimbrarResumen from "./TimbrarResumen";
import { StyleInputs, HeaderStyle, Card } from "../ui/styles/StyleNomina";

import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import jsZip from "jszip";
import { saveAs } from "file-saver";
import { text } from "@fortawesome/fontawesome-svg-core";

const api = process.env.REACT_APP_API_URL;

const Timbrar = () => {
  const { empresas } = useSelector((state) => state.empresas);
  const [empresa, setEmpresa] = useState({ informacionLegal: {} });
  const [colaboradoresSelect, setColaboradoresSelect] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [diaPago, setDiaPago] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [tabType, setTabType] = useState("timbrar");
  const location = useLocation();
  const [errores, setErrores] = useState([]);
  const { state } = location;
  const parameters = useParams();
  const [totales, setTotales] = useState({
    totalTimbrados: 0,
    totalErrores: 0,
    timbresDisponibles: 0,
  });
  const [distSalario, setDistSalario] = useState([]);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {
    let e = empresas.find((e) => e._id === parameters.idEmpresa) || {};
    setTotales({ ...totales, timbresDisponibles: parseInt(e.timbresDisponibles ? e.timbresDisponibles : 0) });
    setEmpresa(e);
    obtenerColaboradores();
  }, []);

  const obtenerColaboradores = async (nombre = "") => {
    console.log(state);
    let query = `empresaId=${parameters.idEmpresa}`;
    if (!state.periodicidad.includes('Extraordinaria')) (query += `&periodicidad=${state.periodicidad}&fechaInicio=${state.fechaInicio}`)
    else (query += `&periodicidad=${state.periodicidad}&periodo=${state.periodo}&type=${state.type}`)
    let { data } = await axios.get(`${api}/nomina/aprobadas/?${query}`, headers);
    setDistSalario([...data.response.dist]);
    setColaboradores([...data.response.res]);
  };

  const onChangeSelected = (selected) => {
    let data = Object.keys(selected).filter((k) => selected[k]);
    setColaboradoresSelect(data);
  };

  const onCorregir = async () => {
    await axios.post(
      `${api}/nomina/estado/?periodicidad=${state.periodicidad}&fechaInicio=${state.fechaInicio}&estado=revision&empresaId=${parameters.idEmpresa}`,
      colaboradoresSelect,
      headers
    );
    obtenerColaboradores();
  };

  const solicitarTimbres = async () => {
    try {
      const { data } = await axios.get(`${api}/nomina/solicitud-timbres/${parameters.idEmpresa}`, headers);
      Swal.fire({
        title: "Timbres solicitados",
        text: data.msg,
        icon: 'success'
      })
    } catch (error) {
      Swal.fire({
        title: "Ocurrió un problema",
        text: error.message,
        icon: 'warning'
      })
    }
  }

  const onCorregirDialog = async () => {
    let result = await Swal.fire({
      title: "Corregir",
      text: "Desea corregir los colaboradores seleccionados?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      onCorregir();
    }
  };
  const onTimbrar = async () => {
    setShowDate(false);
    Swal.showLoading();
    if (totales.timbresDisponibles < colaboradoresSelect.length) {
      Swal.close()
      Swal.fire("Timbrar", "No cuenta con los timbres necesarios para realizar esta acción. Solicite más timbres e intente de nuevo", "error")
      return
    }

    try {
      let query = `periodicidad=${state.periodicidad}&diaPago=${diaPago}&empresaId=${parameters.idEmpresa}`;
      if (!state.periodicidad.includes('Extraordinaria')) (query += `&fechaInicio=${state.fechaInicio}&fechaFin=${state.fechaFin}`)
      else if (state.type === 'finiquito') {
        (query += `&type=${state.type}`)
      } else {
        (query += `&periodo=${state.periodo}&type=${state.type}`)
      }

      let { data } = await axios.post(`${api}/nomina/timbrar/?${query}`, colaboradoresSelect, headers);
      console.log(data);
      setTotales({ totalTimbrados: data.response.totalTimbrados, totalErrores: data.response.totalErrores, timbresDisponibles: data.response.timbresDisponibles });
      setColaboradores([...data.response.result]);
      if (data.response.error.length) {
        setErrores(data.response.error);
      }
      Swal.close();
      Swal.fire("Timbrar", data.msg, "success");
    } catch (error) {
      Swal.close();
      Swal.fire("Timbrar", "Ocurrio un error en el proceso", "error");
    }
  };

  const onTimbrarDialog = async () => {
    let result = await Swal.fire({
      title: "Timbrar",
      text: `Seguro que desea iniciar el proceso de timbrado?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      await onTimbrar();
    }
  };

  const downloadAll = async () => {
    var zip = new jsZip();
    Swal.showLoading();
    colaboradores.forEach((item) => {
      if (item.xml) zip.file(`${item.nombre}-${item.numero}-${item.periodo}.xml`, item.xml, { base64: true });
      if (item.pdf) zip.file(`${item.nombre}-${item.numero}-${item.periodo}.pdf`, item.pdf, { base64: true });
    });
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `timbres ${state.fechaInicio}-${state.fechaFin}.zip`);
    });
    Swal.close();
  };

  const colorTimbres = (totalTimbres) => {
    let color = '';
    if (totalTimbres <= (colaboradores.length * 2)) (color = '#ecb30c');
    if (totalTimbres <= (colaboradores.length + (colaboradores.length / 2))) (color = 'rgb(255 60 0)');
    return color;
  }

  const generateTxt = (cols) => {
    let txtResult = []
    cols.map(cola => {
      let txt = `${cola.numero}\t${cola.nombre}\t${cola.fechaInicio}\t${cola.fechaFin}\t`
      if (cola.percepciones.ordinarias.length) {
        cola.percepciones.ordinarias.map(per => {
          txtResult.push(txt + `percepcion\t${per.codigo}\t${per.descripcion}\t${per.monto}`)
          txtResult.push("\r\n")
        })
      }

      if (cola.deducciones.ordinarias.length) {
        cola.deducciones.ordinarias.map(per => {
          txtResult.push(txt + `deduccion\t${per.codigo}\t${per.descripcion}\t${per.monto}`)
          txtResult.push("\r\n")
        })
      }

      if (cola.otrosPagos.length) {
        cola.otrosPagos.map(per => {
          txtResult.push(txt + `otro pago\t${per.codigo}\t${per.descripcion}\t${per.monto}`)
          txtResult.push("\r\n")
        })
      }

    })

    return txtResult
  }

  const downloadTxt = () => {
    console.log(colaboradores)
    if (!colaboradores.length) return
    const blob = new Blob(generateTxt(colaboradores), { type: "text/plain;charset=utf-8" })
    saveAs(blob, `nomina-${colaboradores[0].periodo}.txt`)
  }

  return (
    <>
      <Row>
        <Col>
          <HeaderStyle>
            <h1>Timbrar {state.type ? state.type.toUpperCase() : ''}</h1>
            <h3>Periodo {state.periodo} {state.fechaInicio && <span style={{ fontSize: 18 }}> De {state.fechaInicio} a {state.fechaFin}</span>}</h3>
            <h3>Razón Social</h3>
            <h2>{empresa.informacionLegal.razon_social}</h2>{" "}
          </HeaderStyle>
        </Col>
      </Row>
      <StyleInputs>
        <Row className="my-3">
          <Col md={2}>
            <Button color={"secondary"} disabled={!colaboradoresSelect.length} block onClick={() => setShowDate(!showDate)}>
              Iniciar
            </Button>
          </Col>
          <Col md={2}>
            <Button color={"secondary"} disabled={!colaboradoresSelect.length || !totales.totalTimbrados} block onClick={downloadAll}>
              Descargar Docs
            </Button>
          </Col>
          <Col md={2}>
            <Button color={"secondary"} disabled={!colaboradores.length} block onClick={downloadTxt}>
              Generar TXT
            </Button>
          </Col>
          {/* <Col md={2}>
            <Button color={"secondary"} disabled={true} block onClick={onCorregirDialog}>
              Ver Errores
            </Button>
          </Col>
          <Col md={2}>
            <Button disabled={true} block>
              Enviar Correo
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <Card text="left">
              <Row>
                <Col className="col-9">
                  <h4 className="mt-2">Timbrados {totales.totalTimbrados}</h4>
                </Col>
                <Col className="col-3 text-right">
                  <StyleInputs>
                    <Button size="sm" onClick={() => setTabType("timbrados")}>
                      Ver
                    </Button>
                  </StyleInputs>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card text="left">
              <Row>
                <Col className="col-9">
                  <h4 className="mt-2">Errores {totales.totalErrores}</h4>
                </Col>
                <Col className="col-3 text-right">
                  <StyleInputs>
                    <Button size="sm" disabled={totales.totalErrores === 0} onClick={() => setTabType("errores")}>
                      Ver
                    </Button>
                  </StyleInputs>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card text="left">
              <Row>
                <Col className="col-9">
                  <h4 className="mt-2"> Timbres Disponibles: <span style={{ color: colaboradores.length ? colorTimbres(totales.timbresDisponibles) : '' }}>{totales.timbresDisponibles}</span></h4>
                </Col>
                <Col className="col-3 text-right">
                  <StyleInputs>
                    <Button size="sm" onClick={solicitarTimbres}>
                      Solicitar
                    </Button>
                  </StyleInputs>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </StyleInputs>
      <TimbrarResumen type={tabType} colaboradores={tabType === "errores" ? errores : colaboradores} onChange={onChangeSelected} metrics={false} dist={distSalario} />
      <Modal isOpen={showDate} toggle={() => setShowDate(!showDate)} centered={true}>
        <ModalHeader toggle={() => setShowDate(!showDate)}>Seleccione el Día de Pago</ModalHeader>
        <ModalBody>
          <Input type={"date"} value={diaPago} onChange={(e) => setDiaPago(moment(e.target.value).format("YYYY-MM-DD"))} />
        </ModalBody>
        <StyleInputs>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowDate(!showDate)}>
              Cancelar
            </Button>{" "}
            <Button color="primary" onClick={onTimbrar}>
              Aceptar
            </Button>
          </ModalFooter>
        </StyleInputs>
      </Modal>
    </>
  );
};

export default Timbrar;
