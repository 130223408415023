import React from 'react';
import { Spinner } from 'reactstrap';

const Spiner = () => {
  return (
    <div
      style={{
        width: '100%',
        height:'90%',
        textAlign: 'center',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      <Spinner
        style={{
          width: '3rem',
          height: '3rem',
          margin: '0 auto',
          textAlign: 'center',
        }}
        color='success'
      />
    </div>
  );
};
export default Spiner;
