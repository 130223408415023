import React, { useEffect, useState } from 'react';
import ComponentDescrip from '../../components/ui/ComponentDescrip';
import HttpService, { getAgregarParametrosExtra } from '../../services/HttpService';
import EmptyList from '../../components/ui/EmptyList';
import Spiner from '../../components/ui/Spiner';
import { BackgroundImage } from '../../pages/styled';
import Tabla from '../../components/Tabla';
import FiltroColaboradores from '../../components/FiltroColaboradores';

const cabecera = [
  {
    id: 'puesto',
    titulo: 'Puesto',
  }
];

const columnasExtra = [
  {
    id: 'foto',
    titulo: 'Foto',
    render: (colaborador) => (colaborador.foto && <BackgroundImage imageUrl={`${process.env.REACT_APP_API_URL}/archivo/descarga/${colaborador.foto}`} />),
    empujarAlInicio: true
  },
  {
    id: "nombreCompleto",
    titulo: "Nombre",
    render: (colaborador) => {
      const { nombre, apellidoMaterno, apellidoPaterno } = colaborador.informacionPersonal;
      
      return (<div> {`${nombre} ${apellidoPaterno} ${apellidoMaterno}`} </div>)
    },
    empujarAlInicio: true
  },
  {
    id: "correo",
    titulo: "Correo",
    render: (colaborador) => {
      const { email } = colaborador.informacionPersonal;
      
      return (<div> {email} </div>)
    },
    empujarAlInicio: true
  }
];

const ListaColaboradores = ({history}) => {
  const [cargando, setCargando] = useState(true);
  const [colaboradores, setColaboradores] = useState([]);
  const [paginacion, setPaginacion] = useState(null);

  useEffect(() => {
    let montado = true;

    const init = async () => {
      if(!montado) return;

      try {
        await obetenerColaborades(null, 1, 5);
      } catch (error) {
        console.log(error);
      } finally {
        setCargando(false);
      }
    }
    
    init();

    return () => {
      montado = false
    }
  }, []);

  const obetenerColaborades = async (parametrosExtra, pagina, limite) => {
    const respuesta = await HttpService.get(`/colaborador/colaboradores?pagina=${pagina}&limite=${limite}&active=true&estatus=completado${getAgregarParametrosExtra(parametrosExtra)}`);

    if(respuesta.data) {
      const { colaboradores, paginacion } = respuesta.data;

      setPaginacion(paginacion);
      setColaboradores(
        colaboradores.map(colaborador => ({
          ...colaborador, 
          puesto: colaborador.condicionesLaborales?.puesto 
            ? colaborador.condicionesLaborales.puesto 
            : "Sin puesto"
        }))
      );
    }
  }

  const cambioPagina = async pagina => 
  {
    setCargando(true);

    try {
      await obetenerColaborades(null, pagina, 5);
    } catch (error) {
      console.log(error);      
    } finally {
      setCargando(false);
    }
  }
  
  const buscar = async ({buscarColaborador, idEmpresa, idSucursal, idDepartamento}) => {
    setCargando(true);

    let parametrosExtra = {
      buscarColaborador,
      idEmpresa,
      idSucursal,
      idDepartamento
    }

    for (const key in parametrosExtra) {
      if(!parametrosExtra[key]) {
        delete parametrosExtra[key];
      }
    }

    try {
      await obetenerColaborades(parametrosExtra, 1, 5);
    } catch (error) {
      console.log(error);
    } finally {
      setCargando(false);
    }
  }

  return (
    <div>
      <ComponentDescrip
        title={'Colaboradores'}
        description={"Elije el colaborador que para empezar el proceso de baja"}
      />     
      <FiltroColaboradores
        buscar={buscar}
      />
      {
        cargando
        ?
          <div style={{marginTop: "20%"}}>
            <Spiner />
          </div>
        :
          colaboradores.length 
          ?
            <Tabla 
              cabecera={cabecera}
              datos={colaboradores}
              columnasExtra={columnasExtra}
              paginacion={paginacion}
              cambioPagina={cambioPagina}
              clickRenglon={(colaborador) => history.push('/dashboard/recursos-humanos/bajas-proceso/' + colaborador.id)}
            />
          :
            <EmptyList
              align={'center'}
              title={'Sin colaboradores'}
            />
      }
    </div>
  )
}

export default ListaColaboradores;