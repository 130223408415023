import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LogIn from './pages/LogIn/LogIn';
import Recuperar from './pages/LogIn/Recuperar';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';

import ProtectedRoutes from './routes/ProtectedRoutes/ProtectedRoutes';
import RegistroComponente from './pages/RegistroUsuario/RegistroComponente';

const App = () => {
  //TODO: Cambiar dashboard por el nombre del sistema en rutas
 //problema en el router ya que no debe estar la logica de las rutas despues del switch (logica esta dentro de protected routes)
  return (
          <Switch>
          
            <Route exact path='/'>
              <LogIn />
            </Route>
            <Route exact path='/recuperar-credenciales'>
              <Recuperar />
            </Route>
            <Route exact path='/registro-usuario' component={RegistroComponente}>
            </Route>
            
            <ProtectedRoutes path='/dashboard' component={Dashboard} />
            
            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
  );
};

export default App;
