import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { schemaDescuentoInfonavit } from '../../../constans/yupSchemas';
import { createDescuento, descuentoEditFinish, editDescuento, deleteDescuento } from '../../../store/descuentosDuck';
import Arrows from '../../ui/Arrows';
import ButtonDowload from '../../ui/ButtonDowload';
import CustomSelect from '../../ui/CustomSelect';
import Icons from '../../ui/Icons';
import InputFile from '../../ui/InputFile';
import SweetAlert from '../../ui/SweetAlet';
import DatePicker from "react-datepicker";

import {
    CancelButton,
    InputUtils,
    LabelForms,
    SubTitleMenu
} from '../VacacionesViews/styled';
import BotonCargando from '../../BotonCargando';

const tiposDescuentos = [
    { value: 'Préstamos provenientes del Fondo Nacional de la Vivienda para los Trabajadores', label: 'Préstamos provenientes del Fondo Nacional de la Vivienda para los Trabajadores' },
    { value: 'Pago por crédito de vivienda', label: 'Pago por crédito de vivienda' }
];

const tiposMonto = [
    { value: 'tasa', label: 'Porcentaje' },
    { value: 'monto', label: 'Cuota fija'},
    { value: 'factor', label: 'Factor de descuento'}
];

const FormDescuentoSalario = React.memo(({ history, disabled = false }) => {
    const periodo = useSelector(state => state.colaborador.InformacionNomina ? state.colaborador.InformacionNomina.modalidadDePago : '');
    const isEdit = useSelector(state => state.descuentos.isEdit);
    let dto = useSelector(state => state.descuentos.dto);
    const { permisos = {} } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const [isFormEdit, setFormEdit] = useState(false);
    const [tipo, setTipo] = useState('');
    const [err, setErr] = useState({});
    const [saving, setSaving] = useState(false);
    
    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(schemaDescuentoInfonavit),
    });

    useEffect(() => {
        if (Object.keys(errors).length) setErr(errors);
    }, [errors]);
    
    useEffect(()=>{
        if(isEdit){
            setFormEdit(true);
        }
    },[isEdit]);
    

    const cancel = async () => {
        dispatch(descuentoEditFinish());
    }

    const elminateUtil = async () => {
        try {
            const { isConfirmed } = await SweetAlert({
                title: 'Seguro que desea eliminar?',
                showCancelButton: 'Cancelar',
            });

            if (isConfirmed) {
                dispatch(deleteDescuento(dto._id));
                await SweetAlert({title:'Descuentos actualizados', icon: 'success'});
                history.push('/');
            }
        } catch (err) {
            await SweetAlert({title:'Error!', text: err, icon: 'error'});
            console.log(err);
        }
    };

    const onSubmit = async (data) => {
        try {
            setSaving(true);
            
            data.infonavit = true;
            data.fechaAplicacion = new Date(data.fechaAplicacion);

            if(isEdit)
                dispatch(editDescuento(data));
            else
                dispatch(createDescuento(data));
            await SweetAlert({title:'Descuentos actualizados', icon: 'success', showConfirmButton: false, timer: 2500});
            history.push('/');
        } catch (err) {
            await SweetAlert({title:'Error!', text: 'Ocurrió un error!', icon: 'error'});
            console.log(err);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
            {isEdit ?
            <FormGroup row>
                <Col sm={1}>
                    <Arrows
                        onClick={() => {
                            cancel();
                            history.push('/');
                        }}
                    />
                </Col>
                {
                    !disabled && <>
                        <Col sm={1} hidden={!dto.active || permisos.colaboradorInfonavit !== 'write'}>
                            <Icons onClick={elminateUtil} />
                        </Col>
                        <Col sm={1} hidden={!dto.active || permisos.colaboradorInfonavit !== 'write'}>
                            <Icons
                                pencil
                                onClick={() => {
                                    setFormEdit(!isFormEdit);
                                }}
                            />
                        </Col>
                    </>
                }
            </FormGroup>
            :''}

            
            <FormGroup row>
                <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                    <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >{isEdit ? 'Editar' : 'Nuevo'} Crédito Infonavit</SubTitleMenu>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo de Descuento*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        onChange={e => setTipo(e.target.value)}
                        name='tipoDescuento'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={tiposDescuentos}
                        defaultValue={dto.tipoDescuento ? dto.tipoDescuento : ''}
                        disabled={isFormEdit}
                    />
                    {err.tipoDescuento && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El tipo de descuento es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo Monto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        name='tipoMonto'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={tiposMonto}
                        defaultValue={dto.tipoMonto ? dto.tipoMonto : ''}
                        disabled={isFormEdit}
                    />
                {err.tipoMonto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El tipo de monto es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Monto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='monto'
                        step='0.0001'
                        type='number'
                        ref={register}
                        placeholder='Ingrese el monto'
                        defaultValue={dto.monto ? dto.monto : ''}
                        disabled={isFormEdit}
                    />
                    {err.monto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El monto es requerido y debe ser numérico
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Número de Crédito*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='numeroCredito'
                        type='text'
                        ref={register}
                        defaultValue={dto.numeroCredito ? dto.numeroCredito : ''}
                        placeholder='Ingrese el número de crédito'
                        disabled={isFormEdit}
                    />
                    {err.numeroCredito && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El número de crédito es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Periodicidad</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        disabled={isFormEdit}
                        name='periodicidad.periodo'
                        type='text'
                        ref={register}
                        // disabled={ true }
                        value={ periodo }
                    />
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Fecha de Aplicación*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <Controller
                        name='fechaAplicacion'
                        control={control}
                        innerRef={register()}
                        render={({ onChange, value }) => (
                            <DatePicker
                                selected={value ? value : dto.fechaAplicacion ? new Date(dto.fechaAplicacion) : new Date()}
                                onChange={onChange}
                                dateFormat={'dd/MM/yyyy'}
                                disabled={isFormEdit}
                            />
                        )}
                    />
                    {err.fechaAplicacion && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        La fecha de aplicación es requerida
                        </span>
                    )}
                </Col>
            </FormGroup>
            
            

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Aviso de Retención</LabelForms>
                </Label>

                <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
                    {isEdit && dto.archivo && isFormEdit
                        ?
                        <ButtonDowload
                            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${dto.archivo}`}
                        />
                        : <InputFile name='archivo' id='archivo' register={register} disabled={isFormEdit} />}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Col sm={dto.active === false ? {offset: 9, size: 3} : { offset: 6, size: 3 }}>
                    <CancelButton
                        onClick={() => {
                            cancel();
                            history.push('/');
                        }}
                        style={{ marginLeft: 'auto' }}>
                        Cancelar
                    </CancelButton>
                </Col>
                {
                    !disabled &&  <Col sm={3} hidden={dto.active === false || permisos.colaboradorInfonavit !== 'write'}>
                        <BotonCargando
                            cargando={saving}
                            disabled={saving || isFormEdit}
                            texto='Guardar'
                            type='submit'
                        />
                    </Col>
                }
            </FormGroup>
        </Form>
    );
});

export default FormDescuentoSalario;