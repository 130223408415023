import React, { useState, useEffect } from "react";
import { Button, Row, Col, FormGroup, Input, Label, Form, Table, CustomInput } from "reactstrap";
import { StyleInputs } from "../ui/styles/StyleNomina";
import "../ui/styles/Nomina.css";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import sweet from "sweetalert2";
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

export default ({ onUpdate, colaboradorId, arregloFaltas }) => {
  const [faltas, setFaltas] = useState([]);
  const [falta, setFalta] = useState({
    fecha: moment().format("YYYY-MM-DD"),
    paga: false,
    septimoDia: false,
    permiso: false,
  });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {
    setFaltas(arregloFaltas);
  }, []);

  const agregarFalta = async () => {
    const id = falta._id;
    const action = id === undefined ? "post" : "put";
    falta.colaborador = colaboradorId;
    action === "put" && delete falta._id;
    let { data } = await axios[action](`${api}/faltas/${id === undefined ? "crear" : `editar_falta/${id}`}`, falta, headers);
    if (action === "post") setFaltas([...faltas, data.resultado]);
    else
      setFalta({
        fecha: moment().format("YYYY-MM-DD"),
        paga: false,
        septimoDia: false,
        permiso: false,
      });
    onUpdate();
  };

  const borrarFaltas = async (falta) => {
    let result = await sweet.fire({
      title: "Borrar falta",
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      let { data } = await axios.delete(`${api}/faltas/eliminar/${falta._id}`, headers);
      await sweet.fire({
        title: "Borrar falta",
        text: "Se elimino exitosamente",
        icon: "success",
      });
      setFaltas(faltas.filter((f) => f._id !== falta._id));
      onUpdate();
    }
  };

  return (
    <StyleInputs>
      <Row>
        <Col>
          <Form onSubmit={(e) => e.preventDefault()} inline className="py-4">
            <FormGroup className="mb-0 mr-4">
              <Label className="mb-2 mr-sm-2 mb-sm-0">Fecha</Label>
              <DatePicker
                selected={falta.fecha}
                onChange={date => setFalta({ ...falta, fecha: date }) }
                dateFormat={'dd/MM/yyyy'}
                placeholderText="Fecha"
              />
            </FormGroup>
            <FormGroup check className="mb-0 mx-4">
              <Label style={{ color: "#404041" }} check className="label-checkbox">
                <CustomInput type="checkbox" checked={falta.paga} onClick={() => setFalta({ ...falta, paga: !falta.paga })} /> Goce de Sueldo
              </Label>
            </FormGroup>
            <FormGroup check className="mb-0 mx-4">
              <Label check className="label-checkbox">
                <CustomInput style={{ color: "#404041" }} type="checkbox" checked={falta.septimoDia} onClick={() => setFalta({ ...falta, septimoDia: !falta.septimoDia })} />{" "}
                Septimo Día
              </Label>
            </FormGroup>
            <FormGroup check className="mb-0 mx-4">
              <Label style={{ color: "#404041" }} check className="label-checkbox">
                <CustomInput type="checkbox" checked={falta.permiso} onClick={() => setFalta({ ...falta, permiso: !falta.permiso })} /> Permiso
              </Label>
            </FormGroup>
            <Button color="primary" onClick={agregarFalta}>
              {falta._id === undefined ? "Agregar" : "Guardar"}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
            <thead>
              <tr>
                <th>Fecha</th>
                <th className="text-center">Goce de Sueldo</th>
                <th className="text-center">Septimo Día</th>
                <th className="text-center">Permiso</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {faltas.map((d, i) => (
                <tr key={i}>
                  <td>{moment(d.fecha).utc().format("YYYY-MM-DD")}</td>
                  <td className="text-center">
                    <CheckBox value={d.paga} />
                  </td>
                  <td className="text-center">
                    <CheckBox value={d.septimoDia} />
                  </td>
                  <td className="text-center">
                    <CheckBox value={d.permiso} />
                  </td>
                  <td>
                    <FontAwesomeIcon onClick={() => setFalta({ ...d, fecha: moment(d.fecha).utc().format("YYYY-MM-DD") })} icon={faPen} cursor="pointer" className="mx-1" />
                    <FontAwesomeIcon onClick={() => borrarFaltas(d)} icon={faTrash} cursor="pointer" className="mx-1" />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </StyleInputs>
  );
};

const CheckBox = ({ value, onChange }) => {
  return (
    <FormGroup check inline>
      <Label check inline>
        <Input disabled style={{ height: "20px" }} type="checkbox" checked={value} onChange={onChange} />
      </Label>
    </FormGroup>
  );
};
