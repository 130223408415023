import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaPermisosLaborales } from '../../../constans/yupSchemas';
import CustomSelect from '../../ui/CustomSelect';
import InputFile from '../../ui/InputFile';
import './PermisosLaborales.css'
import SweetAlert from '../../ui/SweetAlet';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
} from '../VacacionesViews/styled';
import { fetchCreatePermisos } from '../../../store/permisosLaboralesDuck';
import CustomInput from 'reactstrap/lib/CustomInput';
import { saveAs } from 'file-saver';
import axios from 'axios';
import BotonCargando from '../../BotonCargando';
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

const tiposPermisos = [
  { value: 'Con goce de sueldo', label: 'Con goce de sueldo' },
  { value: 'Sin goce de sueldo', label: 'Sin goce de sueldo' },
  { value: 'Otros', label: 'Otros' },
];

const FormPermisosLaborales = React.memo(({ history }) => {
  const [otros, setOtros] = useState(false);
  const [haySuplente, setHaySuplente] = useState(false);
  const dispatch = useDispatch();
  const colaborador = useSelector((state) => state.colaborador);
  const { _id } = colaborador;
  const [data, setData] = useState({ periodoInicial: '', periodoFinal: '', tipoPermiso: '', observaciones: '', personaAutoriza: '' });
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schemaPermisosLaborales),
  }
  );
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  const [err, setErr] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const onSubmit = async (data) => {
    try {
      setSaving(true);
      data.periodoInicial = new Date(data.periodoInicial);
      data.periodoFinal = new Date(data.periodoFinal);
      dispatch(fetchCreatePermisos(data));
      await SweetAlert({ title: 'Permiso guardado', icon: 'success', showConfirmButton: false, timer: 2500 });
      history.push('/');
    } catch (error) {
      console.log(error);
      if (error === 'Agregar horario') {
        await SweetAlert({
          icon: 'error',
          title: 'Agregar Horario Laboral Primero',
        });
      } else await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const downloadPdf = async (e, type) => {
    e.preventDefault();
    const file = type === 'Solicitud' 
    ? await axios.post(`${api}/permisos-laborales/generar-oficio/`, { ...data, colaborador: _id }, headers) 
    : await axios.post(`${api}/permisos-laborales/generar-autorizacion/`, { ...data, colaborador: _id }, headers)

    let byteString = atob(file.data);

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ia], { type: 'application/pdf' });
    saveAs(blob, `${type}-${moment().format('YYYYMMDD')}`)
  }

  const handleTipoPermiso = (value) => {
    let valor = value.target.value;
    setData({ ...data, tipoPermiso: valor })
    if (valor === 'Otros') {
      setOtros(true);
    } else {
      setOtros(false);
    }
  };
  const clickSuplente = e => {
    let value = e.target.value;
    if (value === 'true') {
      setHaySuplente(true);
    } else {
      setHaySuplente(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Nuevo Registro de Permiso</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de Inicio*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Controller
            name='periodoInicial'
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={date  => {
                  setData({ ...data, periodoInicial: date })
                  onChange(date);
                }}
                dateFormat={'dd/MM/yyyy'}
              />
            )}
          />
          {err.periodoInicial && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de inicio es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de Conclusión*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Controller
            name='periodoFinal'
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={date  => {
                  setData({ ...data, periodoFinal: date })
                  onChange(date);
                }}
                dateFormat={'dd/MM/yyyy'}
              />
            )}
          />
          {err.periodoFinal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de conclusión es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Tipo de Permiso*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CustomSelect
            onChange={(value) => handleTipoPermiso(value)}
            name='tipoPermiso'
            type='select'
            innerRef={register}
            control={control}
            options={tiposPermisos}
          />
          {err.tipoPermiso && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El tipo de permiso es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        {otros
          ? <>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Especifica</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                name='permisoOtros'
                type='text'
                placeholder='Especifica'
                ref={register}
              />
            </Col>
            <Col sm={4}></Col>
          </>
          : null
        }

        <Label sm={3} style={{ marginBottom: '12px', marginTop: '7px' }}>
          <LabelForms>Suplente*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Label sm={2} check style={{ marginLeft: '20px' }}>
            <CustomInput id='suplenteR1' type='radio' name='suplente' value='true' innerRef={register()} onClick={clickSuplente} />
              Si
            </Label>
          <Label sm={2} check style={{ marginLeft: '40px' }}>
            <CustomInput id='suplenteR2' type='radio' name='suplente' value='false' innerRef={register()} onClick={clickSuplente} />
              No
            </Label>
          {err.suplente && (
            <FormGroup row>
              <Label>
              </Label>
              <Col sm={9}>
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  Suplente es requerido
                </span>
              </Col>
            </FormGroup>
          )}
        </Col>
        <Col sm={4}></Col>
        {haySuplente
          ? <>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Nombre del Suplente*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                name='datosSuplente.nombreColaborador'
                type='text'
                placeholder='Nombre del suplente'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.nombreColaborador && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El nombre del suplente es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Puesto*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                placeholder='Puesto del suplente'
                name='datosSuplente.puesto'
                type='text'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.puesto && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El puesto es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Teléfono*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                placeholder='Teléfono del suplente'
                name='datosSuplente.telefono'
                type='text'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.telefono && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El teléfono es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Email*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                placeholder='Email del suplente'
                name='datosSuplente.email'
                type='text'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.email && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El email es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
          </>
          : null
        }

        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Persona que Autoriza*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            placeholder='Persona que autoriza'
            name='personaAutoriza'
            type='text'
            ref={register}
            onChange={(e)=>setData({...data, personaAutoriza: e.target.value})}
          />
          {err.personaAutoriza && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La persona que autoriza es requerida
            </span>
          )}
        </Col>

        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px', marginTop: '7px' }}>
          <LabelForms>Agregar a Calendario*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Label sm={2} check style={{ marginLeft: '20px' }}>
            <CustomInput id='calendario1' type='radio' name='calendario' defaultChecked={true} value='true' innerRef={register()} />
              Si
            </Label>
          <Label sm={2} check style={{ marginLeft: '40px' }}>
            <CustomInput id='calendario2' type='radio' name='calendario'  defaultChecked={false} value='false' innerRef={register()} />
              No
            </Label>
            {err.calendario && (
            <FormGroup row>
              <Label>
              </Label>
              <Col sm={9}>
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  Indique si desea mostrar este permiso en el calendario
                </span>
              </Col>
            </FormGroup>
          )}
        </Col>

        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px', marginTop: '7px' }}>
            <LabelForms>Observaciones</LabelForms>
          </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name='observaciones'
            placeholder='Observaciones'
            onChange={(e)=>setData({ ...data, observaciones: e.target.value })}
            ref={register}
          />
        </Col>

      </FormGroup >
      {data.periodoInicial !== '' && data.periodoFinal !== '' && data.tipoPermiso !== '' ?
        <FormGroup row>
          <Col sm={3} className='mb-3'>
            <SaveButton onClick={(e) => downloadPdf(e, 'Solicitud')}>Generar Solicitud</SaveButton>
          </Col>
          <Col sm={3} className='mb-3'>
            <SaveButton onClick={(e) => downloadPdf(e, 'Autorizacion')}>Autorización</SaveButton>
          </Col>
        </FormGroup>
        : ''
      }
      <FormGroup row>
        {/* <Col sm={4}></Col> */}
        <Label sm={3}>
          <LabelForms>Solicitud de Permiso</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          <InputFile name='solicitudPermiso' id='solicitudPermiso' register={register} />
        </Col>
      </FormGroup>
      <FormGroup row>
        {/* <Col sm={4}></Col>  */}
        <Label sm={3}>
          <LabelForms>Autorizacion de Permiso</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          <InputFile name='autorizacionPermiso' id='autorizacionPermiso' register={register} />
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <BotonCargando
            cargando={saving}
            disabled={saving}
            texto='Guardar'
            type='submit'
          />
        </Col>
      </FormGroup>
    </Form>
  );
});

export default FormPermisosLaborales;