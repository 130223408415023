import React from 'react';
import { Table } from 'reactstrap';
import '../../VacacionesViews/TableCommon.css';
import styled from 'styled-components';
import colors from '../../../../constans/colors';

const TR = styled.tr`
  color: ${colors.tashColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
`;
const SpanFloat = styled.span`
  position: relative;
  top: 2vh;
  right: 4rem;
`;

const TableCommonSaludLaboral = React.memo(
  ({ children, striped = false, keysAccepted = [] }) => {
    return (
      <div className='table-container' style={{ width: '-webkit-fill-available' }}>
        <Table borderless hover striped={striped} responsive autoCorrect='true'>
          <thead>
            <TR>
              {keysAccepted.map((element, index) => {
                return (
                  <th key={element + index}>
                    <span> {element}</span>
                  </th>
                );
              })}
            </TR>
          </thead>
          <tbody>{children}</tbody>
        </Table>
        {/*   <TablePagination /> */}
      </div>
    );
  }
);

export default TableCommonSaludLaboral;
