import React, { useEffect } from 'react';

import './styles.css';
import {
  Input,
  ErrorMessage,
  RegisterStyled,
  EnterButton,
  ForgotTitle,
  WelcomeTitle,
  LogoContainer,
  ImageContainer,
  LoginContainer,
} from '../LogIn/styled';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schemaLogin } from '../../constans/yupSchemas';
import { Col, FormGroup, Form, Card } from 'reactstrap';
import { fetchLogin, authorizedToken } from '../../store/userDuck';
import { Link, useHistory } from 'react-router-dom';
import Spiner from '../../components/ui/Spiner';

const LogIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { loading } = useSelector((state) => state.user);
  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('pv2token');
      if (token) {
        try {
          await dispatch(authorizedToken(token));
          history.push('/dashboard');
        } catch (error) {
          console.log(error);
        }
      }
      return;
    })();
  }, [dispatch, history]);

  const recuperar = (e) =>{
    e.preventDefault();
    history.push('/recuperar-credenciales');
  }

  const onSubmit = async (data) => {
    try {
      await dispatch(fetchLogin(data));
      history.push('/dashboard');
    } catch (err) {
      console.log(err);
      setError('Login', {
        types: 'manual',
        message: err,
      });
    }
  };
  return (
    <Card className='mx-auto' style={{ marginTop: '9rem' }}>
      <LoginContainer>
        <Form onSubmit={handleSubmit(onSubmit)} className='p-3'>
          <FormGroup row>
            <Col sm={12}>
              <LogoContainer />
            </Col>
            <Col sm={12}>
              <WelcomeTitle sm={12}> Bienvenido </WelcomeTitle>
            </Col>

            <Col sm={12} className='pl-4'>
              <Input
                type='text'
                name='usuario'
                placeholder='Usuario'
                ref={register()}
                size='sm'
                className='border mt-1 mb-2'
              />
            </Col>
            <Col sm={12} className='pl-4'>
              <Input
                type='password'
                name='contrasena'
                placeholder='Contraseña'
                ref={register()}
                size='sm'
                className='border border-grey mt-1 mb-0'
              />
            </Col>
            <Col sm={12} className='text-right mt-0 pr-4'>
              <ForgotTitle sm={6} onClick={recuperar}>Olvide mi Contraseña</ForgotTitle>
            </Col>
            {errors.Login && (
              <Col sm={12}>
                <ErrorMessage>{errors.Login.message}</ErrorMessage>
              </Col>
            )}

            <Col sm={11}>
              <EnterButton type='submit'>ENTRAR</EnterButton>
            </Col>
            <Col sm={12} className='pl-4'>
              <RegisterStyled>
                No tienes una cuenta?{' '}
                <Link replace={true} to='/registro-usuario'>
                  {' '}
                  Registrate aquí{' '}
                </Link>
              </RegisterStyled>
            </Col>
          </FormGroup>
        </Form>
        <ImageContainer />
      </LoginContainer>
    </Card>
  );
};
export default LogIn;
