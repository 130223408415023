import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchCatalogos, fetchaddCatalogo } from '../../store/empresasDuck';
import { useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
  CustomInput,
  Label,
} from 'reactstrap';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
} from '../ui/styles/styles';
import CustomSelect from '../ui/CustomSelect';
import SweetAlert from '../ui/SweetAlet';
const CatalogoModal = ({ isOpen, setIsOpen, catalogoData, tipoCatalogo }) => {
  console.log(catalogoData);
  const {
    descripcion = '',
    general = '',
    nombreCorto = '',
    clave = '',
    calc = '',
    calcType = '',
  } = catalogoData;
  const dispatch = useDispatch();
  const { register, handleSubmit, control, errors } = useForm();
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const catalogoUpdate = { ...catalogoData, ...data };
      console.log(catalogoUpdate);
      await dispatch(
        fetchaddCatalogo({
          catalogo: tipoCatalogo,
          catalogoData: catalogoUpdate,
        })
      );
      await dispatch(fetchCatalogos({ tipoCatalogo, sat: false }));
      await SweetAlert({
        icon: 'success',
        title: 'Operacion hecha correctamente',
      });
      setIsOpen(false);
    } catch (error) {}
  };
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader>
        <SubTitleMenu style={{ color: 'white' }}>
          Modifica tu Catalogo
        </SubTitleMenu>
        <button
          className='close'
          style={{ position: 'absolute', top: '15px', right: '15px' }}
          onClick={() => setIsOpen(false)}
        >
          &times;
        </button>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Col>
              <LabelForms>{descripcion}</LabelForms>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Valor</Label>
            <Col>
              <InputUtils
                placeholder='Inserte el monto'
                name='calc'
                type='number'
                defaultValue={calc}
                ref={register()}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Unidad</Label>
            <Col>
              <CustomSelect
                type='select'
                name='calcType'
                control={control}
                defaultValue={calcType}
                options={[
                  { label: '$', value: '$' },
                  { label: '%', value: '%' },
                  { label: 'UMA', value: 'UMA' },
                ]}
                innerRef={register()}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Nombre corto</Label>
            <Col>
              <InputUtils
                placeholder='Inserte el nombre corto'
                name='nombreCorto'
                defaultValue={nombreCorto}
                ref={register()}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Cuenta</Label>
            <Col>
              <InputUtils
                placeholder='Inserte la cuenta'
                name='clave'
                defaultValue={clave}
                ref={register()}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>Aplica En General</Label>
            <Label sm={2} className='radio-organize' inline='true'>
              Si
              <CustomInput
                type='radio'
                name='general'
                id='sindicalizado1'
                value='General'
                defaultChecked={general === 'General'}
                innerRef={register()}
              />
            </Label>
            <Label sm={2} className='radio-organize' inline='true'>
              No
              <CustomInput
                type='radio'
                name='general'
                id='sindicalizado2'
                defaultChecked={general === 'Personal'}
                value='Personal'
                innerRef={register()}
              />
            </Label>
          </FormGroup>
          <ModalFooter>
            <FormGroup row style={{width:'100%'}}>
              <Col sm={6}>
                <CancelButton
                style={{width:'100%'}}
                  onClick={() => setIsOpen(false)}
                  color='primary'
                  type='button'
                >
                  Cancelar
                </CancelButton>
              </Col>
              <Col sm={6}>
                <SaveButton style={{width:'100%'}} color='primary' type='submit'>
                  Guardar
                </SaveButton>
              </Col>
            </FormGroup>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CatalogoModal;
