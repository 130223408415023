import React, { useState, useEffect } from "react";
import { Button, Row, Col, FormGroup, Input, Label, Form, Table } from "reactstrap";
import { StyleInputs } from "../ui/styles/StyleNomina";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import sweet from "sweetalert2";
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

export default ({ onUpdate, colaboradorId, horasExtra }) => {
  const [hrsExtra, setHorasExtra] = useState([]);
  const [hrExtra, setHrExtra] = useState({ fecha: moment().utc().format("YYYY-MM-DD"), horas: "" });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {
    setHorasExtra(horasExtra);
  }, []);

  const agregarHoraExtra = async () => {
    const id = hrExtra._id;
    const action = id ? "put" : "post";
    const url = id ? `editar_horaExtra/${id}` : "crear";
    hrExtra.horas = parseInt(hrExtra.horas);
    action === "put" && delete hrExtra._id;
    let body = { numeroHoras: hrExtra, colaborador: colaboradorId };
    let { data } = await axios[action](`${api}/horas-extras/${url}`, body, headers);
    let i = horasExtra.findIndex((h) => h.fecha === data.resultado.fecha);
    let datoGuardado = data.resultado;
    i >= 0 ? (horasExtra[i] = datoGuardado) : horasExtra.push(datoGuardado);
    setHrExtra({ fecha: moment().utc().format("YYYY-MM-DD"), horas: "" });
    setHorasExtra([...horasExtra]);
    onUpdate();
  };
  const borrarHorasExtras = async (hora) => {
    let result = await sweet.fire({
      title: "Borrar Hora Extra",
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      await axios.delete(`${api}/horas-extras/eliminar/${hora._id}`, headers);
      await sweet.fire({
        title: "Borrar Hora Extra",
        text: "Se elimino exitosamente",
        icon: "success",
      });
      setHorasExtra(hrsExtra.filter((f) => f._id !== hora._id));
      onUpdate();
    }
  };
  return (
    <StyleInputs>
      <Row>
        <Col>
          <Form onSubmit={(e) => e.preventDefault()} inline className="py-4">
            <FormGroup className="mb-0 mr-4">
              <Label className="mb-2 mr-sm-2 mb-sm-0">Fecha</Label>
              <DatePicker
                selected={hrExtra.fecha}
                onChange={date => setHrExtra({ ...hrExtra, fecha: date }) }
                dateFormat={'dd/MM/yyyy'}
                placeholderText="Fecha"
              />
            </FormGroup>
            <FormGroup check className="mb-0 mx-4">
              <Input
                type="number"
                name="fecha"
                value={hrExtra.horas}
                placeholder="# de Horas "
                onChange={(e) => setHrExtra({ ...hrExtra, horas: e.target.value })}
              />
            </FormGroup>
            {/* <FormGroup className="mb-0 mx-4 col-md-2">
              <Input className="col-md-12" type="select" name="select" placeholder="Tipo de Horas Extras">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup> */}
            <Button color="primary" disabled={hrExtra.horas === "" && true} onClick={agregarHoraExtra}>
              {hrExtra._id === undefined ? "Agregar" : "Guardar"}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Horas</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {hrsExtra.map((d, i) => (
                <tr key={i}>
                  <td>{moment(d.fecha).utc().format("YYYY-MM-DD")}</td>
                  <td>{d.horas}</td>
                  <td>
                    <FontAwesomeIcon
                      onClick={() =>
                        setHrExtra({
                          ...d,
                          horas: String(d.horas),
                          fecha: moment(d.fecha).utc().format("YYYY-MM-DD"),
                        })
                      }
                      icon={faPen}
                      cursor="pointer"
                      className="mx-1"
                    />
                    <FontAwesomeIcon
                      onClick={() => borrarHorasExtras(d)}
                      icon={faTrash}
                      cursor="pointer"
                      className="mx-1"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </StyleInputs>
  );
};
