import { faBuilding, faChartLine, faAnalytics } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import colors from "../../constans/colors";
import empresa_routes from "../../routes/empresa-routes";
import EmpresaRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/EmpresaRoutes";
import { LettersContainer } from "../styled";
import "./Empresa.css";

const Empresa = ({ match, location }) => {
  const [actualPage, setActualpage] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (location && location.pathname) {
      const pageIndex = location.pathname.split("/").length;
      setActualpage(location.pathname.split("/")[pageIndex - 1]);
    }
  }, [location]);
  const { permisos = {} } = useSelector((store) => store.user);
 
  return (
    <section className="grid-container">
      <div style={{ backgroundColor: colors.primary }} className="sub-menu-container">
        <LettersContainer />
        {permisos.usuarios !== "none" || permisos.roles !== "none" ? (
          <SubSideBar
            icon={faBuilding}
            routes={empresa_routes(permisos)}
            match={match}
            title="Empresa"
            className={`${actualPage === "usuarios" || actualPage === "rol" ? "active" : ""}`}
          />
        ) : null}
        {permisos.reportes !== "none" ? (
          <Button
            className={`sub-menu-btn ${actualPage === "calendario" ? "active" : ""}`}
            onClick={(e) => history.push("/dashboard/empresa/reportes")}
            style={{
              marginBottom: "1rem",
              fontSize: "small",
              backgroundColor: colors.buttonColorSecondary,
            }}
          >
            <FontAwesomeIcon color={colors.secondary} icon={faChartLine} style={{ fontSize: "1.2rem", marginRight: "0.5rem", color: "inherit" }} />
            <span className="mt-5 mb-5 ml-3">Reportes</span>
          </Button>
        ) : null}
        <Button
          className={`sub-menu-btn ${actualPage === "calendario" ? "active" : ""}`}
          onClick={(e) => history.push("/dashboard/empresa/metricas")}
          style={{
            marginBottom: "1rem",
            fontSize: "small",
            backgroundColor: colors.buttonColorSecondary,
          }}
        >
          <FontAwesomeIcon color={colors.secondary} icon={faAnalytics} style={{ fontSize: "1.2rem", marginRight: "0.5rem", color: "inherit" }} />
          <span className="mt-5 mb-5 ml-3">Métricas</span>
        </Button>
      </div>
      <div className="frame-content">
        <EmpresaRoutes path={match.path} />
      </div>
    </section>
  );
};
export default Empresa;
