import React,{ Fragment,useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody
} from "reactstrap";
import LaboralForm from './LaboralForm';
import AntecedenteForm from './AntecedenteForm';
import ProcedimientoForm from './ProcedimientoForm';
import ImssForm from './ImssForm';
import moment from 'moment';
import { editarVerificacionExito } from '../../../store/verificacionesDuck';

const AddModal = ({ isOpen, setIsOpen, typeModal }) => {
	
	const { register, handleSubmit, errors, control, setValue } = useForm({});
  const dispatch = useDispatch();
  const { verificacion={} } = useSelector((state) => state.verificaciones);

	const submitLaboral = async (objeto) => {
    objeto.fecha = Number(moment(objeto.fecha).format('x'));
    const {historialLaboral=[]} = verificacion;
    historialLaboral.push(objeto);
    dispatch(editarVerificacionExito(verificacion));
    setIsOpen(false);
	};

  const submitAntecedente = async (objeto) => {
    objeto.fecha = Number(moment(objeto.fecha).format('x'));
    const {historialPenal=[]} = verificacion;
    historialPenal.push(objeto);
    dispatch(editarVerificacionExito(verificacion));
    setIsOpen(false);
  };
  
  const submitProcedimiento = async (objeto) => {
    objeto.fecha = Number(moment(objeto.fecha).format('x'));
    const {procedimientosLegales=[]} = verificacion;
    procedimientosLegales.push(objeto);
    dispatch(editarVerificacionExito(verificacion));
    setIsOpen(false);
  };

  const submitImss = async (objeto) => {
    objeto.alta = Number(moment(objeto.alta).format('x'));
    objeto.baja = Number(moment(objeto.baja).format('x'));
    const {historialImms=[]} = verificacion;
    historialImms.push(objeto);
    dispatch(editarVerificacionExito(verificacion));
    setIsOpen(false);
  };

	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Agregar {typeModal}
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					{
            typeModal === 'Laboral' && (
              <LaboralForm setIsOpen={setIsOpen} submitLaboral={submitLaboral}/>
            )
          }
          {
            typeModal === 'Antecedente' && (
              <AntecedenteForm setIsOpen={setIsOpen} submitAntecedente={submitAntecedente}/>
            )
          }
          {
            typeModal === 'Procedimiento' && (
              <ProcedimientoForm setIsOpen={setIsOpen} submitProcedimiento={submitProcedimiento}/>
            )
          }
          {
            typeModal === 'Imss' && (
              <ImssForm setIsOpen={setIsOpen} submitImss={submitImss}/>
            )
          }
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default AddModal;