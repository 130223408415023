import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateColaborador } from "../../../store/colaboradorDucks";
import { schemaInformacionNomina } from "../../../constans/yupSchemas";
import { optionsJornada, optionsModalidadPago, optionsRegimen, optionsRiesgo, optionsTipoCuentaPago, optionsMetodoPago } from "../../../constans/informacionNomina";
import optionsBancos from "../../../constans/bancos";
import { Col, FormGroup, Input, Label, Form, CustomInput } from "reactstrap";
import CustomSelect from "../../ui/CustomSelect";
import Row from "reactstrap/lib/Row";
import SweetAlert from "../../ui/SweetAlet";
import CurrencyFormat from 'react-currency-format';
import './CondicionesLaborales.css';
import BotonCargando from "../../BotonCargando";

const FormInformacionNomina = ({ onChange = () => { }, setStep = () => { } }) => {
  const [tipoPago, setTipoPago] = useState("");
  const [banco, setBanco] = useState("");
  const [modalidadPago, setModalidadPago] = useState("");
  const [salD, setSalD] = useState({ salDiario: '' });
  const [salB, setSalB] = useState({ salBase: '' })
  const [color, setColor] = useState('#2b388f')
  const [metodoPago, setMetodoPago] = useState("");
  const [saving, setSaving] = useState(false);

  const addTipoPago = (e) => {
    onChange(e);
    setTipoPago(e.target.value);
    console.log("tipoPago",e, e.target.value);
  };
  const addBanco = (e) => {
    onChange(e);
    setBanco(e.target.value);
  };
  const addModalidadPago = (e) => {
    onChange(e);
    setModalidadPago(e.target.value);
  };
  const addMetodoPago = (e) => {
    onChange(e);
    setMetodoPago(e.target.value);
  };
  const { register, handleSubmit, errors, control, getValues } = useForm({
    mode: 'onBlur', resolver: yupResolver(schemaInformacionNomina),
  });

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) {
      if (!salD.salDiario) errors.salarioDiario = 'Salario diario requerido'
      if (!salB.salBase) errors.salarioBase = 'Salario base requerido'
      setErr(errors);
    }
  }, [errors]);

  const { salBase } = salB;
  const { salDiario } = salD;
  const dispatch = useDispatch();
  
  const onSubmit = async (data) => {

    console.log("submit");
    try {
      setSaving(true);

      data.salarioBase = salB.salBase;
      data.salarioDiario = salD.salDiario;
      const updateData = { InformacionNomina: { ...data } };

      if (metodoPago === 'Depósito') {
        const tipo = getValues().tipoCuentaPago ? getValues().tipoCuentaPago : '';
        
        const numCuenta = getValues().numeroCuentaBancaria ? getValues().numeroCuentaBancaria : '';
        const numClabe = getValues().clabe ? getValues().clabe : '';
        const numTarjeta = getValues().tarjeta ? getValues().tarjeta : '';
        if (!tipo) return SweetAlert({ icon: "error", title: "Error!", text: 'Es necesario seleccionar Tipo Cuenta Pago e incluir número' })
        if (tipo === 'CLABE Interbancaria' && numClabe.length !== 18) return setColor('red');
        if (tipo === 'Cuenta bancaria' && numCuenta.length !== 10) return setColor('red');
        if (tipo === 'Tarjeta bancaria' && numTarjeta.length !== 16) return setColor('red');
      }
      setColor('#2b388f')

      dispatch(updateColaborador(updateData));
      await SweetAlert({ icon: "success", title: "Registrado exitosamente", showConfirmButton: false, timer: 2500 });
    } catch (e) {
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  const validateBankAccountType = (tipo) => {
    // const tipo = getValues().tipoCuentaPago ? getValues().tipoCuentaPago : '';
    let text = '';
    if (tipo === 'clabe') text = 'La CLABE debe contener 18 digitos';
    if (tipo === 'cuenta') text = 'La Cuenta debe contener 10 digitos';
    if (tipo === 'tarjeta') text = 'La Tarjeta debe contener 16 digitos';
    return <span style={{ fontSize: 11, color, marginLeft: 10 }}>{text}</span>
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label for="regimen" sm={3}>
          Régimen*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="regimen"
            id="regimen"
            innerRef={register({ required: true })}
            //invalid={errors.regimen ? true : false}
            onChange={onChange}
            control={control}
            options={optionsRegimen}
          />
          {err.regimen && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El régimen es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="modalidadDePago" sm={3}>
          Modalidad de pago*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="modalidadDePago"
            id="modalidadDePago"
            innerRef={register({ required: true })}
            //invalid={errors.modalidadDePago ? true : false}
            onChange={addModalidadPago}
            control={control}
            options={optionsModalidadPago}
          />
          {err.modalidadDePago && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La modalidad de pago es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="tipoJornada" sm={3}>
          Seleccione Tipo Jornada*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="tipoJornada"
            id="tipoJornada"
            innerRef={register({ required: true })}
            //invalid={errors.tipoJornada ? true : false}
            onChange={onChange}
            control={control}
            options={optionsJornada}
          />
          {err.tipoJornada && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El tipo jornada es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="salarioDiario" sm={3}>
          Salario Diario*
        </Label>
        <Col sm={9}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            name='salarioDiario'
            value={salDiario}
            innerRef={register()}
            placeholder="Ingrese el salario diario del colaborador"
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setSalD({ salDiario: value })
            }}
          />
          {err.salarioDiario && !salD.salDiario && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El salario diario es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="diasAguinaldo" sm={3}>
          Días de aguinaldo*
        </Label>
        <Col sm={9}>
          <Input type="number" name="diasAguinaldo" innerRef={register({ required: true })} placeholder="Ingrese los días de aguinaldo" />
          {err.diasAguinaldo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Los días de aguinaldo son requeridos
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="diasVacaciones" sm={3}>
          Días de vacaciones*
        </Label>
        <Col sm={9}>
          <Input type="number" name="diasVacaciones" innerRef={register({ required: true })} placeholder="Ingrese los días de vacaciones" />
          {err.diasVacaciones && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Los días de vacaciones son requeridos
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Sindicalizado*</Label>
        <Label check sm={1} className="radio-organize" inline="true" style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="sindicalizado" id="sindicalizado1" value={true} innerRef={register()} />
          Si
        </Label>
        <Label check sm={1} className="radio-organize" inline="true">
          <CustomInput type="radio" name="sindicalizado" id="sindicalizado2" value={false} innerRef={register()} />
          No
        </Label>
      </FormGroup>
      {err.sindicalizado && (
        <FormGroup row>
          <Label sm={3}>
          </Label>
          <Col sm={9}>
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Sindicalizado requerido
            </span>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Label for="numeroSeguroSocial" sm={3}>
          Número Seguro Social*
        </Label>
        <Col sm={9}>
          <Input type="text" name="numeroSeguroSocial" innerRef={register({ required: true })} placeholder="Ingrese el número seguro social" />
          {err.numeroSeguroSocial && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El número Seguro Social es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="salarioBase" sm={3}>
          Salario Base Cotización*
        </Label>
        <Col sm={9}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            value={salBase}
            name='salarioBase'
            innerRef={register()}
            placeholder="Ingrese el salario base cotización del colaborador"
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setSalB({ salBase: value })
            }}
          />
          {err.salarioBase && !salB.salBase && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El salario base cotización es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="riesgoPuesto" sm={3}>
          Riesgo de Puesto*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="riesgoPuesto"
            id="riesgoPuesto"
            innerRef={register({ required: true })}
            //invalid={errors.riesgoPuesto ? true : false}
            onChange={onChange}
            control={control}
            options={optionsRiesgo}
          />
          {err.riesgoPuesto && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El riesgo puesto es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='metodoDePago' sm={3}>
          Método de Pago*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="metodoDePago"
            id="metodoDePago"
            onChange={addMetodoPago}
            control={control}
            options={optionsMetodoPago}
          />
          {err.metodoDePago && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Método de pago es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      {
        metodoPago === 'Depósito' ?
          <React.Fragment>
            <FormGroup row>
              <Label for="banco" sm={3}>
                Banco*
             </Label>
              <Col sm={9}>
                <CustomSelect
                  type="select"
                  name="banco"
                  id="banco"
                  innerRef={register({ required: true })}
                  //invalid={errors.banco ? true : false}
                  onChange={addBanco}
                  control={control}
                  options={optionsBancos}
                />
                {err.banco && (
                  <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    El banco es requerido
                  </span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="tipoCuentaPago" sm={3}>
                Tipo de Cuenta de Pago*
              </Label>
              <Col sm={9}>
                <CustomSelect
                  type="select"
                  name="tipoCuentaPago"
                  id="tipoCuentaPago"
                  innerRef={register({ required: true })}
                  //invalid={errors.tipoCuentaPago ? true : false}
                  onChange={addTipoPago}
                  control={control}
                  options={optionsTipoCuentaPago}
                />
                {err.tipoCuentaPago && (
                  <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    El tipo de cuenta de pago es requerido
                  </span>
                )}
              </Col>
            </FormGroup>
            {
              tipoPago === 'Cuenta bancaria' ?
                <React.Fragment>
                  <FormGroup row>
                    <Label for="numeroCuentaBancaria" sm={3}>
                      Número Cuenta Bancaria*
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="numeroCuentaBancaria"
                        innerRef={register({ required: tipoPago === 'Cuenta bancaria' ?true:false })}
                        placeholder="Ingrese el número cuenta bancaria"
                      />
                      {validateBankAccountType('cuenta')}
                      {err.numeroCuentaBancaria && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                          El número cuenta bancaria es requerido
                        </span>
                      )}
                    </Col>
                  </FormGroup>
              </React.Fragment>
              : '' 
            }
            {
              tipoPago === 'CLABE Interbancaria' ?
                <React.Fragment>
                  <FormGroup row>
                    <Label for="clabe" sm={3}>
                      CLABE Bancaria
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="clabe"
                        innerRef={register({ required: tipoPago === 'CLABE Interbancaria' ?true:false })}
                        placeholder="Ingrese la CLABE bancaria"
                      />
                      {validateBankAccountType('clabe')}
                      {err.clabe && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                          El número de clabe interbancaria es requerido
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </React.Fragment>
              : ''
            }
            {
              tipoPago === 'Tarjeta bancaria' ?
                <React.Fragment>
                  <FormGroup row>
                    <Label for="tarjeta" sm={3}>
                      Número Tarjeta Bancaria
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="tarjeta"
                        innerRef={register({ required: tipoPago === 'Tarjeta bancaria'?true:false })}
                        placeholder="Ingrese el número cuenta bancaria"
                      />
                      {validateBankAccountType('tarjeta')}
                      {err.tarjeta && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                          El número de tarjeta es requerido
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </React.Fragment>
              : ''
            }
          </React.Fragment>
          : ''
      }
      <FormGroup className="text-center">
        <Row>
          <Col sm={{ offset: 9, size: 3 }}>
            <BotonCargando
              cargando={saving}
              disabled={saving}
              texto='Guardar'
              type='submit'
            />
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default FormInformacionNomina;
