import React, {useCallback} from 'react';
import colors from '../../constans/colors';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getVerificacionExito } from '../../store/verificacionesDuck';
import PulsateDot from '../ui/PulsateDot';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { saveAs } from 'file-saver';
import axios from 'axios';

const api = process.env.REACT_APP_API_URL;


const TableBody = React.memo(({listaVerificaciones = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((verificacion) => {
        dispatch(getVerificacionExito(verificacion));
        history.push('detalle-verificacion');
    }, [dispatch]);

    const generarPdf = async (v,e) => {
      const headers = {
        headers: {
          token: localStorage.getItem("pv2token"),
        },
      };
      const file = await axios.get(`${api}/verificaciones/pdf/${v._id}`,headers)
			let byteString = atob(file.data);
			let ab = new ArrayBuffer(byteString.length);
			let ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
			  ia[i] = byteString.charCodeAt(i);
			}
			let blob = new Blob([ia], { type: 'application/pdf' });
			saveAs(blob, `verificacion-${v.nombre}-${moment().format('YYYYMMDD')}`)
    }

    const pdfButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: colors.secondary,
        color: '#fff',
        textDecoration: 'none',
        zIndex: 100,
        cursor: 'pointer',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    };

    const handleMouseDown = (e) => {
        e.currentTarget.style.transform = 'scale(0.9)';
        e.currentTarget.style.boxShadow = '0 0 8px rgba(0, 0, 0, 0.3)';
    };

    const handleMouseUp = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = 'none';
    };

    return(
        <>
            {listaVerificaciones.map((v, index) => (
              
          <Tr key={v._id}>
            <td onClick={() => showUtil(v)}>{v.nombre}</td>
            <td onClick={() => showUtil(v)}>{v.curp}</td>
            <td onClick={() => showUtil(v)}>{v.area}</td>
            <td onClick={() => showUtil(v)}>{v.puesto}</td>
            <td onClick={() => showUtil(v)} >{v.nss}</td>
            <td onClick={() => showUtil(v)}>
                {v.active ? (
                    <PulsateDot />
                ) : (
                    <PulsateDot color={colors.closeStatus} time='500ms' />
                )}
            </td>
            <td>
              <div 
                style={pdfButtonStyle}
                onClick={(e) => generarPdf(v,e)} 
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                <FontAwesomeIcon 
                  icon={faFilePdf} 
                />
              </div>
            </td>
          </Tr>
        ))}
        </>
    );
});

export default TableBody;