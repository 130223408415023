import { faMoneyBillWave, faNotesMedical } from "@fortawesome/pro-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { Button } from "reactstrap";
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import colors from "../../constans/colors";
import routes from "../../routes/nominas-routes";
import NominaRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/NominaRoutes";
import { LettersContainer } from "../styled";

const Nomina = ({ match, location }) => {
  const {permisos = {}} = useSelector(store => store.user);

  const [ nomina, imss] = routes(permisos);
  const [actualPage, setActualpage] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (location && location.pathname) {
      const pageIndex = location.pathname.split("/").length;
      setActualpage(location.pathname.split("/")[pageIndex - 1]);
    }
  }, [location]);

  return (
    <section className="grid-container">
      <div style={{ backgroundColor: colors.primary }} className="sub-menu-container">
        <LettersContainer />
        {
          permisos.nomina !== 'none' ?
            <SubSideBar
              icon={faMoneyBillWave}
              title="Nómina"
              routes={[nomina]}
              match={match}
              className={`${actualPage === "configuracion-nomina" || actualPage === "timbrar" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.imss !== 'none' ?
            <SubSideBar
              icon={faNotesMedical}
              title="IMSS/INFONAVIT"
              routes={[imss]}
              match={match}
              className={`${actualPage === "imss-idse"  || actualPage === "imss-conciliacion" || actualPage === "imss-variabilidad" ? "active" : ""}`}
            /> : null
        }
      </div>
      <div className="frame-content">
        <NominaRoutes path={match.path} />
      </div>
    </section>
  );
};

export default Nomina;
