import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { schemaVerificaciones } from '../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { updateVerificacion, editarVerificacionExito } from '../../store/verificacionesDuck';
import { Input, FormGroup, Label, Col, Collapse} from 'reactstrap';
import SweetAlert from '../ui/SweetAlet';
import CustomSelect from '../ui/CustomSelect';
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SaveButton,CancelButton } from '../ui/styles/styles';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';
import {
  faAngleDown,
  faAngleUp,
  faCloudDownloadAlt,
  faFileDownload
} from '@fortawesome/pro-light-svg-icons';
import { WhiteButton, ExamLabel, BlueButton } from "../colaboradores/Forms/FormsSaludLaboral/styledSaludLaboral";
import InputImage from '../ui/InputImage';
import TableCommon from './TableCommon';
import TableLaboralBody from './TableLaboralBody';
import TableAntecedentesBody from './TableAntecedentesBody';
import TableProcedimientosBody from './TableProcedimientosBody';
import TableImssBody from './TableImssBody';
import AddModal from './modales/AddModal';
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';
import colors from '../../constans/colors';
import { saveAs } from 'file-saver';
import axios from 'axios';
const api = process.env.REACT_APP_API_URL;

const DetalleVerificacion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const [err, setErr] = useState({});
  const [empresa, setEmpresa] = useState('');
  const { empresas = [] } = useSelector((state) => state.empresas);
  const { permisos = {} } = useSelector((state) => state.user);
  const { verificacion = {} } = useSelector((state) => state.verificaciones);
  const [verificacionData, setVerificacionData] = useState(verificacion);
  const [collapseDatosPersonales, setCollapseDatosPersonales] = useState(true);
  const [collapseDatosSolicitud, setCollapseDatosSolicitud] = useState(false);
  const [collapseDatosHistorialLaboral, setCollapseDatosHistorialLaboral] = useState(false);
  const [collapseDatosHistorialLegal, setCollapseDatosHistorialLegal] = useState(false);
  const [collapseDatosProcedimientosLegales, setCollapseDatosProcedimientosLegales] = useState(false);
  const [collapseDatosHistorialImss, setCollapseDatosHistorialImss] = useState(false);
  const [cleanImage, setcleanImage] = useState('');
  const [ verificacionPermiso, setVerificacionPermiso] = useState('none');
  const [ isOpen, setIsOpen] = useState(false);
  const [ typeModal, setTypeModal] = useState('');

  const toggleDsatosPersonales = () => setCollapseDatosPersonales(!collapseDatosPersonales);
  const toggleDatosSolicitud = () => setCollapseDatosSolicitud(!collapseDatosSolicitud);
  const toggleDatosHistorialLaboral = () => setCollapseDatosHistorialLaboral(!collapseDatosHistorialLaboral);
  const toggleDatosHistorialLegal = () => setCollapseDatosHistorialLegal(!collapseDatosHistorialLegal);
  const toggleDatosProcedimientosLegales = () => setCollapseDatosProcedimientosLegales(!collapseDatosProcedimientosLegales);
  const toggleDatosHistorialImss = () => setCollapseDatosHistorialImss(!collapseDatosHistorialImss);

  
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
    resolver: yupResolver(schemaVerificaciones),
  });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  const getEmpresas = useCallback(async () => {
    try {
      await dispatch(fetchEmpresasToDropdown());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const onChange = async ({ target }) => {
    if (!target.value) return;
    const filter = empresas.filter(x =>x.value === target.value);
    setEmpresa(filter[0].label);
  };
  
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
    getEmpresas();
    register({ name: 'archivos' }, { required: false });
    if ("verificaciones" in permisos) {
      setVerificacionPermiso(permisos["verificaciones"]);
    }
  }, [getEmpresas, register,errors]);

  useEffect(() => {
      console.log('verificacion',verificacion.historialLaboral);
      setVerificacionData(verificacion);
      console.log(verificacion.historialLaboral);
  }, [verificacion, dispatch])
  

  const onSubmit = async () => {
    const data = getValues();
    try {
      data.fechaNacimiento = data.fechaNacimiento ? parseInt(moment(data.fecha).format('x')) : 0;
      data.historialPenal = verificacionData.historialPenal;
      data.historialLaboral = verificacionData.historialLaboral;
      data.procedimientosLegales = verificacionData.procedimientosLegales;
      data.historialImms = verificacionData.historialImms;
      await dispatch(updateVerificacion(data));
      await SweetAlert({
        icon: 'success',
        title: 'Verificacion editada correctamente',
      });
      // history.replace('/dashboard/nomina/verificaciones');
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLaboral = async (e, index) => {
    e.preventDefault();
    const { historialLaboral = [] } = verificacionData;
    historialLaboral.splice(index, 1);
    dispatch(editarVerificacionExito(verificacionData));
  };

  const deleteAntecedente = async (e, index) => {
    e.preventDefault();
    const { historialPenal = [] } = verificacionData;
    historialPenal.splice(index, 1);
    dispatch(editarVerificacionExito(verificacionData));
  };

  const deleteLegal = async (e, index) => {
    e.preventDefault();
    const { procedimientosLegales = [] } = verificacionData;
    procedimientosLegales.splice(index, 1);
    dispatch(editarVerificacionExito(verificacionData));
  };

  const deleteImss = async (e, index) => {
    e.preventDefault();
    const { historialImms = [] } = verificacionData;
    historialImms.splice(index, 1);
    dispatch(editarVerificacionExito(verificacionData));
  };

  const toggleModal = (type) => {
    console.log(type);
    
    setTypeModal(type); 
    setIsOpen(!isOpen)
  };

  const generarPdf = async (e) => {
    const headers = {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    };
    const file = await axios.get(`${api}/verificaciones/pdf/${verificacion._id}`,headers)
    let byteString = atob(file.data);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ia], { type: 'application/pdf' });
    saveAs(blob, `verificacion-${verificacion.nombre}-${moment().format('YYYYMMDD')}`)
  }

  return (
    <>
    <AddModal isOpen={isOpen} setIsOpen={setIsOpen} typeModal={typeModal}/>
    <div className="d-flex justify-content-between align-items-center">
      <HeaderStyle style={{marginBottom:50}}>
        <h1>Nuevo Prospecto</h1>
        <h3>Agrega un nuevo prospecto</h3>
      </HeaderStyle>
      <WhiteButton onClick={e => generarPdf(e)}>
        <FontAwesomeIcon
          color={colors.primary}
          backgroundColor={colors.primary}
          icon={faCloudDownloadAlt}
          style={{ fontSize: '2vw', color:'#2B388F' }}
        />
        <span className="ml-1 font-weight-bold">Generar</span>
      </WhiteButton>
    </div>
    <div className="row col-sm-12" >
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDsatosPersonales}  
      >
        <h3 style={{ marginBottom: '5px' }}>
          Datos personales
        </h3>
        <FontAwesomeIcon 
          icon={collapseDatosPersonales ? faAngleUp : faAngleDown}
          size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosPersonales}
      >
        {/* <Form> */}
          <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
              Datos personales del prospectos
            </h5>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} for='foto'>
              Foto
            </Label>
            <Col sm={9}>
              <InputImage
                type='file'
                id='foto'
                name='foto'
                accept='image/jpg,image/jpeg,image/png'
                loadImageUrl={`${process.env.REACT_APP_API_URL}/archivo/descarga/${verificacionData['foto']}`}
                register={register}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Nombre*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='nombre'
                innerRef={register()}
                defaultValue={verificacionData['nombre']}
                placeholder='Introduzca nombre'
              />
              {err.nombre && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Nombre es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Fecha Nacimiento*</Label>
            <Col sm={9}>
              <Input
                type='date'
                name='fechaNacimiento'
                innerRef={register()}
                defaultValue={verificacionData['fechaNacimiento'] ? moment(verificacionData.fechaNacimiento).format('YYYY-MM-DD') : ''}
                placeholder='Introduzca la fecha de nacimiento'
              />
              {err.fechaNacimiento && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  La Fecha de nacimiento es requerida
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Lugar Nacimiento*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='lugarNacimiento'
                innerRef={register()}
                defaultValue={verificacionData['lugarNacimiento']}
                placeholder='Introduzca lugar de nacimiento'
              />
              {err.lugarNacimiento && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Lugar de Nacimiento es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Estado Civil*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='estadoCivil'
                defaultValue={verificacionData['estadoCivil']}
                innerRef={register()}
                placeholder='Introduzca Estado Civil'
              />
              {err.estadoCivil && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Estado Civil es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Domicilio*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='domicilio'
                defaultValue={verificacionData['domicilio']}
                innerRef={register()}
                placeholder='Introduzca Domicilio'
              />
              {err.domicilio && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Domicilio es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>NSS*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='nss'
                defaultValue={verificacionData['nss']}
                innerRef={register()}
                placeholder='Introduzca NSS'
              />
              {err.nss && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El NSS es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>CURP*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='curp'
                defaultValue={verificacionData['curp']}
                innerRef={register()}
                placeholder='Introduzca CURP'
              />
              {err.curp && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El CURP es requerido
                </span>
              )}
            </Col>
          </FormGroup>
        {/* </Form> */}
      </Collapse>
    </div>
    <div className="row col-sm-12 mt-2">
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDatosSolicitud}  
      >
        <h3 style={{ marginBottom: '5px' }}>
          Datos solicitud
        </h3>
        <FontAwesomeIcon 
          icon={collapseDatosSolicitud ? faAngleUp : faAngleDown}
          size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosSolicitud}
      >
        {/* <Form> */}
          <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
              Datos de la solicitud
            </h5>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Empresa*</Label>
            <Col sm={9}>
              <CustomSelect
                control={control}
                options={empresas}
                disabled={empresas.length > 0 ? false : true}
                type='select'
                name='empresa'
                defaultValue={verificacionData['empresa']}
                innerRef={register()}
                onChange={onChange}
              />
              {err.empresa && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  La Empresa es requerida
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Area*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='area'
                defaultValue={verificacionData['area']}
                innerRef={register()}
                placeholder='Introduzca Area'
              />
              {err.area && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Area es requerida
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Puesto*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='puesto'
                defaultValue={verificacionData['puesto']}
                innerRef={register()}
                placeholder='Introduzca Puesto'
              />
              {err.fechaNacimiento && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Puesto es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Comentarios</Label>
            <Col sm={9}>
              <TextAreaUtils
                name='comentarios'
                ref={register}
                defaultValue={verificacionData['comentarios']}
                placeholder='Comentarios relacionados con la situación del citatorio'
              />
            </Col>
          </FormGroup>
        {/* </Form> */}
      </Collapse>
    </div>
    {/* Historial laboral */}
    <div className="row col-sm-12 mt-2">
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDatosHistorialLaboral}  
      >
        <h3 style={{ marginBottom: '5px' }}>
        Historial laboral
        </h3>
        <FontAwesomeIcon 
        icon={collapseDatosHistorialLaboral ? faAngleUp : faAngleDown}
        size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosHistorialLaboral}
      >
        <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Demandas laborales
            </h5>
        </FormGroup>
        {
          verificacion?.historialLaboral.length ? (
            <TableContainer style={{ marginLeft: 30, marginTop:10}}>
              <TableCommon
                keysAceepted={['Expediente', 'Fecha', 'Demandante','Demandado','Estatus']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
              >
                <TableLaboralBody data={verificacionData.historialLaboral} deleteLaboral={deleteLaboral}/>
              </TableCommon>
            </TableContainer>
          ) :
          (
            <FormGroup row>
              <span className="ml-4">No cuenta con registro de demandas laborales</span>
            </FormGroup>
          )
        }
        { verificacionPermiso === 'write' && (
          <ExamLabel sm={3} onClick={e => toggleModal('Laboral')} style={{ cursor: 'pointer', marginTop:2 }} >+ Agregar</ExamLabel>
        )}
      </Collapse>
    </div>
    {/* Historial Legal */}
    <div className="row col-sm-12 mt-2">
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDatosHistorialLegal}  
      >
        <h3 style={{ marginBottom: '5px' }}>
        Historial Legal
        </h3>
        <FontAwesomeIcon 
        icon={collapseDatosHistorialLegal ? faAngleUp : faAngleDown}
        size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosHistorialLegal}
      >
        <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
              Antecedentes Penales
            </h5>
        </FormGroup>
        {
          verificacion?.historialPenal.length ? (
            <TableContainer style={{ marginLeft: 30, marginTop:10}}>
              <TableCommon
                keysAceepted={['Fecha', 'Delito']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
              >
                <TableAntecedentesBody data={verificacionData.historialPenal} deleteAntecedente={deleteAntecedente}/>
              </TableCommon>
            </TableContainer>
          ) :
          (
            <FormGroup row>
              <span className="ml-4">No cuenta con registro de antecedentes penales</span>
            </FormGroup>
          )
        }
        { verificacionPermiso === 'write' && (
          <ExamLabel amLabel sm={3} onClick={e => toggleModal('Antecedente')} style={{ cursor: 'pointer', marginTop:2 }} >+ Agregar</ExamLabel>
        )}
      </Collapse>
    </div>
    {/* Procedimientos legales */}
    <div className="row col-sm-12 mt-2">
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDatosProcedimientosLegales}  
      >
        <h3 style={{ marginBottom: '5px' }}>
        Procedimientos legales
        </h3>
        <FontAwesomeIcon 
        icon={collapseDatosProcedimientosLegales ? faAngleUp : faAngleDown}
        size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosProcedimientosLegales}
      >
        <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Otros procedimientos legales
            </h5>
        </FormGroup>
        {
          verificacion?.procedimientosLegales.length ? (
            <TableContainer style={{ marginLeft: 30, marginTop:10}}>
              <TableCommon
                keysAceepted={['Fecha', 'Juzgado', 'Expediente', 'Tipo', 'Actor', 'Demandado']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
              >
                <TableProcedimientosBody data={verificacionData.procedimientosLegales} deleteLegal={deleteLegal}/>
              </TableCommon>
            </TableContainer>
          ) :
          (
            <FormGroup row>
              <span className="ml-4">No cuenta con registro de otros procedimientos legales</span>
            </FormGroup>
          )
        }
        { verificacionPermiso === 'write' && (
          <ExamLabel sm={3} onClick={e => toggleModal('Procedimiento')} style={{ cursor: 'pointer', marginTop:2 }} >+ Agregar</ExamLabel>
        )}
      </Collapse>
    </div>
    {/* Historal IMSS */}
    <div className="row col-sm-12 mt-2">
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDatosHistorialImss}  
      >
        <h3 style={{ marginBottom: '5px' }}>
        Historial IMSS
        </h3>
        <FontAwesomeIcon 
        icon={collapseDatosHistorialImss ? faAngleUp : faAngleDown}
        size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosHistorialImss}
      >
        <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Historial ante el IMSS
            </h5>
        </FormGroup>
        {
          verificacion?.historialImms.length ? (
            <TableContainer style={{ marginLeft: 30, marginTop:10}}>
              <TableCommon
                keysAceepted={['Empresa', 'Alta', 'Baja', 'SBC']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
              >
                <TableImssBody data={verificacionData.historialImms} deleteImss={deleteImss}/>
              </TableCommon>
            </TableContainer>
          ) :
          (
            <FormGroup row>
              <span className="ml-4">No cuenta con registro ante el imss</span>
            </FormGroup>
          )
        }
        { verificacionPermiso === 'write' && (
          <ExamLabel sm={3} onClick={e => toggleModal('Imss')} style={{ cursor: 'pointer', marginTop:2 }} >+ Agregar</ExamLabel>
        )}
      </Collapse>
    </div>
    
    <FormGroup row className="mt-5">
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.replace('/dashboard/recursos-humanos/verificaciones');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <SaveButton onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
        </Col>
    </FormGroup>
    </>
  );
};

export default DetalleVerificacion;
