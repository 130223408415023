import React,{ Fragment,useState,useCallback,useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Form,
	Input,
	Label,
	Col,
	CustomInput
} from "reactstrap";
import {TextAreaUtils} from '../../colaboradores/UtilitarioViews/styled';
import { SaveButton,CancelButton } from '../../ui/styles/styles';
import InputFile from "../../ui/InputFile";
import ButtonDowload from '../../ui/ButtonDowload';
import CustomSelect from "../../ui/CustomSelect";
import {fetchUpdateAudiencias,fetchListaAudiencias} from '../../../store/audienciasDuck';
import DatePicker from "react-datepicker";

const tiposPruebas = [
	{label: 'Confesional',value:'Confesional'},
	{label: 'Testimonial',value:'Testimonial'},
	{label: 'Pericial',value:'Pericial'},
	{label: 'Inspeccion',value:'Inspeccion'},
	{label: 'Otros',value:'Otros'},
];

const AudienciaModal = ({ isOpen, setIsOpen, data }) => {
	const dispatch = useDispatch();
	const { audiencia = {} } = useSelector((state) => state.audiencias);
	const { 
		fecha='',
		hora='',
		tipo='',
		abogado='',
		cedula='',
		notas='',
		celular='',
		email='',
		documento='',
		empresa='',
		demanda='',
		tipoPrueba='',
		otros={},
		confesional={},
		testimonial={},
		pericial={},
		inspeccion={}
	} = audiencia;
	console.log(audiencia);
	const [confesionalForm, setConfesional] = useState(false);
	const [testimonialForm, setTestimonial] = useState(false);
	const [pericialForm, setPericial] = useState(false);
	const [inspeccionForm, setInspeccion] = useState(false);
	const [formOtros, setFormOtros] = useState(false);
	const [formTipoPrueba, setFormTipoPrueba] = useState(false);
	const { register, handleSubmit, control } = useForm({});

	const onSubmit = (objeto) => {
		try {
		  objeto.empresa = empresa;
		  objeto.demanda = demanda;
		  objeto.audiencia = audiencia._id;
		  dispatch(fetchUpdateAudiencias(objeto));
		  getListaAudiencias(data.demanda);
		  setIsOpen(false)
		} catch (error) {
			console.log(error);
		}
	};

	const getListaAudiencias = useCallback(async (demanda) => {
		try {
			dispatch(fetchListaAudiencias(demanda))
		} catch (error) {}
		},
		[dispatch]
	);
	useEffect(() => {
		if (tipo === "DESAHOGO DE PRUEBAS") {
			setFormTipoPrueba(true);
		}else{
			setFormTipoPrueba(false);
		}
		
		if (tipoPrueba === 'Confesional') {
			setConfesional(true);
		}if(tipoPrueba === 'Testimonial'){
			setTestimonial(true);
		}if(tipoPrueba === 'Pericial'){
			setPericial(true);
		}if(tipoPrueba === 'Inspeccion'){
			setInspeccion(true);
		}if(tipoPrueba === 'Otros'){
			setFormOtros(true);
		}
	}, [isOpen])

	const changePruebas = ({target}) =>{
		let value = target.value;
		console.log(value);
		if (value === '') {
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
		}
		if (value === 'Confesional') {
			setConfesional(true);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
		}if(value === 'Testimonial'){
			setTestimonial(true);
			setConfesional(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
		}if(value === 'Pericial'){
			setPericial(true);
			setConfesional(false);
			setTestimonial(false);
			setInspeccion(false);
			setFormOtros(false);
		}if(value === 'Inspeccion'){
			setInspeccion(true);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setFormOtros(false);
		}if(value === 'Otros'){
			setFormOtros(true);
			setConfesional(false);
			setInspeccion(false);
			setPericial(false);
			setTestimonial(false);
		}
	}

	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						{tipo}
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormGroup row>
							<Label sm={2}>Fecha</Label>
							<Col sm={4}>
								<Controller
									name='fecha'
									control={control}
									innerRef={register()}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value ? value : new Date(fecha)}
											onChange={onChange}
											dateFormat={'dd/MM/yyyy'}
										/>
									)}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Hora</Label>
							<Col sm={4}>
								<Input 
									type='time'
									name='hora'
									innerRef={register()}
									defaultValue={hora}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Abogado</Label>
							<Col sm={10}>
								<Input
									type='text'
									name='abogado'
									innerRef={register()}
									defaultValue={abogado}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Cédula</Label>
							<Col sm={10}>
								<Input 
									type='text'
									name='cedula'
									innerRef={register()}
									defaultValue={cedula}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Celular</Label>
							<Col sm={10}>
								<Input 
									type='text'
									name='celular'
									innerRef={register()}
									defaultValue={celular}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Email</Label>
							<Col sm={10}>
								<Input 
									type='text'
									name='email'
									innerRef={register()}
									defaultValue={email}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={2}>Notas</Label>
							<Col sm={10}>
								<TextAreaUtils
									name='notas'
									ref={register}
									defaultValue={notas}
									placeholder='Ingrese notas, de ser necesario'
								/>
							</Col>
						</FormGroup>
						{formTipoPrueba
						?
						<FormGroup row>
							<Label for='tipoPrueba' sm={2}>
								Tipo de prueba
							</Label>
							<Col sm={10}>
								<CustomSelect
									control={control}
									options={tiposPruebas}
									type='select'
									name='tipoPrueba'
									placeholder='Seleccione el tipo de prueba'
									innerRef={register()}
									defaultValue={tipoPrueba}
									onChange={changePruebas}
								/>
							</Col>
						</FormGroup>
						:null}
						{confesionalForm
						?
						<FormGroup row>
							<Col sm={3}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="Confesiones por posiciones"
								value="confesion"
								name="confesional.tipo"
								defaultChecked={confesional["tipo"] === "confesion"}
							/>
							</Col>
							<Col sm={3}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="Para hechos propios"
								value="hechos"
								name="confesional.tipo"
								defaultChecked={confesional["tipo"] === "hechos"}
							/>
							</Col>
							<Col sm={3}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="Declaracion por parte"
								value="declaracion"
								name="confesional.tipo"
								defaultChecked={confesional["tipo"] === "declaracion"}
							/>
							</Col>
							<Col sm={3}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="Otros"
								value="otros"
								name="confesional.tipo"
								defaultChecked={confesional["tipo"] === "otros"}
							/>
							</Col>
						</FormGroup>
						:null}
						{testimonialForm
						?
						<Fragment>
						<FormGroup row>
							<Col sm={12}>
								<Input
									type='text'
									name='testimonial.testigo1'
									placeholder='Testigos'
									innerRef={register()}
									defaultValue={testimonial["testigo1"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm={12}>
								<Input
									type='text'
									name='testimonial.testigo2'
									placeholder='Testigos'
									innerRef={register()}
									defaultValue={testimonial["testigo2"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm={12}>
								<Input
									type='text'
									name='testimonial.testigo3'
									placeholder='Testigos'
									innerRef={register()}
									defaultValue={testimonial["testigo3"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm={12}>
								<Input
									type='text'
									name='testimonial.testigo4'
									placeholder='Testigos'
									innerRef={register()}
									defaultValue={testimonial["testigo4"]}
								/>
							</Col>
						</FormGroup>
						</Fragment>
						:null}
						{pericialForm
						?
						<Fragment>
						<FormGroup row>
							<Label for='tipo' sm={2}>
								Tipo de pericial
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									name='pericial.tipo'
									placeholder='Tipo de pericial'
									innerRef={register()}
									defaultValue={pericial["tipo"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for='nombrePerito' sm={2}>
								Nombre del perito
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									name='pericial.nombrePerito'
									placeholder='Tipo de perito'
									innerRef={register()}
									defaultValue={pericial["nombrePerito"]}
								/>
							</Col>
						</FormGroup>
						</Fragment>
						:null}
						{inspeccionForm
						?
						<Fragment>
						<FormGroup row>
							<Label for='tipo' sm={2}>
								Tipo de inspección
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									name='inspeccion.tipo'
									placeholder='Tipo de inspección'
									innerRef={register()}
									defaultValue={inspeccion["tipo"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for='nombrePerito' sm={2}>
								Nombre del perito
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									name='inspeccion.nombrePerito'
									placeholder='Tipo de perito'
									innerRef={register()}
									defaultValue={inspeccion["nombrePerito"]}
								/>
							</Col>
						</FormGroup>
						</Fragment>
						:null}
						{formOtros
						?
						<Fragment>
						<FormGroup row>
							<Label for='tipoAudiencia' sm={2}>
								Tipo de prueba
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									name='otros.tipoAudiencia'
									placeholder='Tipo de prueba'
									innerRef={register()}
									defaultValue={otros["tipoAudiencia"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for='informacion' sm={2}>
								Información
							</Label>
							<Col sm={10}>
								<TextAreaUtils
									name='otros.informacion'
									placeholder='Información'
									innerRef={register()}
									defaultValue={otros["informacion"]}
								/>
							</Col>
						</FormGroup>
						</Fragment>
						:null}
						<FormGroup row>
							<Label sm={2}>Documento</Label>
							<Col sm={{ offset: 1, size: 3 }}>
								<InputFile
									type='file'
									id='documento'
									name='documento'
									register={register}
								/>
							</Col>
							{documento?
							<Col sm={3}>
								<ButtonDowload
									Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${documento}`}
								/>
							</Col>
							:null}
						</FormGroup>
					</Form>
					<FormGroup row>
						<Col sm={{ offset: 4, size: 4 }}>
							<CancelButton
								onClick={() => setIsOpen(false)}
								style={{ marginLeft: 'auto' }}>
								Cancelar
							</CancelButton>
						</Col>
						<Col sm={3}>
							<SaveButton type='button' onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
						</Col>
					</FormGroup>
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default AudienciaModal;