import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, CustomInput } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sucursalDetalleSchema } from '../../constans/yupSchemas';
import { editarAction } from '../../store/sucursalDuck';
import SweetAlert from '../ui/SweetAlet';
import {
  InputUtils,
  SubTitleMenu,
  LabelForms,
  CancelButton,
} from '../ui/styles/styles';
import BotonCargando from '../BotonCargando';

const DetalleSucursal = React.memo(() => {
  const { encargado = {}, configuracion, nombreSucursal = '' } = useSelector(
    (state) => state.sucursal
  );
  const {
    nombres = '',
    apellidoPaterno = '',
    apellidoMaterno = '',
    telefono = null,
    email = '',
  } = encargado;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
  } = useForm({
    resolver: yupResolver(sucursalDetalleSchema),
  });
  const [err, setErr] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length) { setErr(errors); }
  }, [errors]);

  const submit = async (data) => {
    try {
      setSaving(true);
      data.configuracion = configuracion;
      dispatch(editarAction(data));
      await SweetAlert({ icon: 'success', title: 'Editado exitosamente', showConfirmButton: false, timer: 2500 });
      history.goBack();
    } catch (e) {
      console.log(e);
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
    } finally {
      setSaving(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Información Sucursal</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Nombre Sucursal*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            defaultValue={nombreSucursal}
            name='nombreSucursal'
            placeholder='Introduzca el nombre del la sucursal'
            ref={register()}
          />
          {err.nombreSucursal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre sucursal es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Encargado</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Nombres*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            defaultValue={nombres}
            name='encargado.nombres'
            placeholder='Introduzca el nombres del encargado sucursal'
            ref={register()}
          />
          {err.encargado && err.encargado.nombres && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Apellido Paterno*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            defaultValue={apellidoPaterno}
            placeholder='Introduzca el apellido paterno del encargado sucursal'
            name='encargado.apellidoPaterno'
            ref={register()}
          />
          {err.encargado && err.encargado.apellidoPaterno && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El apellido paterno es requerido
              </span>
            )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Apellido Materno*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            defaultValue={apellidoMaterno}
            placeholder='Introduzca el apellido materno del encargado sucursal'
            name='encargado.apellidoMaterno'
            ref={register()}
          />
          {err.encargado && err.encargado.apellidoMaterno && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El apellido materno es requerido
              </span>
            )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Teléfono*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='number'
            placeholder='Introduzca el numero teléfono del encargado sucursal'
            defaultValue={telefono}
            name='encargado.telefono'
            ref={register()}
          />
          {err.encargado && err.encargado.telefono && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El teléfono es requerido
              </span>
            )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Email*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='email'
            defaultValue={email}
            placeholder='Introduzca el email del encargado sucursal'
            name='encargado.email'
            ref={register()}
          />
          {err.encargado && err.encargado.email && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El email es requerido
              </span>
            )}
        </Col>
      </FormGroup>
      <br /><br />
      <FormGroup className='row'>
        <Col sm={{ offset: 2, size: 2 }} className={'pr-2'} style={{ display: 'inherit' }}>
          <CancelButton
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={2} className={'pl-2'} style={{ display: 'inherit' }}>
          <BotonCargando
            cargando={saving}
            disabled={saving}
            texto='Guardar'
            type='submit'
          />
        </Col>
      </FormGroup>
    </Form>
  );
});
export default DetalleSucursal;
