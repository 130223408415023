import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import Paginacion from '../Paginacion';
//Estructira de cabecera
//id: identificador que no sirbe para relacionarlo con la propiedad del objeto en cada renglon
//titulo: Se usara para mostrar en cada columna de la cabecera.

//Ejmeplo
/* const cabecera = [
  {
    id: 'puesto',
    titulo: 'Puesto',
  }
]; */

//Fin estructura de cabecera

//Estructura de datos: Es tal cual el array de mongo

//Estructura de columnasExtra
//id: identificador que no sirbe para relacionarlo con la propiedad del objeto en cada renglon
//titulo: Se usara para mostrar en cada columna de la cabecera.
//render: Servira para dibujar lo que se quiera en particular en cada renglon.
//empujarAlInicio: Si es true, se empujará al inicio del array de cabecera.

//ejemplo
/* const columnasExtra = [
  id: "nombreCompleto",
  titulo: "Nombre completo",
  render: (dato) => (<>{dato.nombre} {dato.apellido}</>)
  empujarAlInicio: true
]; */

//paginacion: objeto plugin de mongo pagiancion v2
//cambioPagina: CallBack que se ejecutara cuando se cambie de pagina, tiene como paramtro el numero de pagina

const Tabla = ({cabecera = [], datos = [], columnasExtra = [], paginacion = null, cambioPagina, clickRenglon}) => {
  const [cabeceraTabla, setCabeceraTabla] = useState([]);
  const [datosTabla, setDatosTabla] = useState([]);
  const [paginacionTabla, setPaginacionTabla] = useState(null);

  useEffect(() => {
    if(cabecera.length && datos.length) {
      const cabeceraInicio = [];
      const cabeceraFin = [];
      const _contenido = datos.map(dato => {
        let _dato = {};

        columnasExtra.filter(columna => columna.empujarAlInicio).forEach(columna => {
          if(!cabeceraInicio.some(cabecera => cabecera.id === columna.id)) {
            const _columna = {id: columna.id, titulo: columna.titulo};
            cabeceraInicio.push(_columna);
          }

          _dato[columna.id] = columna.render(dato);
        }); 

        cabecera.forEach(cabecera => {
          _dato[cabecera.id] = dato[cabecera.id];
        });

        columnasExtra.filter(columna => !columna.empujarAlInicio).forEach(columna => {
          if(!cabeceraFin.some(cabecera => cabecera.id === columna.id)) {
            const _columna = {id: columna.id, titulo: columna.titulo};
            cabeceraFin.push(_columna);
          }

          _dato[columna.id] = columna.render(dato);
        });

        for (const key in dato) {
          if(_dato[key] === undefined) {
            _dato[key] = dato[key];
          }
        }

        return _dato;
      });

      setCabeceraTabla([...cabeceraInicio, ...cabecera, ...cabeceraFin]);
      setDatosTabla(_contenido);
    }
  }, [cabecera, datos, columnasExtra]);

  useEffect(() => {
    if(paginacion) {
      setPaginacionTabla(paginacion);
    }
  }, [paginacion]);

  if(!cabeceraTabla.length || !datosTabla.length) return null;

  return (
    <>
      <Table hover>
        <thead>
          <tr>
          {
            cabeceraTabla.map(c => (<th key={c.id}>{c.titulo}</th>))
          }
          </tr>
        </thead>
        <tbody>
        {
          datosTabla.map((renglon) => (
            <tr key={renglon._id} onClick={() => clickRenglon && clickRenglon(renglon)} style={{cursor: "pointer"}}>
            {
              Object.keys(renglon)
                .filter(key => cabeceraTabla.some(cab => cab.id === key))
                .map((key, i) => (
                  <td key={i}>{renglon[key]}</td>
                ))
            }
            </tr>
          ))
        }
        </tbody>
      </Table>
      {
        paginacionTabla && <Paginacion
          pagina={paginacionTabla.page}
          totalPaginas={paginacionTabla.totalPages}
          cambioPagina={cambioPagina}
        />
      }
    </>
  )
}

export default Tabla;