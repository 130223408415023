import React,{ Fragment,useState,useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Form,
	Input,
	Label,
	Col,
	CustomInput
} from "reactstrap";
import { SaveButton,CancelButton } from '../../ui/styles/styles';
import CustomSelect from '../../ui/CustomSelect';
import CurrencyFormat from 'react-currency-format';
import '../../colaboradores/Forms/CondicionesLaborales.css';
import SweetAlert from "../../ui/SweetAlet";
import optionsBancos from "../../../constans/bancos";
import { schemaConvenio } from '../../../constans/yupSchemas';
import {yupResolver} from '@hookform/resolvers/yup';
import DatePicker from "react-datepicker";

const pagosOptions = [
	{ label: 'Efectivo', value: 'Efectivo' },
	{ label: 'Cheque', value: 'Cheque' },
	{ label: 'Otro', value: 'Otro' }
  ];

const ModalFiniquitoAdd = ({ isOpen, setIsOpen,setListaFiniquitos, listaFiniquitos=[], montoConvenio,restante,setRestante }) => {
	const { register, handleSubmit, errors, control } = useForm({
		resolver: yupResolver(schemaConvenio),
	});
	const [cheque, setCheque] = useState(false);
	const [err, setErr] = useState({});
	const [mon, setMon] = useState({monto:''});
	const {monto} = mon;
	const [especifica, setEspecifica] = useState(false);
	const [notEmail, setNotEmail] = useState(false);
	const onSubmit = async (objeto) => {
		if (monto > restante) {
			await SweetAlert({ icon: 'warning', title: 'El monto sobrepasa el restante' });
		}else{
			objeto.monto = monto;
			setListaFiniquitos(oldArray => [...oldArray,objeto]);
			setMon({monto:''});
			setIsOpen(false);
			setEspecifica(false);
			setCheque(false);
		}
		
	};

	const handleChange = ({target}) =>{
		let valor = target.value;
		if (valor === 'Cheque') {
			setCheque(true);
			setEspecifica(false);
		}if (valor === 'Otro') {
			setEspecifica(true);
			setCheque(false);
		}if (valor === '') {
			setEspecifica(false);
			setCheque(false);
		}if (valor === 'Efectivo') {
			setEspecifica(false);
			setCheque(false);
		}
	}

	useEffect(() => {
		let suma='';
		if (listaFiniquitos.length) {
			listaFiniquitos.forEach(x => {
				suma += parseFloat(x.monto);
			});
			
		}else{
			setRestante(montoConvenio);
		}
		let rest = montoConvenio - suma;
		setRestante(rest);
	});
	useEffect(() => {
		if (Object.keys(errors).length) setErr(errors);
	  }, [errors]);
	
	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Agregar Convenio de Pago
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormGroup row>
							<Label sm={3}>Cantidad Restante</Label>
							<Col sm={9}>
							<CurrencyFormat
								value={restante}
								displayType={'text'}
								decimalScale={2}
								thousandSeparator={true}
								prefix={'$'}
								renderText={value => <span>{value}</span>}
							/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Monto*</Label>
							<Col sm={9}>
								<CurrencyFormat
									fixedDecimalScale={true}
									decimalScale={2}
									className='inputMoneda'
									name='monto'
									value={monto}
									placeholder='Ej. $5,000.00'
									innerRef={register()}
									thousandSeparator={true}
									prefix={'$'}
									onValueChange={(values) => {
										const { value } = values;
										setMon({ monto: value })
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Fecha*</Label>
							<Col sm={4}>
								<Controller
									name='fecha'
									control={control}
									innerRef={register()}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											dateFormat={'dd/MM/yyyy'}
										/>
									)}
								/>
								{err.fecha && (
									<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
									La Fecha es requerida
									</span>
								)}
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Forma de Pago*</Label>
							<Col sm={9}>
								<CustomSelect
									control={control}
									options={pagosOptions}
									type='select'
									name='formaPago'
									innerRef={register()}
									onChange={handleChange}
									placeholder='Selecciona forma de pago'
								/>
								{err.formaPago && (
									<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
									La Forma de pago es requerida
									</span>
								)}
							</Col>
						</FormGroup>
						{especifica ?
						<FormGroup row>
							<Label sm={3}>Especifica</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='otro'
									innerRef={register()}
									placeholder='Especifica la forma de pago'
								/>
							</Col>
						</FormGroup>:null}
						{cheque ?
						<>
						<FormGroup row>
							<Label sm={3}>Numero de cheque</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='numeroCheque'
									innerRef={register()}
									placeholder='Numero de cheque'
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Numero de cuenta</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='cuenta'
									innerRef={register()}
									placeholder='Numero de cuenta'
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Banco</Label>
							<Col sm={9}>
								<CustomSelect
									control={control}
									options={optionsBancos}
									type='select'
									name='banco'
									innerRef={register()}
									placeholder='Selecciona el banco'
								/>
							</Col>
						</FormGroup>
						</>
						:null}
						<FormGroup row>
							<Label sm={3}>Recordatorio*</Label>
							<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="SI"
								value={true}
								name="recordatorio"
								onClick={() => setNotEmail(true)}
							/>
							</Col>
							<Col sm={1}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="NO"
								value={false}
								name="recordatorio"
								onClick={() => setNotEmail(false)}
							/>
							{err.recordatorio && (
									<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
									requerido
									</span>
								)}
							</Col>
						</FormGroup>
						{notEmail ?
						<FormGroup row>
							<Label sm={3}>Agendar recordatorio</Label>
							<Col sm={4}> 
								<Controller
									name='fechaRecordatorio'
									control={control}
									innerRef={register()}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											dateFormat={'dd/MM/yyyy'}
										/>
									)}
								/>
							</Col>
						</FormGroup>:null}
					</Form>
					<FormGroup row>
						<Col sm={{ offset: 4, size: 4 }}>
							<CancelButton
								onClick={() => setIsOpen(false)}
								style={{ marginLeft: 'auto' }}>
								Cancelar
							</CancelButton>
						</Col>
						<Col sm={3}>
							<SaveButton type='button' onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
						</Col>
					</FormGroup>
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default ModalFiniquitoAdd;