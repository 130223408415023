import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import PromocionesView from './PromocionesViews/PromocionesView';
import FormPromocion from './PromocionesViews/FormPromocionView';

const Promociones = ({disabled = false}) => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/promociones/agregar' component={(props) => <FormPromocion disabled={disabled} {...props} />} />
                <Route path='/' component={(props) => <PromocionesView disabled={disabled} {...props} />} /> 
            </Switch>
        </HashRouter>
    );
};

export default Promociones;