import React from 'react';
import { useForm } from 'react-hook-form';
import {
	FormGroup,
	Input,
	Label,
	Col,
} from "reactstrap";
import { SaveButton,CancelButton } from '../../ui/styles/styles';


const LaboralForm = ({setIsOpen, submitLaboral}) => {
  
  const { register, handleSubmit, errors, control, getValues } = useForm({});

  const onSubmit = async () => {
    submitLaboral(getValues());
  }

  return (
    <div>
      <FormGroup row>
        <Label sm={3}>Fecha</Label>
        <Col sm={4}>
          <Input
            type='date'
            name='fecha'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Expediente</Label>
        <Col sm={9}>
          <Input 
            type='text'
            name='expediente'
            innerRef={register()}
            placeholder='Expediente'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Demandante</Label>
        <Col sm={9}>
          <Input
            type='text'
            name='demandante'
            innerRef={register()}
            placeholder='Demandante'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Demandado</Label>
        <Col sm={9}>
          <Input
            type='text'
            name='demandado'
            innerRef={register()}
            placeholder='Demandado'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Estatus</Label>
        <Col sm={9}>
          <Input
            type='text'
            name='estatus'
            innerRef={register()}
            placeholder='Estatus'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={{ offset: 4, size: 4 }}>
          <CancelButton
            onClick={() => setIsOpen(false)}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <SaveButton type='button' onClick={handleSubmit(onSubmit)}>Agregar</SaveButton>
        </Col>
      </FormGroup>
    </div>
  )
};

export default LaboralForm;
