export default {
    primary:'#EBECF4',
    secondary:'#2B388F',
    borderColor:'#979797',
    textColorPrimary:'#767676',
    textColorSecondary:'#797979',
    labelText:'#7B7B7B',
    submenuTextColor:'#3C3C3C',
    cardSecondaryText:'#9B9B9B',
    buttonColorPrimary:'#A6AFB9',
    buttonColorSecondary:'#B2BDC8',
    iconsColors:'#969696',
    tashColor:'#3C3C3C',
    closeStatus:'#E96060',
    yellow:'#ffc107'
}