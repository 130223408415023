import React,{useState,useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { cierreCitatorioSchema } from '../../constans/yupSchemas';
import { cierreCitatorio } from '../../store/citatorioDuck';
import { Form, Input, FormGroup, Label, Col, Button,CustomInput } from 'reactstrap';
import InputFile from '../ui/InputFile';
import SweetAlert from "../ui/SweetAlet";
import { cleanInvalidFieldsInObject } from '../../helpers';
import { SaveButton,CancelButton } from '../ui/styles/styles';
import CustomSelect from '../../components/ui/CustomSelect';
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';

const motivoOptions = [
  {label:'Inconformidad resuelta satisfactoriamente',value:'Inconformidad resuelta satisfactoriamente'},
  {label:'Inconformidad no resuelta',value:'Inconformidad no resuelta'},
  {label:'Inconformidad finiquito laboral',value:'Inconformidad finiquito laboral'}
];

const CerrarCitatorio = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [err, setErr] = useState({});
  const [esDemanda, setEsDemanda] = useState(false);
  const [esFiniquito, setEsFiniquito] = useState(false);
  const [resuelta, setResuelta] = useState(false);
  const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
    resolver: yupResolver(cierreCitatorioSchema),
  });
  console.log(errors);
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors])

  const getFile = (files) => {
    register({ name: 'evidencia' }, { required: false, validate: true });
    setValue('evidencia', files[0]);
  };

  const onChange = async ({target}) =>{
    let value = target.value;
    if (!value) {
			setEsDemanda(false);
      setEsFiniquito(false);
      setResuelta(false);
		}
    if (value === "Inconformidad no resuelta") {
      setEsDemanda(true);
      setEsFiniquito(false);
      setResuelta(false);
    }
    if (value === "Inconformidad resuelta satisfactoriamente") {
      setResuelta(true);
      setEsDemanda(false);
      setEsFiniquito(false);
    }
    if (value === "Inconformidad finiquito laboral") {
      setEsFiniquito(true);
      setResuelta(false);
      setEsDemanda(false);
    }
  }

  const onSubmit = async (data) => {
    try {
      const cleanData = cleanInvalidFieldsInObject(data);
      await dispatch(cierreCitatorio(cleanData));
      await SweetAlert({ icon: 'success', title: 'Citatorio cerrado correctamente' });
      console.log(data);
      if (data.motivo === "Inconformidad no resuelta") {
          if (data.esDemanda === "true") {
            history.replace('/dashboard/juridico/nueva-demanda');
          } else {
            history.replace('/dashboard/juridico/citatorios');
          }
      }else if(data.motivo === "Inconformidad finiquito laboral"){
        if (data.esFiniquito === "true") {
          history.replace('/dashboard/juridico/nuevo-finiquito');
        } else {
          history.replace('/dashboard/juridico/citatorios');
        }
      }else{
        history.replace('/dashboard/juridico/citatorios');
      }
    } catch (error) {

    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Cerrar Citatorio
        </h5>
      </FormGroup>
      <FormGroup row>
				<Label sm={2}>Motivo*</Label>
				<Col sm={10}>
					<CustomSelect
						control={control}
						options={motivoOptions}
						type='select'
						name='motivo'
						innerRef={register()}
						onChange={onChange}
					/>
          {err.motivo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Motivo es requerido
            </span>
          )}
				</Col>
			</FormGroup>
      <FormGroup row>
        <Label sm={2}>Inconformidad*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='inconformidad'
            innerRef={register()}
            placeholder='Señalar motivo de inconformidad'
          />
          {err.inconformidad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Razon es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      {resuelta ?
      <FormGroup row>
        <Label sm={2}>Solución</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='solucion'
            innerRef={register()}
            placeholder='Especificar la solución de la inconformidad'
          />
        </Col>
      </FormGroup>
      :null}
      {esFiniquito?
      <FormGroup row>
        <Label sm={2}>Propuesta</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='propuesta'
            innerRef={register()}
            placeholder='Especificar la propuesta de finiquito'
          />
        </Col>
      </FormGroup>
      :null}
      <FormGroup row>
        <Label sm={2}>Nota adicional</Label>
        <Col sm={10}>
          <TextAreaUtils
            name='notaCierre'
            ref={register}
            placeholder='Detalles adicionales'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Persona que atendió</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='atendio'
            innerRef={register()}
            placeholder='Persona que atendió citatorio'
          />
        </Col>
      </FormGroup>
      {esDemanda ?
      <FormGroup row>
        <Label sm={2}>¿Crear expediente laboral?</Label>
        <Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="SI"
            value={true}
            name="esDemanda"
          />
        </Col>
        <Col sm={1}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="NO"
            value={false}
            name="esDemanda"
          />
        </Col>
      </FormGroup>
      :null}
      {esFiniquito ?
      <FormGroup row>
        <Label sm={2}>¿Crear finiquito?</Label>
        <Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="SI"
            value={true}
            name="esFiniquito"
          />
        </Col>
        <Col sm={1}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="NO"
            value={false}
            name="esFiniquito"
          />
        </Col>
      </FormGroup>
      :null}
      <FormGroup row>
        <Label sm={2} for='evidencia'>
          Evidencia
        </Label>
        <Col sm={10}>
          <InputFile
            name='evidencia'
            type='file'
            id='evidencia'
            getFile={getFile}
          />
        </Col>
      </FormGroup>
      

      <FormGroup row>
          <Col sm={{ offset: 6, size: 3 }}>
            <CancelButton
              onClick={() => {
                history.replace('/dashboard/juridico/citatorios');
              }}
              style={{ marginLeft: 'auto' }}>
              Cancelar
            </CancelButton>
          </Col>
          <Col sm={3}>
            <SaveButton type='submit'>Cerrar Citatorio</SaveButton>
          </Col>
      </FormGroup>
    </Form>
  );
};

export default CerrarCitatorio;
