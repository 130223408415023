import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportesSchema } from '../../constans/yupSchemas';
import getTxt from '../../services/dowloadTxt';
import { fetchEmpresasToDropdown, fetchreport, cleanReportes } from '../../store/empresasDuck';
import { TitleMenu, SubTitleMenu, SaveButton } from '../ui/styles/styles';
import { ContainerReportes } from './styles/styles';
import CustomSelect from '../ui/CustomSelect';
import TableDinamic from '../ui/TableDinamicDelete';
import Spiner from '../ui/Spiner';
import PaginationComponent from 'react-reactstrap-pagination';
import './styles/PaginationComponent.css';
import SweetAlert from '../ui/SweetAlet';
import axios from '../../services/axios';
import BotonCargando from '../BotonCargando';

const api = process.env.REACT_APP_API_URL;

let loadingFirst = true;

const Reportes = () => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  const dispatch = useDispatch();
  const { empresas, loading, reportes = {} } = useSelector(
    (state) => state.empresas
  );
  const [dataBody, setDataBody] = useState({});
  const [page, setPage] = useState(null);
  const [request, setRequest] = useState({})
  const [showPaginador, setPaginador] = useState(false);
  const { register, control, getValues } = useForm(
    {
      resolver: yupResolver(reportesSchema),
    }
  );
  const [excludedIds, setExcludedIds] = useState([]);
  const [registros, setRegistros] = useState([]);
  const [registroSelected, setRegistroSelected] = useState('');
  const [downloading, setDownloading] = useState(false);

  const registrosToDwopDown = async (empresa) => {
    const { data } = await axios.get(`${api}/empresa/obtener_registros/${empresa}`, headers)
    if (data) {
      const rp = [{ label: "Seleccione opción", value: "" }]
      data.response.map(r => {
        rp.push({ label: r, value: r })
      });
      setRegistros(rp)
    }
    console.log('fetch registros to dd');
  }
  
  console.log(page);

  useEffect(() => {
    const data = getValues();
    
    if (request && page) {
      data.nombreReporte = 'idse';
      data.requestedData = request;
      data.page = page;
      setDataBody(() => data);
      dispatch(fetchreport(data));
    }
  }, [page])

  useEffect(() => {
    dispatch(cleanReportes());
  }, [])

  useEffect(() => {
    dispatch(fetchEmpresasToDropdown());
    loadingFirst = false;
  }, [dispatch]);

  if (loadingFirst) {
    return <Spiner />;
  }

  const onSubmit = async () => {
    try {
      const data = getValues();
      data.nombreReporte = 'idse';
      data.registro = registroSelected;
      data.requestedData = request;
      setDataBody(() => data);
      setPaginador(true);
      setPage(1);
      dispatch(fetchreport(data));
      setExcludedIds([])
    } catch (error) { 
      console.log(error);
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
    }
  };

  const dowloadTxt = async () => {
    if (reportes.docs) {
      const { isConfirmed } = await SweetAlert({
        title: 'Al exportar los Colaboradores seran marcados como enviados a IDSE',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        try {
          setDownloading(true);
          await getTxt({ body: dataBody, reporte: 'idse', nombre: request, ids: excludedIds });
        } catch (error) {
          console.log(error);          
        } finally {
          setDownloading(false);
        }
      }
    } else SweetAlert({ icon: "warning", title: "Información", text: 'No hay resultados a exportar' });
  }

  const tipos = [
    { value: 'reingresos', label: 'Reingresos' },
    { value: 'bajas', label: 'Bajas' },
    { value: 'cambiosSBC', label: 'Cambios S.B.C' }
  ];

  const onChange = ({ target }) => {
    if (target.value) setRequest(() => target.value);
  };

  return (
    <ContainerReportes>
      <div style={{ minHeight: '15vh' }}>
        <TitleMenu>IDSE</TitleMenu>
        <SubTitleMenu style={{ color: '#404041', fontWeight: '400' }}>
          Genera los reportes del IDSE de tus colaboradores
        </SubTitleMenu>
      </div>
      <Form>
        <FormGroup row>
          <Label for='empresa' sm={1}>
            Empresa
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='empresas[0]'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={empresas}
              onChange={e => registrosToDwopDown(e.target.value)}
            />
          </Col>
          <Label sm={1}>Registro</Label>
          <Col sm={3}>
            <Input
              disabled={request === "cambiosSBC"}
              type='select'
              name='registro'
              id='registro'
              value={registroSelected}
              onChange={e => setRegistroSelected(e.target.value)}
            >
              {registros && registros.length > 0 ?
                registros.map(r => (<option value={r.value}>{r.label}</option>))
                : <option value=''>Seleccione una empresa</option>}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='empresa' sm={1}>
            Reporte
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='nombreReporte'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={tipos}
              onChange={onChange}
            />
          </Col>
          <Col sm={3}>
            <BotonCargando texto='Procesar' onClick={onSubmit} cargando={loading} disabled={loading} />
          </Col>
          <Col sm={3}>
            <BotonCargando texto='Exportar' onClick={dowloadTxt} cargando={downloading} disabled={downloading} />
          </Col>
          <Col sm={12}>
            {loading ?<div style={{marginTop: 20}}> <Spiner  /></div>  : reportes.docs ? <TableDinamic reportes={reportes} excludedIds={excludedIds} setExcludedIds={setExcludedIds} /> : ''}
          </Col>
        </FormGroup>
      </Form>
      <br />
      <br />
      <Col hidden={!showPaginador}>
        <PaginationComponent
          size={"sm"}
          defaultActivePage={page}
          firstPageText="Primera"
          previousPageText="Anterior"
          nextPageText="Siguiente"
          lastPageText="Ultima"
          totalItems={reportes?.total || 0}
          pageSize={reportes?.limit || 0}
          onSelect={setPage}
        />
      </Col>
    </ContainerReportes>
  );
};

export default Reportes;
