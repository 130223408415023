import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { CancelButton, InputUtils, LabelForms, SaveButton, SubTitleMenu, TextAreaUtils } from '../../ui/styles/styles';
import { actaEditFinish, createActa, deleteActa, editActa } from '../../../store/actasDuck';
import { schemaActaAdministrativa } from '../../../constans/yupSchemas';
import { faTrash, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import Arrows from '../../ui/Arrows';
import CustomSelect from '../../ui/CustomSelect';
import CustomSelectEstados from '../../ui/CustomSelectEstados';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { validate } from 'isemail';
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

const motivos = [
    {label:'Artículo 47 LFT', value:'Artículo 47 LFT'},
    {label:'Artículo 134 LFT', value:'Artículo 134 LFT'},
    {label:'Reglamento Interior de Trabajo', value:'Reglamento Interior de Trabajo'},
    {label:'Otro', value:'Otro'},
]

const FormActa = React.memo(({ history, disabled = false }) => {

    const headers = {
        headers: {
            token: localStorage.getItem("pv2token"),
        },
    };
    
    const isEdit = useSelector(state => state.actas.isEdit);
    const dto = useSelector(state => state.actas.dto);
    const colaborador = useSelector(state => state.colaborador._id);
    const { permisos = {} } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const [isFormEdit, setFormEdit] = useState(false);
    const [err, setErr] = useState({});
    const [modal, setModal] = useState(false);
    const [modalNotificacion, setModalNotificacion] = useState(false);
    const [modalTestigo, setModalTestigo] = useState(false);
    const [testigo, setTestigo] = useState({
        nombre: '', 
        sexo: '',
        ine: '',
        domicilio: '',
        ciudad: '',
        estado: '',
        declaracion: ''
    });
    const [notificacion, setNotificacion] = useState({
        nombre: '',
        puesto: '',
        email: '',
        notas: ''
    });
    const [participantes, setParticipantes] = useState(dto['participantes'] ? dto['participantes'] : []);
    const [cierreNotificaciones, setCierreNotificaciones] = useState(dto['cierreNotificaciones'] ? dto['cierreNotificaciones'] : []);
    const [testigos, setTestigos] = useState(dto['testigos'] ? dto['testigos'] : []);
    const [motivo, setMotivo] = useState(dto.hechoMotivo ? dto.hechoMotivo : '');
    const [notificar, setNotificar] = useState(dto['cierreNotificacion']);

    const toggle = () => setModal(!modal);
    const toggleTestigo = () => setModalTestigo(!modalTestigo);
    const toggleNotificacion = () => setModalNotificacion(!modalNotificacion);

    const { register, handleSubmit, errors, control, setValue, getValues} = useForm({
        resolver: yupResolver(schemaActaAdministrativa)
    });

    useEffect(() => {
        if (Object.keys(errors).length) setErr(errors);
    }, [errors]);

    useEffect(()=>{
        if(isEdit){
            setFormEdit(true);
        }
    },[isEdit]);

    const cancel = async () =>{
        await dispatch(actaEditFinish());
    }

    const elminarActa = async () => {
        try {
            const { isConfirmed } = await SweetAlert({
                title: '¿Seguro que desea eliminar?',
                text: 'Esta acción no se podrá revertir.',
                showCancelButton: 'Cancelar',
            });
            if (isConfirmed) {
                await dispatch(deleteActa(dto._id));
                SweetAlert({title:'Actas actualizadas', icon: 'success'});
                history.push('/');
            }
        } catch (err) {
            SweetAlert({title:'Error!', text: err, icon: 'error'});
            console.log(err);
        }
    }

    const onSubmit = async (data) => {
        try {
            data.participantes = participantes;
            data.testigos = testigos;
            data.cierreNotificaciones = cierreNotificaciones;
            data.fechaLevantamiento = new Date(data.fechaLevantamiento);
            data.cierreFecha = new Date(data.cierreFecha);

            if(isEdit)
                await dispatch(editActa(data));
            else
                await dispatch(createActa(data));
            SweetAlert({title:'Actas actualizadas', icon: 'success'});
            history.push('/');
        } catch (err) {
            SweetAlert({title:'Error!', text: err, icon: 'error'});
            console.log(err);
        }
    }

    const cleanTestigo = () =>{
        setTestigo({
            nombre: '', 
            sexo: '',
            ine: '',
            domicilio: '',
            ciudad: '',
            estado: '',
            declaracion: ''
        });
    }

    const cleanNotificacion = () => {
        setNotificacion({
            nombre: '',
            puesto: '',
            email: '',
            notas: ''
        })
    }

    const addTestigo = () =>{
        if(testigo.nombre === '') {
            setErr({...err, testigo: {nombre: 'req'}}); return }
        if(testigo.sexo === '') {
            setErr({...err, testigo: {sexo: 'req'}}); return }
        if(testigo.ine === '') {
            setErr({...err, testigo: {ine: 'req'}}); return }
        if(testigo.domicilio === '') {
            setErr({...err, testigo: {domicilio: 'req'}}); return }
        if(testigo.ciudad === '') {
            setErr({...err, testigo: {ciudad: 'req'}}); return }
        if(testigo.estado === '') {
            setErr({...err, testigo: {estado: 'req'}}); return }

        setErr({});
        
        setTestigos([...testigos, testigo]);
        cleanTestigo();
        toggleTestigo();
    }

    const addDeclaracion = () =>{
        if (testigo.nombre !== ''){
            const otrosTestigos = testigos.filter(t => t.nombre !== testigo.nombre);
            setTestigos([...otrosTestigos, testigo]);
            cleanTestigo();
        }
    }

    const addNotificacion = () =>{
        if(notificacion.nombre === '') {
            setErr({...err, notificacion: {nombre: 'req'}}); return }
        if(notificacion.email === '' || !validate(notificacion.email)) {
            setErr({...err, notificacion: {email: 'req'}}); return }

        setErr({});
        
        setCierreNotificaciones([...cierreNotificaciones, notificacion]);
        cleanNotificacion();
        toggleNotificacion();
    }

    const addParticipante = useCallback(() =>{
        const { participantes } = getValues();
        if (!participantes) return;
        if (!participantes.nombre) return;
        if (!participantes.personalidad) return;

        const cleanInputData = {
            nombre: '',
            personalidad: '',
        };

        setValue('participantes', cleanInputData, { shouldDirty: true });
        setParticipantes((state) => [...state, participantes]);
    }, [getValues, setValue]);

    const downloadPdf = async (e) => {
        try {
            e.preventDefault();
            if(schemaActaAdministrativa.isValidSync(getValues())){
                const file = await axios.post(`${api}/actas/crear_pdf`, {...getValues(), testigos, colaborador}, headers)
            
                let byteString = atob(file.data);
            
                let ab = new ArrayBuffer(byteString.length);
                let ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                let blob = new Blob([ia], { type: 'application/pdf' });
                saveAs(blob, `Acta-${moment().format('YYYYMMDD')}`)
            } else {
                SweetAlert({title: 'Algunos campos están vacíos', icon: 'warning', 
                    text: "Asegúrese de llenar los campos marcados con *"})
            }
        } catch (error) {
            SweetAlert({title: 'Error!', icon: 'error', text: error})
        }
    }

    return (
        <div>
            <div>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Agregar participante</ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Nombre*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    name='participantes.nombre'
                                    type='text'
                                    innerRef={register()}
                                    placeholder='Nombre completo'
                                />
                                {err.participantes && err.participantes.nombre && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El nombre es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Personalidad*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    name='participantes.personalidad'
                                    type='text'
                                    innerRef={register()}
                                    placeholder='Personalidad o puesto'
                                />
                                {err.participantes && err.participantes.personalidad && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    La personalidad es requerida
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <SaveButton onClick={() => {addParticipante();toggle()}}>Agregar</SaveButton>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalTestigo} toggle={toggleTestigo}>
                    <ModalHeader toggle={toggleTestigo}>Agregar Testigo</ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Nombre*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='text'
                                    placeholder='Nombre completo'
                                    onChange={e => setTestigo({...testigo, nombre: e.target.value})}
                                    value={testigo.nombre}
                                    disabled={isFormEdit}
                                />
                                {err.testigo && err.testigo.nombre && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El nombre es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Sexo*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='select'
                                    disabled={isFormEdit}
                                    value={testigo.sexo}
                                    onChange={ e => setTestigo({...testigo, sexo: e.target.value})}
                                >
                                    <option value=''>Seleccione opción</option>
                                    <option value='Masculino'>Masculino</option>
                                    <option value='Femenino'>Femenino</option>
                                </Input>
                                {err.testigo && err.testigo.sexo && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El sexo es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>INE*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='text'
                                    placeholder='Número de INE'
                                    onChange={e => setTestigo({...testigo, ine: e.target.value})}
                                    value={testigo.ine}
                                    disabled={isFormEdit}
                                />
                                {err.testigo && err.testigo.ine && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El número de INE es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Domicilio*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='text'
                                    placeholder='Domicilio'
                                    onChange={e => setTestigo({...testigo, domicilio: e.target.value})}
                                    value={testigo.domicilio}
                                    disabled={isFormEdit}
                                />
                                {err.testigo && err.testigo.domicilio && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El domicilio es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Ciudad*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='text'
                                    placeholder='Ciudad'
                                    onChange={e => setTestigo({...testigo, ciudad: e.target.value})}
                                    value={testigo.ciudad}
                                    disabled={isFormEdit}
                                />
                                {err.testigo && err.testigo.ciudad && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    La ciudad es requerida
                                    </span>
                                )}
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Estado*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <CustomSelectEstados
                                    type='select'
                                    name='testigo.estado'
                                    control={control}
                                    disabled={isFormEdit}
                                    onChange={e => setTestigo({...testigo, estado: e.target.value})}
                                    defaultValue={testigo && testigo.estado ? testigo.estado : ''}
                                />
                                {err.testigo && err.testigo.estado && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El estado es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <SaveButton onClick={addTestigo}>Agregar</SaveButton>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalNotificacion} toggle={toggleNotificacion}>
                    <ModalHeader toggle={toggleNotificacion}>Notificación a tercero</ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Nombre*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='text'
                                    placeholder='Nombre completo'
                                    onChange={e => setNotificacion({...notificacion, nombre: e.target.value})}
                                    value={notificacion.nombre}
                                    disabled={isFormEdit}
                                />
                                {err.notificacion && err.notificacion.nombre && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El nombre es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Email*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='email'
                                    placeholder='Correo electrónico'
                                    onChange={e => setNotificacion({...notificacion, email: e.target.value})}
                                    value={notificacion.email}
                                    disabled={isFormEdit}
                                />
                                {err.notificacion && err.notificacion.email && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    Ingrese un correo electrónico
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ marginBottom: '12px' }}>
                                <LabelForms>Puesto</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 7 }}>
                                <Input
                                    type='text'
                                    placeholder='Puesto (en caso de ser colaborador)'
                                    onChange={e => setNotificacion({...notificacion, puesto: e.target.value})}
                                    value={notificacion.ine}
                                    disabled={isFormEdit}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={12} style={{ marginBottom: '12px' }}>
                                <LabelForms>Notas</LabelForms>
                            </Label>
                            <Col sm={12}>
                            <Input
                                type='textarea'
                                name='notas'
                                placeholder='Las notas serán enviadas por correo al interesado'
                                disabled={isFormEdit}
                                onChange={e => setNotificacion({...notificacion, notas: e.target.value})}
                                value={notificacion.notas}
                            />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <SaveButton onClick={addNotificacion}>Agregar</SaveButton>
                    </ModalFooter>
                </Modal>
            </div>
            <Form id='form' onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
                {isEdit ?
                <FormGroup row>
                    <Col sm={1}>
                        <Arrows
                            onClick={() => {
                                cancel();
                                history.push('/');
                            }}
                        />
                    </Col>
                    {
                        !disabled && <>
                            <Col sm={1} hidden={permisos.colaboradorActas !== 'write'}>
                                <Icons onClick={elminarActa} />
                            </Col>
                            <Col sm={1} hidden={permisos.colaboradorActas !== 'write'}>
                                <Icons
                                    pencil
                                    onClick={() => {
                                        setFormEdit(!isFormEdit);
                                    }}
                                />
                            </Col>
                        </>
                    }
                  
                </FormGroup>
                :''}

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >{isEdit ? 'Editar' : 'Nueva'} Acta Administrativa</SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Tipo de Acta*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='tipoActa'
                            type='text'
                            ref={register}
                            placeholder='Ingrese el tipo de acta'
                            defaultValue={dto.tipoActa}
                            disabled={isFormEdit}
                        />
                        {err.tipoActa && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El tipo de acta es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Número de Acta*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <CustomSelect
                            name='numeroActa'
                            type='select'
                            innerRef={register}
                            control={control}
                            options={[
                                {value: 1, label: '1'},
                                {value: 2, label: '2'},
                                {value: 3, label: '3'}]}
                            defaultValue={dto.numeroActa}
                            disabled={isFormEdit}
                        />
                    {err.numeroActa && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El número de acta es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Fecha de Levantamiento*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <Controller
                            name='fechaLevantamiento'
                            control={control}
                            innerRef={register()}
                            render={({ onChange, value }) => (
                                <DatePicker
                                    selected={value ? value : dto.fechaLevantamiento ? new Date(dto.fechaLevantamiento) : new Date()}
                                    onChange={onChange}
                                    dateFormat={'dd/MM/yyyy'}
                                    disabled={isFormEdit}
                                />
                            )}
                        />
                        {err.fechaLevantamiento && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Debe indicar la fecha de levantamiento
                            </span>
                        )}
                    </Col>
                    <Col sm={3}>
                        <Input
                            type="time"
                            name="horaLevantamiento"
                            defaultValue={dto.horaLevantamiento ? dto.horaLevantamiento : moment().format('kk:mm')}
                            innerRef={register()}
                            readOnly={isFormEdit}
                        />
                        {err.horaLevantamiento && err.horaLevantamiento && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Debe indicar la hora
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Reglamento Interior de Trabajo*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <Label sm={2} check style={{ marginLeft: '20px' }}>
                            <CustomInput type='radio' name='rit' 
                            id='ritt'
                            defaultChecked={dto.rit === true ? true : false} 
                            value={true} 
                            innerRef={register} 
                            disabled={isFormEdit} />Sí
                        </Label>
                        <Label sm={2} check style={{ marginLeft: '40px' }}>
                            <CustomInput type='radio' name='rit' 
                            id='ritf'
                            defaultChecked={dto.rit === false ? true : false} 
                            value={false} 
                            innerRef={register} 
                            disabled={isFormEdit} />No
                        </Label>
                        {err.rit && (
                            <>
                                <br />
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique si cuenta con Reglamento Interior de Trabajo
                                </span>
                            </>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >Datos para la elaboración</SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Lugar de Elaboración*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='lugarElaboracion'
                            type='text'
                            ref={register}
                            placeholder='Lugar donde se elabora el acta'
                            defaultValue={dto.lugarElaboracion}
                            disabled={isFormEdit}
                        />
                        {err.lugarElaboracion && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El lugar de elaboración es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Calle*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='domicilioActa.calle'
                            type='text'
                            ref={register}
                            placeholder='Domicilio donde se elabora el acta'
                            defaultValue={dto.domicilioActa && dto.domicilioActa.calle ? dto.domicilioActa.calle : '' }
                            disabled={isFormEdit}
                        />
                        {err.domicilioActa && err.domicilioActa.calle && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Complete el domicilio de elaboración
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Número Exterior*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='domicilioActa.numeroExterior'
                            type='text'
                            ref={register}
                            placeholder='Número exterior'
                            defaultValue={dto.domicilioActa && dto.domicilioActa.numeroExterior ? dto.domicilioActa.numeroExterior : '' }
                            disabled={isFormEdit}
                        />
                        {err.domicilioActa && err.domicilioActa.numeroExterior && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Complete el domicilio de elaboración
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Número Interior</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='domicilioActa.numeroInterior'
                            type='text'
                            ref={register}
                            placeholder='Número interior'
                            defaultValue={dto.domicilioActa && dto.domicilioActa.numeroInterior ? dto.domicilioActa.numeroInterior : '' }
                            disabled={isFormEdit}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Colonia*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='domicilioActa.colonia'
                            type='text'
                            ref={register}
                            placeholder='Colonia'
                            defaultValue={dto.domicilioActa && dto.domicilioActa.colonia ? dto.domicilioActa.colonia : '' }
                            disabled={isFormEdit}
                        />
                        {err.domicilioActa && err.domicilioActa.colonia && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Complete el domicilio de elaboración
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Ciudad*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='domicilioActa.ciudad'
                            type='text'
                            ref={register}
                            placeholder='Ciudad'
                            defaultValue={dto.domicilioActa && dto.domicilioActa.ciudad ? dto.domicilioActa.ciudad : '' }
                            disabled={isFormEdit}
                        />
                        {err.domicilioActa && err.domicilioActa.ciudad && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Complete el domicilio de elaboración
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Estado*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <CustomSelectEstados
                            type='select'
                            name='domicilioActa.estado'
                            id='domicilioActa.estado'
                            defaultValue={dto.domicilioActa && dto.domicilioActa.estado ? dto.domicilioActa.estado : ''}
                            control={control}
                            innerRef={register}
                            disabled={isFormEdit}
                        />
                        {err.domicilioActa && err.domicilioActa.estado && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Complete el domicilio de elaboración
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >Persona que relata los hechos</SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Nombre completo*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='relator'
                            type='text'
                            ref={register}
                            placeholder='Persona que relata los hechos'
                            defaultValue={dto.relator}
                            disabled={isFormEdit}
                        />
                        {err.relator && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El nombre es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Puesto</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='relatorPuesto'
                            type='text'
                            ref={register}
                            placeholder='Puesto (en caso de ser colaborador)'
                            defaultValue={dto.relatorPuesto}
                            disabled={isFormEdit}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>INE*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='relatorIne'
                            type='text'
                            ref={register}
                            placeholder='Número de INE'
                            defaultValue={dto.relatorIne}
                            disabled={isFormEdit}
                        />
                        {err.relatorIne && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El número de INE es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Sexo*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <CustomSelect
                            name='relatorSexo'
                            type='select'
                            innerRef={register}
                            control={control}
                            options={[
                                {value: 'masculino', label: 'Masculino'},
                                {value: 'femenino', label: 'Femenino'}]}
                            defaultValue={dto.relatorSexo}
                            disabled={isFormEdit}
                        />
                    {err.relatorSexo && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Especifique el sexo de la persona
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >
                            Otros participantes involucrados (No testigos)
                        </SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    {participantes && participantes.length > 0 ? (
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Personalidad o Cargo</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {participantes.map(
                                ({ nombre, personalidad }, index) => (
                                <tr key={index}>
                                    <td>{nombre}</td>
                                    <td>{personalidad}</td>
                                    <td>
                                        {!isFormEdit && 
                                            <FontAwesomeIcon
                                            cursor="pointer"
                                            onClick={() => setParticipantes(participantes.filter((p, i) => i !== index))}
                                            icon={faTrash}
                                        />}
                                    </td>
                                </tr>
                                ))}
                        </tbody>
                    </Table>
                    ) : null}
                </FormGroup>
                <FormGroup row hidden={isFormEdit}>
                    <Col sm={3} className='text-center'>
                    <CancelButton type='button' onClick={toggle}>
                        + Agregar
                    </CancelButton>
                    </Col>
                </FormGroup> <br />

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >
                            Hecho violatorio
                        </SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Motivo*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <CustomSelect
                            name='hechoMotivo'
                            type='select'
                            innerRef={register}
                            control={control}
                            options={motivos}
                            defaultValue={dto.hechoMotivo}
                            disabled={isFormEdit}
                            onChange={e => setMotivo(e.target.value)}
                        />
                    {err.hechoMotivo && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El motivo del acta es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                {
                    motivo === 'Otro' ? (
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Otro Motivo*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <InputUtils
                                    name='hechoMotivoDetalle'
                                    type='text'
                                    ref={register}
                                    placeholder='Especifique el motivo'
                                    defaultValue={dto.hechoMotivoDetalle}
                                    disabled={isFormEdit}
                                />
                                {err.hechoMotivoDetalle && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El motivo del acta es requerido
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                    ) : ''
                }

                <FormGroup row>
                    <Label sm={12} style={{ marginBottom: '12px' }}>
                        <LabelForms>Hechos violatorios que se le imputan al trabajador*</LabelForms>
                    </Label>
                    <Col sm={12}>
                    <TextAreaUtils
                        name='hechoViolatorio'
                        ref={register}
                        defaultValue={dto.hechoViolatorio}
                        placeholder='Sugerencia: Para efectos de validez, se recomienda tomar en cuenta las circunstancias de tiempo, modo y lugar.'
                        disabled={isFormEdit}
                    />
                    {err.hechoViolatorio && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El hecho violatorio es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={12} style={{ marginBottom: '12px' }}>
                        <LabelForms>Declaración del trabajador respecto a los hechos que se le atribuyen en forma personal, libre y espontánea*</LabelForms>
                    </Label>
                    <Col sm={12}>
                    <TextAreaUtils
                        name='hechoDeclaracion'
                        ref={register}
                        defaultValue={dto.hechoDeclaracion}
                        placeholder='Derecho a su defensa (Se le concede el uso de la voz al trabajador para que manifieste con su léxico, lo que a su derecho corresponda)'
                        disabled={isFormEdit}
                    />
                    {err.hechoDeclaracion && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            La declaración del trabajador es requerida.
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >
                            Información de testigos
                        </SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    {testigos && testigos.length > 0 ? (
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>INE</th>
                            <th>Domicilio</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {testigos.map(
                                ({ nombre, ine, domicilio, ciudad, estado }, index) => (
                                <tr key={index}>
                                    <td>{nombre}</td>
                                    <td>{ine}</td>
                                    <td>{`${domicilio}, (${ciudad}, ${estado})`}</td>
                                    <td>
                                        {!isFormEdit && (
                                            <FontAwesomeIcon
                                            cursor="pointer"
                                            onClick={() => {
                                                setTestigos(testigos.filter((p, i) => i !== index))
                                                cleanTestigo()
                                            }}
                                            icon={faTrash}
                                        />
                                        )}
                                    </td>
                                </tr>
                                ))}
                        </tbody>
                    </Table>
                    ) : null}
                </FormGroup>
                <FormGroup row hidden={isFormEdit}>
                    <Col sm={3} className='text-center'>
                    <CancelButton type='button' onClick={toggleTestigo}>
                        + Agregar
                    </CancelButton>
                    </Col>
                </FormGroup><br />

                {testigos && testigos.length > 0 ? (
                    <React.Fragment>
                        <FormGroup row>
                            <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                                <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >
                                    Declaración de testigos
                                </SubTitleMenu>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Seleccione el testigo*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <Input
                                    disabled={disabled}
                                    name='testigo'
                                    type='select'
                                    value={testigo.nombre}
                                    onChange={e => { 
                                        if (e.target.value !== '')
                                        setTestigo(testigos.filter(t => t.nombre === e.target.value)[0])
                                        else
                                        cleanTestigo() 
                                    }}
                                >
                                    <option value=''>Seleccione Opción</option>
                                    {testigos.map(t => (<option value={t.nombre}>{t.nombre}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={12} style={{ marginBottom: '12px' }}>
                                <LabelForms>{`Declaración del testigo ${testigo.nombre}*`}</LabelForms>
                            </Label>
                            <Col sm={12}>
                            <Input
                                name='testigoDeclaración'
                                placeholder='Cuándo, dónde y cómo sucedieron los hechos y/o actos que se le atribuyen al trabajador'
                                type='textarea'
                                disabled={isFormEdit}
                                value={testigo.declaracion}
                                onChange={e => setTestigo({...testigo, declaracion: e.target.value})}
                            />
                            {err.testigo && err.testigo.declaracion && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    La declaración del testigo es requerida.
                                    </span>
                                )}
                            </Col>
                        </FormGroup>

                        <FormGroup row hidden={isFormEdit}>
                            <Col sm={3} className='text-center'>
                            <CancelButton type='button' onClick={addDeclaracion}>
                                + Guardar
                            </CancelButton>
                            </Col>
                        </FormGroup><br />
                    </React.Fragment> 
                ) : null }

                <FormGroup row>
                    <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                        <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >
                            Cierre del acta
                        </SubTitleMenu>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Fecha de Aplicación*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <Controller
                            name='cierreFecha'
                            control={control}
                            innerRef={register()}             
                            render={({ onChange, value }) => (
                                <DatePicker
                                    selected={value ? value : dto.cierreFecha ? new Date(dto.cierreFecha) : new Date()}
                                    onChange={onChange}
                                    dateFormat={'dd/MM/yyyy'}
                                    disabled={isFormEdit}
                                />
                            )}
                        />
                        {err.cierreFecha && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Debe indicar la fecha de aplicación
                            </span>
                        )}
                    </Col>
                    <Col sm={3}>
                        <Input
                            type="time"
                            name="cierreHora"
                            defaultValue={dto.cierreHora ? dto.cierreHora : moment().format('kk:mm')}
                            innerRef={register()}
                            readOnly={isFormEdit}
                        />
                        {err.cierreHora && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Debe indicar la hora
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Causal de rescisión laboral*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <Label sm={2} check style={{ marginLeft: '20px' }}>
                            <CustomInput type='radio' name='cierreRescision' 
                            id='cierreRescesionF'
                            defaultChecked={dto.cierreRescision === true ? true : false} 
                            value={true} 
                            innerRef={register} 
                            disabled={isFormEdit} />Sí
                        </Label>
                        <Label sm={2} check style={{ marginLeft: '40px' }}>
                            <CustomInput type='radio' name='cierreRescision' 
                            id='cierreRescesionT'
                            defaultChecked={dto.cierreRescision === false ? true : false} 
                            value={false} 
                            innerRef={register} 
                            disabled={isFormEdit} />No
                        </Label>
                        {err.cierreRescision && (
                            <>
                                <br />
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique si el hecho violatorio es causal de rescision laboral
                                </span>
                            </>
                        )}
                    </Col>
                </FormGroup>
                {
                    !disabled && <FormGroup row>
                        <Col sm={3} className='mb-3'>
                            <SaveButton onClick={(e) => downloadPdf(e)}>Generar Acta</SaveButton>
                        </Col>
                    </FormGroup>
                }
                <FormGroup row>
                    <Label sm={3} style={{ marginBottom: '12px' }}>
                        <LabelForms>Notificación a terceros</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <Label sm={2} check style={{ marginLeft: '20px' }}>
                            <CustomInput type='radio' name='cierreNotificacion' 
                            id='notificacionT'
                            defaultChecked={dto.cierreNotificacion === true ? true : false} 
                            value={true} 
                            innerRef={register} 
                            onChange={e => setNotificar(true)}
                            disabled={isFormEdit} />Sí
                        </Label>
                        <Label sm={2} check style={{ marginLeft: '40px' }}>
                            <CustomInput type='radio' name='cierreNotificacion' 
                            id='notificacionF'
                            defaultChecked={dto.cierreNotificacion === false ? true : false} 
                            value={false} 
                            innerRef={register} 
                            onChange={e => setNotificar(false)}
                            disabled={isFormEdit} />No
                        </Label>
                        {err.cierreNotificacion && (
                            <>
                                <br />
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique si se debe notificar a terceros de esta acta
                                </span>
                            </>
                        )}
                    </Col>
                </FormGroup>

                {notificar && cierreNotificaciones && cierreNotificaciones.length > 0 ? (
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Puesto</th>
                            <th>Email</th>
                            <th>Enviado</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {cierreNotificaciones.map(
                                ({ nombre, puesto, email, sended }, index) => (
                                <tr key={index}>
                                    <td>{nombre}</td>
                                    <td>{puesto}</td>
                                    <td>{email}</td>
                                    <td>
                                    {sended ? (
                                        <FontAwesomeIcon
                                            color='#626262'
                                            icon={faCheckCircle}
                                            style={{ fontSize: '1.5rem' }}
                                        />
                                        ) : ''}
                                    </td>
                                    <td>
                                        {!isFormEdit &&
                                            <FontAwesomeIcon
                                                cursor="pointer"
                                                onClick={() => {
                                                    setCierreNotificaciones(cierreNotificaciones.filter((p, i) => i !== index))
                                                    cleanNotificacion()
                                                }}
                                                icon={faTrash}
                                            />
                                        }
                                    </td>
                                </tr>
                                ))}
                        </tbody>
                    </Table>
                    ) : null}

                {notificar ? (
                    <FormGroup row hidden={isFormEdit}>
                        <Col sm={3} className='text-center'>
                        <CancelButton type='button' onClick={toggleNotificacion}>
                            + Agregar
                        </CancelButton>
                        </Col>
                    </FormGroup>
                ) : null}

                <br /><br />
                <FormGroup row>
                    <Col sm={{ offset: 6, size: 3 }}>
                        <CancelButton
                            onClick={() => {
                                cancel();
                                history.push('/');
                            }}
                            style={{ marginLeft: 'auto' }}>
                            Cancelar
                        </CancelButton>
                    </Col>
                    <Col sm={3} hidden={permisos.colaboradorActas !== 'write'}>
                    {!isFormEdit && (
                        <SaveButton type='submit'>Guardar</SaveButton>
                    )}
                    </Col>
                </FormGroup>
            </Form>
        </div>
    );
});

export default FormActa;