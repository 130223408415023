import styled from "styled-components";
import colors from "../../../constans/colors";

export const MessageAddUtils = styled.span`
  font-family: "Roboto", sans-serif;
  color: #3c3c3c;
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;
export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 55%;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
`;

export const InputUtils = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

export const TextAreaUtils = styled.textarea`
  display: block;
  width: 100%;
  height: calc(4em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const SubTitleLogotipo = styled.h5`
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #2b388f;
`;
export const SubTitleMenu = styled.h5`
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #3c3c3c;
`;
export const TitleMenu = styled(SubTitleMenu)`
  font-size: 48px;
  line-height: 1;
`;
export const LabelForms = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #3c3c3c;
`;
export const LabelFormsUp = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2b388f;
`;
export const SaveButton = styled.button`
  width: 203px;
  height: 51px;
  border: 2px solid #2b388f;
  background-color: white;
  border-radius: 4px;
  background-color: #2b388f;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
  padding: 1.4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #151e5d;
  }
`;
export const CancelButton = styled.button`
  border: 2px solid #2b388f;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #2b388f;
  background-color: white;
  border-radius: 4px;
  padding: 0.6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #2b388f;
    color: white;
    font-weight: 600;
  }
`;
export const LightButton = styled.button`
  width: 169px;
  border: 2px solid #2b388f;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #2b388f;
  background-color: white;
  border-radius: 4px;
  padding: 1.4vh;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #2b388f;
    color: white;
    font-weight: 600;
  }
  &:focus {
    outline: none;
  }
`;
export const LightMiniButton = styled.button`
  /* width: 169px; */
    border: 2px solid #2b388f;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #2b388f;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease;
    cursor: pointer;
    text-align: center;
    max-height: 30px;
    height: 30px;
    width: 29px;
    padding-top: 0;
    margin-left: 10px;
    &:hover {
        background-color: #2b388f;
        color: white;
        font-weight: 600;
    }
    &:focus {
        outline: none;
    }
`;
export const DarkButton = styled.button`
  align-items: center;
  background-color: white;
  border: 2px solid #2b388f;
  border-radius: 4px;
  background-color: #2b388f;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: "Roboto", sans-serif;
  /* font-size: 22px; */
  justify-content: center;
  letter-spacing: 0.284167px;
  padding: 1.4vh;
  transition: all 300ms ease;
  text-align: center;
  width: 100%;
  &:hover {
    background-color: #151e5d;
    color: white;
    font-weight: 600;
  }
  &:focus {
    outline: none;
  }
`;
export const TableContainer = styled(Container)`
  margin-top: 8vh;
  align-items: inherit;
  justify-content: start;
`;
export const Tr = styled.tr`
  color: ${colors.tashColor};
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
`;

export const BackgroundImage = styled.div`
  box-shadow: #6a6a6a 1px 1px 3px 0px;
  width: 68px;
  height: 68px;
  min-height: 68px;
  border-radius: 100px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 2s;
`;
export const CardEmpresas = styled.div`
  padding: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: ${(props) => (props.text ? props.text : "center")};
  border: 3px solid #ebecf4;
  border-radius: 8px;
  h2 {
    font-size: 30px;
    text-overflow: ellipsis;
    width: 340px;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const GridContainer = styled.section`
  display: grid;

  gap: 0 2em;
`;
