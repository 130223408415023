import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import DescuentosInfonavitView from './DescuentosViews/DescuentosInfonavitView';
import FormDescuentoInfonavit from './DescuentosViews/FormDescuentoInfonavit';

const DescuentosInfonativt = ({disabled = false}) => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/infonavit/agregar' component={(props) => <FormDescuentoInfonavit disabled={disabled} {...props} />} />
                <Route path='/' component={(props) => <DescuentosInfonavitView disabled={disabled} {...props} />} /> 
            </Switch>
        </HashRouter>
    );
};

export default DescuentosInfonativt;