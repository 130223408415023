import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import colors from '../../../constans/colors';
import { MessageAddUtils, Container } from './styled';
const FirstAddUtil = React.memo(() => {
    const history= useHistory()
    const { permisos = {} } = useSelector((state) => state.user);

    console.log(permisos);

  return (
    <Container>
      <MessageAddUtils>Agrega aquí las herramientas de trabajo asignadas al colaborador</MessageAddUtils>
      <Button 
        onClick={()=>{
          history.push('/agregar-utilitario')
        }} 
        style={{background:colors.secondary}}
        hidden={permisos.colaboradorUtilitario !== "write"}>+ Agregar</Button>
    </Container>
  );
});

export default FirstAddUtil;
