import { faCalendarAlt, faCheckDouble, faGavel, faMoneyBillWave, faUserFriends, faNotesMedical, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import colors from "../../constans/colors";
import routes from "../../routes/recursos-humanos-routes";
import RecursosHumanosRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/RecursosHumanosRoutes";
import { LettersContainer } from "../styled";

const RecursosHumanos = ({ match, location }) => {
  const {permisos = {}} = useSelector(store => store.user);

  const [colaboradores, calendario, incidencias] = routes(permisos);
  const [actualPage, setActualpage] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (location && location.pathname) {
      const pageIndex = location.pathname.split("/").length;
      setActualpage(location.pathname.split("/")[pageIndex - 1]);
    }
  }, [location]);


  return (
    <section className="grid-container">
      <div style={{ backgroundColor: colors.primary }} className="sub-menu-container">
        <LettersContainer />
        {
          permisos.colaboradores !== 'none' ?
            <SubSideBar
              icon={faUserFriends}
              title="Colaboradores"
              routes={[colaboradores]}
              match={match}
              className={`${actualPage === "proceso" || actualPage === "altas" || actualPage === "bajas" || actualPage === "lista-colaboradores" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.incidencias !== 'none' ?
            <SubSideBar
              icon={faCheckDouble}
              title="Incidencias"
              routes={[incidencias]}
              match={match}
              className={`${actualPage === "horas-extra" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.calendario !== 'none' ?
            <Button
              className={`sub-menu-btn ${actualPage === "calendario" ? "active" : ""}`}
              onClick={(e) => history.push("/dashboard/recursos-humanos/calendario")}
              style={{
                marginBottom: "1rem",
                fontSize: "small",
                backgroundColor: colors.buttonColorSecondary,
              }}
            >
              <FontAwesomeIcon color={colors.secondary} icon={faCalendarAlt} style={{ fontSize: "1.2rem", marginRight: "0.5rem", color: "inherit" }} />
              <span className="mt-5 mb-5 ml-3">Calendario</span>
            </Button> : null
        }
        {
          permisos.verificaciones !== 'none' ?
            <Button
              className={`sub-menu-btn ${actualPage === "verificaciones" ? "active" : ""}`}
              onClick={(e) => history.push("/dashboard/recursos-humanos/verificaciones")}
              style={{
                marginBottom: "1rem",
                fontSize: "small",
                backgroundColor: colors.buttonColorSecondary,
              }}
            >
              <FontAwesomeIcon color={colors.secondary} icon={faSearch} style={{ fontSize: "1.2rem", marginRight: "0.5rem", color: "inherit" }} />
              <span className="mt-5 mb-5 ml-3">Verificaciones</span>
            </Button> : null
        }

      </div>
      <div className="frame-content">
        <RecursosHumanosRoutes path={match.path} />
      </div>
    </section>
  );
};

export default RecursosHumanos;
