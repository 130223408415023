import React from "react";
import { Route, useHistory } from "react-router-dom";

import HashRouteNomina from "../Nomina/HashRouteNomina";
import MenuEmpresas from "../../../pages/MenuNomina/MenuEmpresas"
import ConciliacionSat from '../../../pages/ConciliacionSat/ConciliacionSat';
import TimbrarEmpresa from "../../../components/nomina/Timbrar";
import HorasExtra from "../../../pages/HorasExtra/HorasExtra";
import Faltas from "../../../pages/Faltas/Faltas";
import Ajustes from "../../../pages/Ajustes/Ajustes";
import Nomina from "../../../components/nomina/Nomina";
import PrenominaEmpresa from "../../../components/nomina/TimbrarEmpresa";
import MenuExtraordinaria from "../../../pages/MenuExtraordinaria/MenuExtraordinaria";
import ExtraordinariaPtu from "../../../pages/Extraordinaria/ExtraordinariaPtu";
import ExtraordinariaFiniquito from "../../../pages/Extraordinaria/ExtraordinariaFiniquito";
import ExtraordinariaAguinaldo from "../../../pages/Extraordinaria/ExtraordinariaAguinaldo";
import idse from "../../../components/imss/Idse";
import conciliacion from "../../../components/imss/Conciliacion";
import variabilidad from "../../../components/imss/Variabilidad";

const NominaRoutes = ({ path }) => {

  const history = useHistory()

  return (
    <>
      {/* <Route path={`${path}/configuracion-nomina`} component={HashRouteNomina} /> */}
      <Route path={`${path}/conciliacion-sat`} component={ConciliacionSat} />
      <Route path={`${path}/timbrar`} component={() => <MenuEmpresas title="Timbrado" />} />
      <Route path={`${path}/timbrar-empresa/:idEmpresa`} component={TimbrarEmpresa} />
      <Route path={`${path}/horas-extra`} component={HorasExtra} />
      <Route path={`${path}/faltas-masivo`} component={Faltas} />
      <Route path={`${path}/ajustes-masivo`} component={Ajustes} />
      <Route path={`${path}/nomina/:idEmpresa`} component={Nomina} />
      <Route path={`${path}/prenomina/:idEmpresa/:status?`} component={PrenominaEmpresa} />
      <Route path={`${path}/configuracion-nomina`} component={() => <MenuEmpresas title="Nómina" />} />
      <Route path={`${path}/extraordinaria`} component={() => <MenuExtraordinaria title="Extraordinaria" />} />
      <Route path={`${path}/configuracion-extraordinaria/:idEmpresa/ptu`} component={() => <ExtraordinariaPtu title="Extraordinaria PTU" />} />
      <Route path={`${path}/configuracion-extraordinaria/:idEmpresa/finiquitos`} component={() => <ExtraordinariaFiniquito title="Extraordinaria Finiquitos" />} />
      <Route path={`${path}/configuracion-extraordinaria/:idEmpresa/aguinaldo`} component={() => <ExtraordinariaAguinaldo title="Extraordinaria Aguinaldos" />} />
      <Route path={`${path}/imss-idse`} component={idse} />
      <Route path={`${path}/imss-conciliacion`} component={conciliacion} />
      <Route path={`${path}/imss-variabilidad`} component={variabilidad} />
    </>
  );
};

export default NominaRoutes;
