import React,{ Fragment,useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Form,
	Input,
	Label,
	Col,
	CustomInput
} from "reactstrap";
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';
import { SaveButton,CancelButton } from '../ui/styles/styles';
import InputFile from "../ui/InputFile";
import CustomSelect from '../ui/CustomSelect';
import {agregarExtension} from '../../store/citatorioDuck';
import SweetAlert from "../ui/SweetAlet";
import DatePicker from "react-datepicker";

const numerocitatorio = [
	{ label: 'Primer Citatorio', value: 1 },
	{ label: 'Segundo Citatorio', value: 2 },
	{ label: 'Tercer Citatorio', value: 3 },
	{ label: 'Cuarto Citatorio', value: 4},
	{ label: 'Quinto Citatorio', value: 5},
	{ label: 'Sexto Citatorio', value: 6}
  ];

const CitatorioModal = ({ isOpen, setIsOpen }) => {
	const dispatch = useDispatch();
	const { register, handleSubmit, control } = useForm({});
	const [notEmail, setNotEmail] = useState(false);
	
	const onSubmit = async (objeto) => {
		try {
      objeto.fecha = new Date(objeto.fecha);
			
			dispatch(agregarExtension(objeto));
			await SweetAlert({ icon: 'success', title: 'Citatorio agregado correctamente' });
		  	setIsOpen(false)
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Agregar Extensión de Citatorio
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormGroup row>
							<Label sm={3}>Fecha</Label>
							<Col sm={4}>
								<Controller
									name='fecha'
									control={control}
									innerRef={register()}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											dateFormat={'dd/MM/yyyy'}
										/>
									)}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Hora</Label>
							<Col sm={4}>
								<Input 
									type='time'
									name='hora'
									innerRef={register()}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>No. Citatorio</Label>
							<Col sm={9}>
								<CustomSelect
									control={control}
									options={numerocitatorio}
									type='select'
									name='numeroCitatorio'
									innerRef={register()}
									placeholder='Seleccion el numero de citatorio'
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Persona que atiende</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='responsable'
									innerRef={register()}
									placeholder='Persona que atiende'
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Notificar</Label>
							<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
							<CustomInput
								id={'notificar'}
								innerRef={register}
								type="radio"
								label="SI"
								value={true}
								name="notificar"
								onClick={() => setNotEmail(true)}
							/>
							</Col>
							<Col sm={1}>
							<CustomInput
								id={'notificar2'}
								innerRef={register}
								type="radio"
								label="NO"
								value={false}
								name="notificar"
								onClick={() => setNotEmail(false)}
							/>
							</Col>
						</FormGroup>
						{notEmail ?
						<FormGroup row>
							<Label sm={3}>Email</Label>
							<Col sm={9}> 
								<Input 
								type='text'
								name='email'
								innerRef={register}
								placeholder='Email'
								/>
							</Col>
						</FormGroup>:null}
						<FormGroup row>
							<Label sm={3}>Notas</Label>
							<Col sm={9}>
								<TextAreaUtils
									name='notas'
									ref={register}
									placeholder='Ingrese notas, de ser necesario'
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Documento</Label>
							<Col sm={9}>
								<InputFile
									type='file'
									id='documento'
									name='documento'
									register={register}
								/>
							</Col>
						</FormGroup>
					</Form>
					<FormGroup row>
						<Col sm={{ offset: 4, size: 4 }}>
							<CancelButton
								onClick={() => setIsOpen(false)}
								style={{ marginLeft: 'auto' }}>
								Cancelar
							</CancelButton>
						</Col>
						<Col sm={3}>
							<SaveButton type='button' onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
						</Col>
					</FormGroup>
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default CitatorioModal;