const initState = {
  loading: false,
  empresas: [],
  error: null,
  rutaEmpresa: {
    empresa: true,
  },
  editar: false,
  msg: null,
  empresasForFiniquitos: [],
  reportes: {},
  delegacionIMSS: '',
  subdelegacionIMSS: '',
  empresa:{},
  registrosPatronales:[]
};

export const GET_EMPRESAS = "GET_EMPRESAS";
export const GET_EMPRESA_SUCESS = "GET_EMPRESAS_SUCESS";
export const GET_EMPRESA_LIST_SUCESS = "GET_EMPRESA_LIST_SUCESS";
export const GET_EMPRESA_ERROR = "GET_EMPRESAS_ERROR";
export const AGREGAR_EMPRESA_SUCESS = "AGREGAR_EMPRESA_SUCESS";
export const AGREGAR_EMPRESA_ERROR = "AGREGAR_EMPRESA_ERROR";
export const GET_REGISTROS_LIST_SUCESS = "GET_REGISTROS_LIST_SUCESS";

export const ELIMINAR_EMPRESA = "ELIMINAR_EMPRESA";
export const ELIMINAR_EMPRESA_ERROR = "ELIMINAR_EMPRESA_ERROR   ";
export const ELIMINAR_EMPRESA_EXITO = "ELIMINAR_EMPRESA_EXITO";

export const DETALLE_EMPRESA = "DETALLE_EMPRESA";
export const DETALLE_EMPRESA_SUCESS = "DETALLE_EMPRESA_SUCESS";
export const DETALLE_EMPRESA_ERROR = "DETALLE_EMPRESA_ERROR";

export const NUMERO_COLABORADORS_EXITO = "NUMERO_COLABORADORS_EXITO";

export const EDITAR_EMPRESA = "EDITAR_EMPRESA";
export const EDITAR_EMPRESA_EXITO = "EDITAR_EMPRESA_EXITO";
export const EDITAR_EMPRESA_ERROR = "EDITAR_EMPRESA_ERROR";

export const CATALOGO = "CATALOGO";
export const CATALOGO_ERROR = "CATALOGO_ERROR";
export const CATALOGO_SAT_EXITO = "CATALOGO_SAT_EXITO";
export const CATALOGO_EXITO = "CATALOGO_EXITO";

export const REPORTES_EXITO = "REPORTES_EXITO";
export const REPORTES_ERROR = "REPORTES_ERROR";
export const CLEAN_REPORTES = "CLEAN_REPORTES";

export const RUTA_ACTIVA = "RUTA_ACTIVA";
export const EDITAR_BTN = "EDITAR_BTN";
export const CLEAN_STATE_EMPRESAS = "CLEAN_STATE_EMPRESAS";

export const LISTA_EMPRESAS_FINIQUITOS = "LISTA_EMPRESAS_FINIQUITOS";

export default function empresaReducer(state = initState, { type, payload }) {
  switch (type) {
    case GET_EMPRESAS:
      return { ...state, loading: true };
    case GET_EMPRESA_SUCESS:
      return { ...state, loading: false, empresas: payload.empresas };
    case GET_EMPRESA_ERROR:
      return { ...state, loading: false };
    case GET_EMPRESA_LIST_SUCESS:
      return { ...state, loading: false, empresas: [...payload] };
    case AGREGAR_EMPRESA_SUCESS:
      return { ...state, loading: false, ...payload };
    case LISTA_EMPRESAS_FINIQUITOS:
      return { ...state, loading: false, empresasForFiniquitos: [...payload] };
    case AGREGAR_EMPRESA_ERROR:
      return { ...state, loading: false, error: payload, editar: false };
    case DETALLE_EMPRESA:
      return { ...state, loading: true, empresa: {}, error: null };
    case DETALLE_EMPRESA_SUCESS:
      return { ...state, loading: false, empresa: payload, error: null };

    case EDITAR_EMPRESA:
      return { ...state, loading: true };
    case EDITAR_EMPRESA_EXITO:
      return { ...state, loading: false };
    case EDITAR_EMPRESA_ERROR:
      return { ...state, loading: false };
    case EDITAR_BTN:
      return { ...state, editar: !state.editar };

    case REPORTES_EXITO:
      return { ...state, loading: false, reportes: payload };
    case CLEAN_REPORTES:
      return { ...state, reportes: {} };
    case CATALOGO:
      return { ...state, loading: true };
    case CATALOGO_SAT_EXITO:
      return { ...state, catalogosSat: payload, loading: false };
    case CATALOGO_EXITO:
      return { ...state, catalogosEmpresa: payload, loading: false };
    case CATALOGO_ERROR:
      return { ...state, error: true, loading: false };
    case NUMERO_COLABORADORS_EXITO:
      return { ...state, numeroColaboradores: payload, loading: false };
    case ELIMINAR_EMPRESA:
      return { ...state, loading: true };
    case ELIMINAR_EMPRESA_EXITO:
      return { ...state, loading: false };
    case ELIMINAR_EMPRESA_ERROR:
      return { ...state, loading: false };
    case RUTA_ACTIVA:
      return { ...state, rutaEmpresa: payload };
    case CLEAN_STATE_EMPRESAS:
      return initState;
    case GET_REGISTROS_LIST_SUCESS:
      return { ...state, loading: false, registrosPatronales: [...payload] };
    default:
      return state;
  }
}

export const getStart = () => ({
  type: GET_EMPRESAS,
});
export const getEmpresasListSucess = (payload) => ({
  type: GET_EMPRESA_LIST_SUCESS,
  payload,
});

export const getRegistrosListSucess = (payload) => ({
  type: GET_REGISTROS_LIST_SUCESS,
  payload,
});

export const getEmpresasSucess = (payload) => ({
  type: GET_EMPRESA_SUCESS,
  payload,
});
export const getEmpresasError = (payload) => ({
  type: GET_EMPRESA_ERROR,
  payload,
});

export const agregarEmpresaSucess = (payload) => ({
  type: AGREGAR_EMPRESA_SUCESS,
  payload,
});

export const agregarEmpresaError = (payload) => ({
  type: AGREGAR_EMPRESA_ERROR,
  payload,
});
export const getDetalleSuccess = (payload) => ({
  type: DETALLE_EMPRESA_SUCESS,
  payload,
});
export const getDetalleStart = () => ({
  type: DETALLE_EMPRESA,
});
export const detalleStart = () => ({
  type: EDITAR_EMPRESA,
});
export const editarEmpresa = () => ({
  type: EDITAR_EMPRESA,
});
export const editarEmpresaExito = (payload) => ({
  type: EDITAR_EMPRESA_EXITO,
  payload,
});
export const editarEmpresaError = (payload) => ({
  type: EDITAR_EMPRESA_ERROR,
  payload,
});

export const eliminarEmpresa = () => ({
  type: ELIMINAR_EMPRESA,
});

export const eliminarEmpresaError = (payload) => ({
  type: ELIMINAR_EMPRESA_ERROR,
  payload,
});
const eliminarEmpresaExito = (payload) => ({
  type: ELIMINAR_EMPRESA_EXITO,
  payload,
});

export const setNumeroColaboradores = (payload) => ({
  type: NUMERO_COLABORADORS_EXITO,
  payload,
});

//reportes actions
export const setReportes = (payload) => ({
  type: REPORTES_EXITO,
  payload,
});
export const setReportesError = () => ({
  type: REPORTES_ERROR,
});

export const cleanReportes = () => ({
  type: CLEAN_REPORTES,
});

//catalogos actions
export const startCatalogos = () => ({
  type: CATALOGO,
});

export const getListaEmpresasForFiniquitos = (payload) => ({
  type: LISTA_EMPRESAS_FINIQUITOS,
  payload,
});

export const getCatalogosSat = (payload) => ({
  type: CATALOGO_SAT_EXITO,
  payload,
});

export const getCatalogos = (payload) => ({
  type: CATALOGO_EXITO,
  payload,
});

export const createCatalogo = () => ({
  type: "Create_succees",
});

export const setErrorCatalogos = () => ({
  type: CATALOGO_ERROR,
});

export const editarEmpresaBtn = () => ({
  type: EDITAR_BTN,
});

export const selecRuta = (payload) => ({
  type: RUTA_ACTIVA,
  payload,
});

export const cleanStateEmpresas = () => ({
  type: CLEAN_STATE_EMPRESAS,
});

export const fetchEmpresas = () => async (dispatch, getState, { axios }) => {
  dispatch(getStart());
  try {
    const { empresas } = (
      await axios.get(`/empresa/obtener_empresas`, {
        headers: { token: localStorage.getItem("pv2token") },
      })
    ).data;
    dispatch(cleanStateEmpresas());
    dispatch(getEmpresasSucess({ empresas }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getEmpresasError(data));
    throw data;
  }
};

export const editarEmpresaAction = ({
  calle,
  ciudad,
  colonia,
  razon_social,
  tipo_persona,
  nombre_empresa,
  estado,
  rfc,
  numero,
}) => async (dispatch, getState, { axios }) => {
  dispatch(editarEmpresa());
  const { _id } = getState().empresas;
  try {
    const { msg } = await axios.put(
      `/empresa/editar_empresa/${_id}`,
      {
        calle,
        ciudad,
        colonia,
        razon_social,
        tipo_persona,
        nombre_empresa,
        estado,
        rfc,
        numero,
      },
      { headers: { token: localStorage.getItem("pv2token") } }
    );
    dispatch(editarEmpresaExito({ msg }));
  } catch (e) {
    dispatch(editarEmpresaError(e));
    const { data } = e.response;
    throw data;
  }
};

export const fetchEditarEmpresa = (empresa) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { _id } = getState().empresas;
    const { logotipo = {}, documentos = {}, ...dataEmpresa } = empresa;
    const formData = new FormData();
    formData.append("logotipo", logotipo[0]);
    formData.append("certificado", empresa.informacionLegal.certificado[0]);
    formData.append("empresa", JSON.stringify({ ...dataEmpresa }));
    const {
      data: { resultado },
    } = await axios.put(`/empresa/editar_empresa/${_id}`, formData, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(agregarEmpresaSucess(resultado));
  } catch (error) {
    console.log(error);
    const { data } = error.response;
    dispatch(agregarEmpresaError(data));
    throw data;
  }
};

export const eliminarEmpresaAction = ({ _id, password }) => async (
  dispatch,
  getState,
  { axios }
) => {
  const user_id = getState().user._id;
  dispatch(eliminarEmpresa());
  try {
    const { msg } = await axios.put(
      `/empresa/eliminar_empresa`,
      { idEmpresa: _id, password: password, user_id: user_id },
      { headers: { token: localStorage.getItem("pv2token") } }
    );
    dispatch(eliminarEmpresaExito({ msg }));
  } catch (error) {
    dispatch(eliminarEmpresaError(error));
    const { data } = error.response;
    throw data;
  }
};

export const getDetalle = (empresa) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { empresas } = getState().empresas;
  dispatch(getDetalleStart());
  let empresaFilter = empresas.find((elemento) => elemento._id === empresa);
  dispatch(getDetalleSuccess(empresaFilter));
};
export const agregarEmpresaAction = (empresa) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { logotipo = {}, documentos = {}, ...dataEmpresa } = empresa;
    const formData = new FormData();
    formData.append("logotipo", logotipo[0]);
    formData.append("contratoColectivo", documentos.contratoColectivo[0]);
    formData.append("certificado", empresa.informacionLegal.certificado[0]);
    formData.append(
      "reglamentoInterioTrabajo",
      documentos.reglamentoInterioTrabajo[0]
    );
    formData.append(
      "registroFederalContribuyentes",
      documentos.registroFederalContribuyentes[0]
    );
    formData.append("comprobanteDomicilio", documentos.comprobanteDomicilio[0]);

    formData.append("empresa", JSON.stringify({ ...dataEmpresa }));
    const { data } = await axios.post("/empresa/crear_empresa", formData, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(data);
    dispatch(agregarEmpresaSucess({ test: "test" }));
  } catch (error) {
    console.log(error);
    const { data } = error.response;
    dispatch(agregarEmpresaError(data));
    throw data;
  }
};

export const fetchEmpresasToDropdown = () => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(getStart());
  try {
    const {
      data: { empresas },
    } = await axios.get(`/empresa/obtener_empresas`, {
      headers: { token: localStorage.getItem("pv2token") },
    });

    const list = empresas.map(({ _id, nombre_empresa }) => {
      return { value: _id, label: nombre_empresa };
    });
    dispatch(getEmpresasListSucess(list));
    dispatch(getListaEmpresasForFiniquitos(empresas));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getEmpresasError(data));
    throw data;
  }
};

export const fetchCatalogos = ({
  tipoCatalogo = "percepciones",
  sat = true,
}) => async (dispatch, getState, { axios }) => {
  try {
    dispatch(startCatalogos());
    const { _id } = getState().empresas;
    const request = sat
      ? `/catalogos/?catalogo=${tipoCatalogo}&sat=${sat}`
      : `/catalogos/?catalogo=${tipoCatalogo}&empresaId=${_id}`;
    const {
      data: { response = [] },
    } = await axios.get(request, {
      headers: { token: localStorage.getItem("pv2token"), caller: 'empresasDuck' },
    });
    dispatch(sat ? getCatalogosSat(response) : getCatalogos(response));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(sat ? getCatalogosSat([]) : getCatalogos([]));
    throw data;
  }
};

export const fetchaddCatalogo = ({ catalogo, catalogoData }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(startCatalogos());
    const { _id } = getState().empresas;
    const {
      data: { response },
    } = await axios.post(
      `/catalogos/?empresaId=${_id}&catalogo=${catalogo}`,
      catalogoData,
      {
        headers: { token: localStorage.getItem("pv2token"), caller: 'empresasDucks' },
      }
    );
    dispatch(createCatalogo());
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(setErrorCatalogos(data));
    throw data;
  }
};
export const fetchreport = ({
  nombreReporte = "",
  page = 1,
  perPage = 10,
  ...dataReportes
}) => async (dispatch, getState, { axios }) => {
  
  try {
    dispatch(startCatalogos());
    const {
      data: { data = {} },
    } = await axios.post(`/reportes/${nombreReporte}`, dataReportes, {
      params: {
        page,
        perPage,
      },
      
      headers: { token: localStorage.getItem("pv2token") },
    });
    
    dispatch(setReportes(data));
  } catch (error) {
    if (!error) dispatch(setReportesError());
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(setReportesError());
    throw data;
  }
};
export const deleteCatalogos = ({ catalogo, idCatalogo }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(startCatalogos());
    const { _id } = getState().empresas;
    console.log(catalogo);
    const {
      data: { response },
    } = await axios.delete(
      `/catalogos/?catalogo=${catalogo}&empresaId=${_id}&codigo=${idCatalogo}`,
      {
        headers: { token: localStorage.getItem("pv2token") },
      }
    );
    dispatch(createCatalogo());
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(setErrorCatalogos(data));
    throw data;
  }
};
export const fetchNumeroColaboradores = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { _id } = getState().empresas;
    const {
      data: { resultado },
    } = await axios.get(`colaborador/numero-colaboradores?idEmpresa=${_id}`, {
      headers: { token: localStorage.getItem("pv2token") },
    });
    dispatch(setNumeroColaboradores(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(setNumeroColaboradores(0));
    throw data;
  }
};

export const getDetalleConciliacion = (empresa) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { empresasForFiniquitos } = getState().empresas;
  dispatch(getDetalleStart());
  let empresaFilter = empresasForFiniquitos.find((elemento) => elemento._id === empresa);
  const {registroPatronal=[]} = empresaFilter;
  const list = registroPatronal.map((x) => {
    return { value: x, label: x };
  }); 
  dispatch(getDetalleSuccess(empresaFilter));
  dispatch(getRegistrosListSucess(list));

};