import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Col, CustomInput, Form, FormGroup } from "reactstrap";
import moment from "moment";
import InputFile from "../../ui/InputFile";
import { yupResolver } from "@hookform/resolvers/yup";
import InputImage from "../../ui/InputImage";
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
} from "./styled";
import { schemaUniforme } from "../../../constans/yupSchemas";
import {
  fetchCreateUtilitario,
  fetchListaUtilitario,
  fetchUpdateUtilitario,
} from "../../../store/utilitarioDucks";
import CurrencyFormat from "react-currency-format";
import "../Forms/CondicionesLaborales.css";
import atob from 'atob';
import { saveAs } from 'file-saver';
import axios from 'axios';
import SweetAlert from '../../ui/SweetAlet';
import BotonCargando from "../../BotonCargando";
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

const FormUniforme = React.memo(({ history }) => {
  const [valor, setValor] = useState({ valorF: "" });
  const [err, setErr] = useState({});
  const { valorF } = valor;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, control } = useForm({
    resolver: yupResolver(schemaUniforme),
  });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  const [saving, setSaving] = useState(false);
  
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const [utilitario, setUtilitario] = useState({});

  const downloadPdf = async (e) => {
    e.preventDefault()
    if (schemaUniforme.isValidSync(getValues())) {
      const save = await onSubmit(getValues())
      if (save) {
        setUtilitario(save)
        const file = await axios.get(`${api}/utilitario/generar-oficio/uniforme?idUtilitario=${save._id}`, headers);
        let byteString = atob(file.data);

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], { type: 'application/pdf' });
        saveAs(blob, `Responsiva${moment().format('YYYYMMDD')}`)
      }
    } else {
      await SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Asegúrese de llenar los campos marcados con *"
      })
    }
  }

  const save = async (data) => {
    const save = await onSubmit(data)
    if (save) {
      await SweetAlert({ title: 'Uniforme guardado', icon: 'success' });
      dispatch(fetchListaUtilitario('uniforme'));
      history.push('/tabla-uniforme');
    }
  }

  const onSubmit = async (data) => {
    try {
      setSaving(true);
      
      let save
      data.valorFactura = !valor.valorF ? 0 : valor.valorF;
      data.fechaEntrega = parseInt(moment(new Date(data.fechaEntrega)).format("x"));
      data.fechaRestitucion = parseInt(moment(new Date(data.fechaRestitucion)).format("x"));
      
      if (utilitario._id) {
        save = dispatch(fetchUpdateUtilitario(data, 'uniforme'));
      } else {
        save = dispatch(fetchCreateUtilitario(data, 'uniforme'));
      }
      
      dispatch(fetchListaUtilitario('uniforme'));
      
      return save
    } catch (error) {
      await SweetAlert({ title: 'Error!', text: error, icon: 'error' });
      console.log(error);
    } finally {
      
      setSaving(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(save)} style={{ marginLeft: "30px" }}>
      <FormGroup row>
        <Col sm={3}>
          <InputImage name="fotos[0]" id="foto0" register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name="fotos[1]" id="foto1" register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name="fotos[2]" id="foto2" register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name="fotos[3]" id="foto3" register={register} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Nombre*</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4, push: 12 }}>
          <InputUtils
            name="nombre"
            type="text"
            placeholder="Ej. Camisa"
            ref={register}
          />
          {err.nombre && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Cantidad</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name="cantidad"
            min="1"
            type="number"
            defaultValue={1}
            placeholder="Ej. $15000"
            ref={register({
              min: 1,
            })}
          />
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Marca</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name="marca"
            type="text"
            ref={register}
            placeholder="Ej. Levis"
          />
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Talla</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name="talla"
            type="text"
            placeholder="Ej. M"
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Color*</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name="color"
            type="text"
            placeholder="Ej. Blanco"
            ref={register}
          />
          {err.color && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El color es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Col sm={12}>
          <FormGroup row>
            <Col sm={3} style={{ marginBottom: "21px" }}>
              <LabelForms>Logo</LabelForms>
            </Col>
            <Col sm={{ offset: 1, size: 1 }}>
              <CustomInput
                innerRef={register}
                type="radio"
                label="SI"
                value={true}
                name="logo"
              />
            </Col>
            <Col sm={1}>
              <CustomInput
                innerRef={register}
                type="radio"
                label="NO"
                value={false}
                name="logo"
              />
            </Col>
          </FormGroup>
        </Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Entregado por*</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name="entregadoPor"
            type="text"
            placeholder="Entregado por"
            ref={register}
          />
          {err.entregadoPor && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Debes especificar por quien fue entregado
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Valor factura</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className="inputMoneda"
            value={valorF}
            name="valorFactura"
            placeholder="Valor factura"
            innerRef={register()}
            thousandSeparator={true}
            prefix={"$"}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setValor({ valorF: value });
            }}
          />
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Fecha de entrega*</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <Controller
            name='fechaEntrega'
            control={control}
            defaultValue={new Date()}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
                placeholderText="Fecha de entrega"
              />
            )}
          />
          {err.fechaEntrega && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de entrega es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Fecha de restitución*</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <Controller
            name='fechaRestitucion'
            control={control}
            innerRef={register()}
            defaultValue={new Date()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
                placeholderText="Fecha de restitución"
              />
            )}
          />
          {err.fechaRestitucion && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de restitucion es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Otras características</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name="otrasCaracteristicas"
            placeholder="Otras características"
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: "21px" }}>
          <LabelForms>Descripción</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name="observaciones"
            placeholder="Descripción"
            ref={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} className='mb-3'>
          <SaveButton onClick={(e) => downloadPdf(e)}>Generar Responsiva</SaveButton>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: "12px", marginLeft: "-17px" }}>
          <SubTitleMenu
            style={{ color: "#2B388F", fontWeight: "bold", fontSize: "20px" }}
          >
            Documentos
          </SubTitleMenu>
        </Col>
        <Col sm={3}>
          <LabelForms>Oficio de Entrega</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile
            name="oficioEntrega"
            id="oficioEntrega"
            register={register({ required: true })}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push("/");
            }}
            style={{ marginLeft: "auto" }}
          >
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <BotonCargando
            cargando={saving}
            disabled={saving}
            texto='Guardar'
            type='submit'
          />
        </Col>
      </FormGroup>
    </Form>
  );
});

export default FormUniforme;
