import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { faRedo, faPen, faSearch, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Input, InputGroupAddon, InputGroupText, InputGroup, Table } from "reactstrap";
import { BtnGroup, ListContent, HeaderStyle } from "../ui/styles/StyleNomina";
import ModalPercepciones from "./ModalPercepciones";
import TimbrarLayout from "./LayoutBancario";
import TimbrarResumen from "./TimbrarResumen";
import Incidencias from "./Incidencias";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
const api = process.env.REACT_APP_API_URL;

export default () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showModalType, setShowModalType] = useState({ colaborador: undefined, type: "" });
  const [showTimbrarLayout, setShowTimbrarLayout] = useState(false);
  const [showCalcularNomina, setShowCalcularNomina] = useState(false);
  const [type, setType] = useState("");
  const [showIncidencias, setShowIncidencias] = useState(false);
  const parameters = useParams();
  const [colaboradores, setColaboradores] = useState([]);
  const [buscaColaborador, setBuscaColaborador] = useState("");
  const [colaborador, setColaborador] = useState({});
  const location = useLocation();
  const { state } = location;
  const [empresa, setEmpresa] = useState({ informacionLegal: {} });
  const [colaboradoresNomina, setColaboradoresNomina] = useState([]);
  const { empresas } = useSelector((state) => state.empresas);
  const [colaboradoresSelect, setColaboradoresSelect] = useState([]);
  const history = useHistory();
  const [nominaQuery, setNominaQuery] = useState({ fechaInicio: state.fechaInicio, fechaFin: state.fechaFin, periodicidad: state.periodicidad });
  const [distSalarios, setDistSalarios] = useState([]);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {
    setNominaQuery({ fechaInicio: state.fechaInicio, fechaFin: state.fechaFin, periodicidad: state.periodicidad });
    if (parameters.status) {
      obtenerPendientes();
    } else {
      obtenerColaboradores();
    }
    setEmpresa(empresas.find((e) => e._id === parameters.idEmpresa) || {});
  }, []);

  const obtenerPendientes = async () => {
    let query = "";

    query += `&periodicidad=${state.periodicidad}&periodo=${state.periodo}&empresaId=${parameters.idEmpresa}`;
    let { data } = await axios.get(`${api}/nomina/obtener-pendientes/?${query}`, headers);
    setType("timbrarResumen");
    const [primero] = data.response.res;
    setNominaQuery({ fechaInicio: primero.fechaInicio, fechaFin: primero.fechaFin, periodo: primero.periodo });
    setDistSalarios(data.response.dist);
    setColaboradoresNomina(data.response.res);
  };

  const obtenerColaboradores = async (nombre = "") => {
    let query = "";
    if (nombre !== "") {
      query = `filtro=${nombre}`;
    }
    console.log(query);
    query += `&periodicidad=${state.periodicidad}&fechaInicio=${nominaQuery.fechaInicio}&fechaFin=${nominaQuery.fechaFin}&empresaId=${parameters.idEmpresa}`;

    let { data } = await axios.get(`${api}/nomina/colaboradores/?${query}`, headers);
    if (!data.response.length) {
      await Swal.fire("Prenomina", "No hay colaboradores en esta periodicidad", "info");
      history.goBack();
    }
    setColaboradores([...data.response]);
  };


  const onKeyDownBusqueda = (e) => e.key === 'Enter' && obtenerColaboradores(buscaColaborador);

  const calcularPrenomina = async () => {
    Swal.showLoading();
    let ob = {
      fechaInicio: nominaQuery.fechaInicio,
      fechaFin: nominaQuery.fechaFin,
      periodicidad: state.periodicidad,
      empresaId: parameters.idEmpresa,
      colaboradores: colaboradores.map((c) => c._id),
    };

    let { data } = await axios.post(`${api}/nomina/prenomina/`, ob, headers);
    const [primero] = data.response.res;
    setNominaQuery({ fechaInicio: primero.fechaInicio, fechaFin: primero.fechaFin, periodo: primero.periodo });
    setType("timbrarResumen");
    setDistSalarios(data.response.dist);
    setColaboradoresNomina(data.response.res);
    Swal.close();
  };

  const aprobarPrenomina = async () => {
    Swal.showLoading();
    await axios.post(
      `${api}/nomina/estado/?periodo=${nominaQuery.periodo}&periodicidad=${state.periodicidad}&fechaInicio=${nominaQuery.fechaInicio}&estado=aprobada&empresaId=${parameters.idEmpresa}`,
      colaboradoresSelect,
      headers
    );
    Swal.close();
    history.goBack();
  };

  const onChangeSelected = (selected) => {
    let data = Object.keys(selected).filter((k) => selected[k]);
    setColaboradoresSelect(data);
  };

  const onVolver = async () => {
    await obtenerColaboradores();
    setType("");
  };


  return (
    <>
      {showModalType.type !== "" && showModalType.type !== "incidencias" && (
        <ModalPercepciones
          typeModal={showModalType.type}
          colaborador={showModalType.colaborador}
          toggle={() => setShowModalType({ colaborador: undefined, type: "" })}
          onUpdate={obtenerColaboradores}
        />
      )}
      {showModalType.type === "incidencias" && (
        <Incidencias
          typeModal={showModalType.type}
          colaborador={showModalType.colaborador}
          toggle={() => setShowModalType({ colaborador: undefined, type: "" })}
          onUpdate={obtenerColaboradores}
        />
      )}

      <TimbrarLayout isOpen={showTimbrarLayout} toggle={() => setShowTimbrarLayout(!showTimbrarLayout)} nominas={colaboradoresNomina} />
      <Row>
        <Col>
          <HeaderStyle>
            <h1>Prenomina</h1>
            <h3>
              Periodo {state.periodo}  
              {                
                state.fechaInicio && <span style={{ fontSize: 18 }}> De {state?.fechaInicio.toLocaleDateString()} a {state?.fechaFin.toLocaleDateString()}</span>
              }
            </h3>
            <h3>Razón Social</h3>
            <h2>{empresa?.informacionLegal?.razon_social}</h2>
          </HeaderStyle>
        </Col>
      </Row>
      <BtnGroup>
        <Row className="my-4">
          {/* <Col md={"auto"}>
            <ButtonDropdown isOpen={showOptions} toggle={() => setShowOptions(!showOptions)}>
              <DropdownToggle caret>Cargar</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col> */}
          {type === "" && (
            <Col md={"auto"}>
              <Button outline onClick={calcularPrenomina}>
                Calcular
              </Button>
            </Col>
          )}
          {type !== "" && (
            <>
              <Col md={"auto"}>
                <Button outline onClick={onVolver}>
                  Volver
                </Button>
              </Col>
              <Col md={"auto"}>
                <Button outline disabled={!colaboradoresSelect.length} onClick={aprobarPrenomina}>
                  Aprobar
                </Button>
              </Col>
              <Col md={"auto"}>
                <Button outline onClick={() => setShowTimbrarLayout(!showTimbrarLayout)}>
                  Layout Bancario
                </Button>
              </Col>
            </>
          )}
        </Row>
      </BtnGroup>
      {type === "timbrarResumen" && <TimbrarResumen colaboradores={colaboradoresNomina} onChange={onChangeSelected} dist={distSalarios} />}
      {type === "" && (
        <Row className="">
          <Col>
            <ListContent>
              <Row>
                <Col className="my-2 mb-4">
                  <h2>Prenomina de Colaboradores</h2>
                </Col>
                <Col>
                  <Button close>
                    <span>
                      <FontAwesomeIcon icon={faRedo} color={"black"} cursor="pointer" onClick={() => obtenerColaboradores()} />
                    </span>
                  </Button>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <InputGroup>
                    <Input onChange={e => setBuscaColaborador(e.target.value)} type="text" placeholder="Buscar colaboradores" onKeyDown={onKeyDownBusqueda} />
                    <InputGroupAddon addonType="prepend" style={{ background: "white", border: "none" }}>
                      <InputGroupText style={{ background: "white", border: "none" }}>
                        <FontAwesomeIcon size={"2x"} icon={faSearch} />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
                    <thead>
                      <tr>
                        <th>Número</th>
                        <th>Nombre</th>
                        <th className="text-center">Salario</th>
                        <th className="text-center">Percepciones</th>
                        <th className="text-center">Deducciones</th>
                        <th className="text-center">Otros pagos</th>
                        <th className="text-center">Incidencias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {colaboradores.map((d, i) => (
                        <tr key={i}>
                          <th>{d.numero}</th>
                          <td>{d.nombre}</td>
                          <td className="text-center">
                            {d.salario.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }) || ""}
                          </td>
                          <td className="text-center">
                            <FontAwesomeIcon cursor="pointer" onClick={() => setShowModalType({ colaborador: d, type: "percepciones" })} icon={faBars} />
                          </td>
                          <td className="text-center">
                            <FontAwesomeIcon cursor="pointer" onClick={() => setShowModalType({ colaborador: d, type: "deducciones" })} icon={faBars} />
                          </td>
                          <td className="text-center">
                            <FontAwesomeIcon cursor="pointer" onClick={() => setShowModalType({ colaborador: d, type: "otrosPagos" })} icon={faBars} />
                          </td>
                          <td className="text-center">
                            <FontAwesomeIcon cursor="pointer" onClick={() => setShowModalType({ colaborador: d, type: "incidencias" })} icon={faBars} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </ListContent>
          </Col>
        </Row>
      )}
    </>
  );
};
