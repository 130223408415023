import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCitatorios } from '../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { getListaColaboradoresByEmpresa } from '../../store/colaboradorDucks';
import { updateCitatorio,dropCitatorio,eliminarExtension } from '../../store/citatorioDuck';
import { Form, Input, FormGroup, Label, Col, CustomInput } from 'reactstrap';
import { ExamLabel } from "../colaboradores/Forms/FormsSaludLaboral/styledSaludLaboral";
import SweetAlert from '../ui/SweetAlet';
import CustomSelect from '../ui/CustomSelect';
import InputMultiFile from '../ui/InputMultiFile';
import { CancelButton } from '../ui/styles/styles';
import Arrows from '../ui/Arrows';
import Icons from '../ui/Icons';
import estados from '../../constans/estados';
import CurrencyFormat from 'react-currency-format';
import '../colaboradores/Forms/CondicionesLaborales.css';
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';
import TableBody from './TableBodyFiles';
import TableBodyExtension from './TableBodyExtension';
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';
import axios from '../../services/axios';
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import CitatorioModal from './CitatorioModal';
import BotonCargando from '../../components/BotonCargando';
import DatePicker from "react-datepicker";

const numerocitatorio = [
  { label: 'Primer Citatorio', value: 1 },
  { label: 'Segundo Citatorio', value: 2 },
  { label: 'Tercer Citatorio', value: 3 },
  { label: 'Cuarto Citatorio', value: 4},
  { label: 'Quinto Citatorio', value: 5},
  { label: 'Sexto Citatorio', value:6}
];
const estatus = [
  { label: 'Activo', value: 'activo' },
  { label: 'Cerrado', value: 'cerrado' },
];

const api = process.env.REACT_APP_API_URL;

const EditarCitatorio = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(true);
  const [arrayInfo, setArrayInfo] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [err, setErr] = useState({});
  const { empresas = [] } = useSelector((state) => state.empresas);
  const { listaColaboradores = [] } = useSelector((state) => state.colaborador);
  const citatorio = useSelector((state) => state.citatorio.citatorio);
  const {archivos=[],colaborador,extensiones=[]} = citatorio;
  const [tablaExtensiones, setTablaExtensiones] = useState(extensiones);
  const [salario, setSalario] = useState({salarioDiario:citatorio['salarioDiarioIntegrado']});
  const {salarioDiario} = salario;
  const [depto, setDepto] = useState('');
  const [pues, setPues] = useState('');
  const [empCitada, setEmpCitada] = useState(citatorio['empresaCitada']);
  const [notEmail, setNotEmail] = useState(citatorio['notificar']);
  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(schemaCitatorios),
  });
  const headers = {
    headers: {
      "token": localStorage.getItem("pv2token"),
      "Content-Type": "multipart/form-data",
    },
  };
  const [saving, setSaving] = useState(false);
  
  const getFiles = (files) => {
    setValue('archivos', files);
  };
  
  const toggleModal = () =>{ 
    if (!isEdit) {
      setIsOpen(!isOpen)
    }
  };

  const onChange = ({ target }) => {
      if (!target.value) return;
      const filter = empresas.filter(x =>x.value === target.value);
      setEmpCitada(filter[0].label);
      if (listaColaboradores.length > 0) {
        setPues('');
        setSalario({salarioDiario:''});
        setDepto('');
      }
      try {
        dispatch(getListaColaboradoresByEmpresa(target.value));
      } catch (error) {}
    };
  
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
    if (extensiones.length > 0) {
      setTablaExtensiones(extensiones)
      setDataModal(citatorio);
    }
    (async () => {
      try {
        dispatch(fetchEmpresasToDropdown());
        dispatch(getListaColaboradoresByEmpresa(citatorio['empresa']));
        await getArchivos(archivos);
        setDepto(citatorio['departamento']);
		    setPues(citatorio['puesto']);
      } catch (error) {
        console.log(error);
      }
    })();
    register({ name: 'archivos' }, { required: false });
  }, [register, citatorio, dispatch,errors]);

  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(dropCitatorio());
        history.replace('/dashboard/juridico/citatorios');
      }
    } catch (error) {}
  };

  const getArchivos = async (datos) => {
    const array=[]
    if (datos.length) {
      await Promise.all(
        datos.map( async (element) => {
        const {data} = await axios.get(`/archivo/descargaInfo/${element}`);
        array.push({
          id: element,
          fecha: data.fecha,
          nombre: data.nombreOriginal,
          type: data.mimetype,
          espacio: data.espacio
        })
        })
      );
    }
    setArrayInfo(array);
  }

  const handleDelete = async (info,e) => {
    e.preventDefault();
    const { data } = await axios.put(`${api}/citatorios/documento/${citatorio._id}`, {archivo: info.id}, headers)
    const {respuesta} = data;
    const {archivos} = respuesta;
    getArchivos(archivos);
  }

  const handleChange = async ({ target }) => {
    const id = target.value;
    if(id !== 'Otro' && id !== ''){
      const { data } = await axios.get(`${api}/colaborador/obtener_colaborador/${id}`, headers)
      const {resultado} = data;
      setSalario({salarioDiario:resultado.InformacionNomina.salarioDiario});
      setPues(resultado.condicionesLaborales.puesto);
      setDepto(resultado.pertenencia.nombreDepartamento);
    }else{
      setSalario({salarioDiario:''});
      setPues('');
      setDepto('');
    }
  };

  const deleteExtension = async (extensiones,info,e,index) => {
    e.preventDefault();
    if(!isEdit){
      try {
        const { isConfirmed } = await SweetAlert({
          title: 'Seguro que desea eliminar?',
          showCancelButton: 'Cancelar',
          });
        if (isConfirmed) {
          let numeroExt = extensiones.length - 1;
          if (numeroExt === index) {
            dispatch(eliminarExtension(info._id))
          }else{
            SweetAlert({icon: 'error', title: 'No puedes borrar la extensión'});
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    
  }

  const onSubmit = async (data) => {
    try {
      setSaving(true);
      // data.fecha = parseInt(moment(data.fecha).format('x'));
      if(data.colaborador === 'Otro' || data.colaborador === ''){
        data.colaborador = null; 
      }
      
      data.fecha = new Date(data.fecha);
      dispatch(updateCitatorio(data));
      
      await SweetAlert({
        icon: 'success',
        title: 'Citatorio editado correctamente',
        showConfirmButton: false,
        timer: 2500,
      });
      history.replace('/dashboard/juridico/citatorios');
    } catch (error) {
      await SweetAlert({title:'Error!', text: 'Ocurrió un error!', icon: 'error'});
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
    <CitatorioModal isOpen={isOpen} data={dataModal} setIsOpen={setIsOpen} />
    <HeaderStyle style={{marginBottom:50}}>
      <h1>Editar Citatorio</h1>
    </HeaderStyle>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Col sm={1}>
          <Arrows
            onClick={() => {
              history.replace('/dashboard/juridico/citatorios');
            }}
          />
        </Col>
        <Col sm={1}>
          <Icons onClick={elminateUtil} />
        </Col>
        <Col sm={1}>
          <Icons
            pencil
            onClick={() => {
              setIsEdit((state) => !state);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Datos de la Empresa
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Empresa relacionada*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={empresas}
            disabled={isEdit || empresas.length > 0 ? false : true}
            type='select'
            name='empresa'
            defaultValue={citatorio['empresa']}
            innerRef={register()}
            onChange={onChange}
            
          />
          {err.empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Empresa citada*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='empresaCitada'
            value={empCitada}
            innerRef={register()}
            placeholder=''
            disabled={isEdit}
          />
          {err.empresaCitada && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa citada es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Datos del colaborador
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Colaborador</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={[{label:'Otro',value:'Otro'},...listaColaboradores]}
            disabled={(listaColaboradores.length > 0 ? false : true) || isEdit}
            type='select'
            name='colaborador'
            defaultValue={(colaborador && colaborador._id) || 'Otro'}
            innerRef={register()}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Otro colaborador</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='otroColaborador'
            defaultValue={citatorio['otroColaborador']}
            innerRef={register()}
            placeholder='Introduzca otro colaborador'
            disabled={isEdit}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Salario diario integrado</Label>
        <Col sm={10}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            thousandSeparator={true}
            name='salarioDiarioIntegrado'
            value={salarioDiario}
            prefix={'$'}
            onValueChange={(values) => {
              const {formattedValue, value} = values;
              setSalario({salarioDiario: value})
            }}
            innerRef={register()}
            placeholder='Salario diario integrado'
            disabled={isEdit}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Departamento</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='departamento'
            defaultValue={depto}
            innerRef={register()}
            placeholder='Introduzca a que departamento de la empresa va dirigido'
            disabled={isEdit}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Puesto</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='puesto'
            defaultValue={pues}
            innerRef={register()}
            placeholder='Introduzca a que area de la empresa va dirigido'
            disabled={isEdit}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Citatorio
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Numero de citatorio*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={numerocitatorio}
            type='select'
            name='numeroCitatorio'
            defaultValue={citatorio['numeroCitatorio']}
            innerRef={register()}
            placeholder='Seleccion el numero de citatorio'
            disabled={isEdit}
          />
          {err.numeroCitatorio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Numero del citatorio es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Estatus*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={estatus}
            type='select'
            name='status'
            defaultValue={citatorio['status']}
            innerRef={register()}
            placeholder='Seleccione el estatus Activo/Inactivo'
            disabled={isEdit}
          />
          {err.status && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Estatus es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Autoridad laboral*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='autoridadLaboral'
            defaultValue={citatorio['autoridadLaboral']}
            innerRef={register()}
            placeholder=' '
            disabled={isEdit}
          />
          {err.autoridadLaboral && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Autoridad laboral es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Inspector*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='inspector'
            defaultValue={citatorio['inspector']}
            innerRef={register()}
            placeholder=' '
            disabled={isEdit}
          />
          {err.inspector && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El inspector es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Estado*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={estados}
            type='select'
            name='estado'
            defaultValue={citatorio['estado']}
            innerRef={register()}
            placeholder='Seleccione un Estado'
            disabled={isEdit}
          />
          {err.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Ciudad*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='ciudad'
            defaultValue={citatorio['ciudad']}
            innerRef={register()}
            placeholder='Ej. Hermosillo'
            disabled={isEdit}
          />
          {err.ciudad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Fecha*</Label>
        <Col sm={10}>
          <Controller
            name='fecha'
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value ? value : new Date(citatorio['fecha'])}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
                placeholderText='Introduzca la fecha del Citatorio'
                disabled={isEdit}
              />
            )}
          />
          {err.fecha && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Fecha es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Hora*</Label>
        <Col sm={10}>
          <Input
            type='time'
            name='hora'
            defaultValue={citatorio['hora']}
            innerRef={register()}
            placeholder=''
            disabled={isEdit}
          />
          {err.hora && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Hora es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Atención
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Representante</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='representante'
            defaultValue={citatorio['representante']}
            innerRef={register()}
            placeholder=' '
            disabled={isEdit}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Notificar</Label>
        <Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
          <CustomInput
            id={'notificar'}
            innerRef={register}
            type="radio"
            label="SI"
            value={true}
            defaultChecked={citatorio['notificar']===true}
            name="notificar"
            disabled={isEdit}
            onClick={() => setNotEmail(true)}
          />
        </Col>
        <Col sm={1}>
          <CustomInput
            id={'notificar2'}
            innerRef={register}
            type="radio"
            label="NO"
            defaultChecked={citatorio['notificar']===false}
            value={false}
            name="notificar"
            disabled={isEdit}
            onClick={() => setNotEmail(false)}
          />
        </Col>
      </FormGroup>
      {notEmail ?
      <FormGroup row>
        <Label sm={2}>Email</Label>
        <Col sm={10}> 
            <Input 
              type='text'
              name='email'
              innerRef={register}
              placeholder='Email'
              disabled={isEdit}
              defaultValue={citatorio['email']}
            />
        </Col>
      </FormGroup>:null}
      <FormGroup row>
        <Label sm={2}>Comentarios relacionados con la situación del citatorio</Label>
        <Col sm={10}>
          <TextAreaUtils
            name='notaCitatorio'
            ref={register}
            placeholder='Comentarios relacionados con la situación del citatorio'
            defaultValue={citatorio['notaCitatorio']}
            disabled={isEdit}
            
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px", marginBottom:25 }}>
          Agregar Extensión de Citatorio
        </h5>
      </FormGroup>
      
      <FormGroup row>
      {extensiones.length > 0 ?
        <TableContainer style={{ marginLeft: 30, marginTop:10}}>
          <TableCommon
            keysAceepted={['Fecha', 'Hora', 'Representante','No. Citatorio','Descargar','']}
            extraTag={{ key: 'Valor Total', value: 1200 }}
            striped
          >
          <TableBodyExtension extensiones={tablaExtensiones} deleteExtension={deleteExtension} isEdit={isEdit}/>
          </TableCommon>
        </TableContainer>:null}
        <ExamLabel sm={3} onClick={e => toggleModal()} style={{ cursor: 'pointer' }} >+ Agregar</ExamLabel>
      </FormGroup>
      
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Adjuntar citatorios
        </h5>
      </FormGroup>
      <FormGroup row>
        <Col sm={10}>
          {!isEdit?
            <InputMultiFile name='archivos' getFiles={getFiles}/>
          :null}
          
        </Col>
      </FormGroup>
      {archivos.length > 0?
      <FormGroup row >
          <TableContainer style={{marginLeft:30, marginTop:10}}>
            <TableCommon
              keysAceepted={['Fecha', 'Nombre', '','' ]}
              extraTag={{ key: 'Valor Total', value: 1200 }}
              striped
            >
            <TableBody arrayInfo={arrayInfo} handleDelete={handleDelete}/>
            </TableCommon>
          </TableContainer>
      </FormGroup>
      :null}
      <FormGroup row style={{marginTop:'6em'}}>
          <Col sm={{ offset: 6, size: 3 }}>
            <CancelButton
              onClick={() => {
                history.replace('/dashboard/juridico/citatorios');
              }}
              style={{ marginLeft: 'auto' }}>
              Cancelar
            </CancelButton>
          </Col>
          <Col sm={3}>
            {!isEdit ?
              <BotonCargando 
                cargando={saving}
                disabled={saving}
                texto='Guardar'
                type='submit'
              />
            :null}
          </Col>
      </FormGroup>
    </Form>
    </>
  );
};

export default EditarCitatorio;
