const radiosReportes = {
  personales: [
    { label: 'General', value: 'general',check:'true'},
    { label: 'ID', value: 'id',check:'false'},
    { label: 'Contacto Emergencia', value: 'contacto',check:'false'},
    { label: 'Info. Personal Colaborador', value: 'info',check:'false'},
    { label: 'Info. Familiar', value: 'familiar',check:'false'},
    { label: 'Escolaridad', value: 'escolaridad',check:'false'},
  ],
  laborales: [
    { label: 'General', value: 'general',check:'true'},
    { label: 'Condiciones Laborales', value: 'condiciones',check:'false'},
    { label: 'Contrato Laboral', value: 'contratoLaboral',check:'false' },
    { label: 'Datos Bancarios', value: 'bancarios',check:'false' },
    { label: 'Info. Beneficiario(s)', value: 'beneficiarios',check:'false' },
    { label: 'Promoción', value: 'promocion',check:'false' },
    { label: 'Descuentos al Salario', value: 'descuentos',check:'false' },
  ],

  herramientas: [
    { label: 'Activas', value: 'activas',check:'true'},
    { label: 'Características', value: 'specs',check:'false' },
    { label: 'Próximas a Restitución', value: 'restitucion',check:'false' },
    { label: 'Vencidas', value: 'vencidas',check:'false' },
  ],

  uniformes:[
    {label:'Activas',value:'activas'},
    {label:'Características',value:'specs'},
    {label:'Próximas a Restitución',value:'restitucion'},
    {label:'Vencidas',value:'vencidas'},
  ],
/*   utilitario:[
    {label:'Medio de Transporte',value:'medioTransporte'},
    {label:'Locker / Espacio Personal',value:'locker'},
  ], */
  expediente:[
    {label:'Completo',value:'completo'},
    {label:'Incompleto',value:'incompleto'},
    {label:'Por vencer',value:'porVencer'},
    {label:'Vencidos',value:'vencidos'},
  ],
  vacaciones:[
    {label:'Historial',value:'historial'},
    {label:'Año Laboral',value:'nextAniversary'},
    {label:'Colaboradores en periodo vacacional',value:'vacacionistas'},
    {label:'Periodo vacacional pendiente',value:'periodoPendiente'},
    {label:'Fecha límite vacacional',value:'limite'},
  ],
  medico:[
    {label:'Notas Evolución',value:'evolucion'},
    {label:'No Patológicos',value:'nopatologicos'},
    {label:'Alergias',value:'alergias'},
    {label:'Tipo de Sangre',value:'sangre'},
    {label:'Médico Familiar',value:'medico'},
    {label:'Donador de Órganos',value:'donadorOrganos'},
    {label:'Donador de Sangre',value:'donadorSangre'},
    {label:'Situación de Vulnerabilidad',value:'vulnerabilidad'},
  ],
  visor:[
    {label:'Colaboradores Activos',value:'activos', check:'true'},
    {label:'Colaboradores Inactivos',value:'inactivos',check:'false'},
    {label:'Colaboradores en periodo Vacacional',value:'vacacionistas', check:'false'},
  ],
  imss:[
    {label:'Alta IMSS',value:'imss'},
    {label:'Baja IMSS',value:'bajaImss'},
    {label:'Crédito INFONAVIT',value:'credito'},
    {label:'S.B.C.',value:'sbc'},
    {label:'Puesto',value:'puesto'},
    {label:'Ciudad',value:'ciudad'},
  ],
  nomina:[
    {label:'Nómina',value:'nomina'},
    {label:'Pagos IMSS',value:'pagosImss'},
  ],
  permisos:[
    {label:'Historial', value:'historial'},
    {label:'Permiso Activo', value:'enPermiso'},
    {label:'Permiso Inactivo/Conluido',value:'concluido'},
    {label:'En Proceso', value:'proceso'}
  ],
  incapacidades:[
    {label:'Historial', value:'historial'},
    {label:'Incapacidades Activas', value:'vigentes'},
    {label:'Incapacidades Concluidas',value:'concluidas'},
  ],
  citatorios:[
    {label:'Historial', value:'historial'},
    {label:'Activos',value:'activos'},
    {label:'Cerrados',value:'cerrados'}
  ],
  demandas:[
    {label:'Historial', value:'historial'},
    {label:'Activas',value:'activas'},
    {label:'Cerradas',value:'cerradas'}
  ],
  finiquitos:[
    {label:'Historial', value:'historial'},
    {label:'En Proceso',value:'proceso'},
    {label:'En Convenio',value:'convenio'},
    {label:'Concluidos',value:'concluidos'},
    {label:'Proximos Pago',value:'proximos'}
  ]
};

export default radiosReportes;