import React, {useState, useCallback} from 'react';
import { Table } from 'reactstrap';
import './TableCommon.css';
import styled from 'styled-components';
import colors from '../../constans/colors';
import {
	faFile,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResumenModal from "../nomina/ResumenModal";

const TR = styled.tr`
  color: ${colors.tashColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: initial;
  letter-spacing: 0.322917px;

  color: #404041;
`;
const SpanFloat = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  /* identical to box height */
  text-align: justify;
  letter-spacing: 0.27125px;
  color: #464646;
`;

const TableCommon = React.memo(({ striped = true, reportes = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const getDocumento = (data) => {
    setDataModal(data);
    toggleModal();
  };
  const { columns = [], docs = [] } = reportes;
  return (
    <div className='table-containers' style={{ marginTop: '70px' }}>
      <ResumenModal isOpen={isOpen} data={dataModal} setIsOpen={setIsOpen} />
      <Table className='table-responsiver' borderless hover striped={striped} responsive autoCorrect='true'>
        <thead>
          <TR>
            {columns.map(({ title, index }) => (
              <th key={index}>{title}</th>
            ))}
          </TR>
        </thead>
        <tbody>
          {docs.map((element, index) => (
            <TR key={index}>
              {columns.map(({ name = '' }) => (
                <td kye={name}>{name === 'desgloce'? <FontAwesomeIcon cursor="pointer" icon={faFile} onClick={(e) => getDocumento(element)} />:element[name]}</td>
              ))}
            </TR>
          ))}
        </tbody>
      </Table>
      {/*   <TablePagination /> */}
    </div>
  );
});

export default TableCommon;
