import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ComponentDescrip from "../ui/ComponentDescrip";
import Search from "../ui/Search";
import { CancelButton, SaveButton } from '../ui/styles/styles';
import SweetAlert from '../ui/SweetAlet';
import UserList from "./UserList";
import { cambiarContrasenaAction } from "../../store/usuariosDuck";
import BotonCargando from '../BotonCargando';

const Usuarios = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [update, setUpdate] = useState({ password: '', rePassword: '' });
    const [user, setUser] = useState('');
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);

    const guardar = async () => {
        try {
            if (update.password !== update.rePassword) {
                await SweetAlert({ icon: 'warning', title: 'Atención', text: 'Las contraseñas no coinciden.' });
                return;
            }
            if (update.password.length <= 8) {
                await SweetAlert({ icon: 'warning', title: 'Atención', text: 'La contraseña es demasiado corta.' });
                return;
            }

            setSaving(true);
            dispatch(cambiarContrasenaAction(update, user));
            setUpdate({ password: '', rePassword: '' });
            await SweetAlert({ icon: 'success', title: 'Se ha modificado la contraseña.' });
            toggle();
        } catch (e) {
            setUpdate({ password: '', rePassword: '' });
            await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
            console.log(e);
        } finally {
            setSaving(false);
        }
    }

    const cancel = () => {
        setUpdate({ password: '', rePassword: '' });
        toggle();
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Cambiar contraseña</ModalHeader>
                <ModalBody>
                    <p className='mt-3 mb-5'>
                        Te recomendamos utilizar una contraseña de al menos 8 caracteres. Guarda la contraseña un lugar seguro.
                    </p>
                    <Form>
                        <FormGroup>
                            <Col xs={12}>
                                <Label>Nueva Contraseña</Label>
                                <Input
                                    type='password'
                                    value={update.password}
                                    onChange={e => setUpdate({ ...update, password: e.target.value })}
                                    placeholder='Ingrese la contraseña'
                                    required></Input>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12}>
                                <Label>Reingresar Contraseña</Label>
                                <Input
                                    type='password'
                                    value={update.rePassword}
                                    onChange={e => setUpdate({ ...update, rePassword: e.target.value })}
                                    placeholder='Ingrese de nuevo la contraseña'
                                    required></Input>
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <BotonCargando
                        onClick={guardar}
                        texto="Agregar"
                        cargando={saving}
                        disabled={saving}
                    />
                    {' '}
                    <CancelButton onClick={cancel}>Cancel</CancelButton>
                </ModalFooter>
            </Modal>
            <Row>
                <Col lg={12}>
                    <ComponentDescrip title={"Usuarios"} description={"Administra los usuarios de tu empresa"} icon={faUsers} />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Search placeholder="Buscar por correo, rol o empresa" />

                </Col>
            </Row>
            <Row style={{ marginBottom: '2em' }}>
                <Col lg={{ size: 'auto' }}>
                    <Link to="/dashboard/empresa/agrega-usuario">
                        <SaveButton style={{ marginTop: '2em' }} >+ Agregar</SaveButton>
                    </Link>

                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <UserList modal={modal} toggle={toggle} user={user} setUser={setUser} />
                </Col>
            </Row>
        </div>
    )
}
export default Usuarios;