import React, { useState, useEffect } from "react";
import { StyleInputs } from "../ui/styles/StyleNomina";
import { Row, Col, FormGroup, Input, Form } from "reactstrap";
import axios from "axios";
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

function  CalcularNomina({ setPeriodo, periodo }) {
  const [catalogos, setCatalogos] = useState([]);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  useEffect(() => {
    getCatalogos();
  }, []);

  const getCatalogos = async () => {
    let { data } = await axios.get(`${api}/catalogos/?sat=true&catalogo=periodicidadPago`, headers);
    setCatalogos(data.response);
  };

  return (
    <>
      <StyleInputs>
        <Form className="px-0 my-4">
          <FormGroup>
            <Row>
              <Col className="col-4">
                <Input type="select" name="select" onChange={(e) => setPeriodo({ ...periodo, periodicidad: e.target.value })} value={periodo.periodicidad}>
                  <option value={""}>Seleccione Periodo ..</option>
                  {catalogos.map((c) => (
                    <option value={c.descripcion} key={c.codigo}>
                      {c.descripcion}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col className="col-4">
                <DatePicker
                  selected={periodo.fechaInicio}
                  onChange={(date) => setPeriodo({ ...periodo, fechaInicio: date })}
                  placeholderText="Fecha de Inicio"
                  dateFormat="dd/MM/yyyy" 
                />
              </Col>
              <Col className="col-4">
                <DatePicker 
                  onChange={(date) => setPeriodo({ ...periodo, fechaFin: date })} 
                  selected={periodo.fechaFin} 
                  placeholderText="Fecha de Fin" 
                  dateFormat="dd/MM/yyyy" 
                />
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </StyleInputs>
    </>
  );
}

export default CalcularNomina;
