import React, { useState, useEffect } from 'react';
import OpcionesAlta from '../../components/colaboradores/OpcionesAlta';
import ColaboradorCard from '../../components/ui/ColaboradorCard';
import './DarAlta.css';
import EditForms from '../../components/colaboradores/Forms/EditForms';
import EmptyList from '../../components/ui/EmptyList';
import Spiner from '../../components/ui/Spiner';
import HttpService from '../../services/HttpService';
import { creandoColaboradorSuccees } from '../../store/colaboradorDucks';
import { useDispatch } from 'react-redux';

const VerColaborador = ({match, history}) => {
  const [cargando, setCargando] = useState(true);
  const [colaborador, setColaborador] = useState(null);
  const [SelectOption, setSelectOption] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    let montado = true;
    const { id } = match.params;

    if(id) {
      const obtenerColaborador = async () => {
        if(!montado) return;
        
        try {
          const { data } = await HttpService.get(`/colaborador/obtener_colaborador/${id}`);

          setColaborador(data.resultado);  
          dispatch(creandoColaboradorSuccees(data.resultado));
        } catch (error) {
          console.log(error);          
        } finally {
          setCargando(false);
        }
      };

      obtenerColaborador();
    } else {
      history.goBack();
    }

    return () => {
      montado = false;
    }
  }, []);

  if(cargando) return <div style={{marginTop: "20%"}}>
    <Spiner />
  </div>;

  const onChange = ({ target }) => {
    console.log(target.value);
    setSelectOption(() => parseInt(target.value));
  }

  return (
    <section className='grid-alta'>
      <div className='ComponentDescript'>
        <ColaboradorCard {...colaborador} />
      </div>
      <div className='Opciones'>
        <OpcionesAlta onChange={onChange} {...colaborador} />
      </div>
      {SelectOption === 0 ?
        <EmptyList
          align={"center"}
          title={'Comienza aquí'}
          description={'Selecciona la sección que deseas editar.'}
        />
        :(
        <div className='Forms'>
          <EditForms form={SelectOption} />
        </div>
      )}
    </section>
  );
};

export default VerColaborador;