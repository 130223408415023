import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import InputFile from '../../ui/InputFile';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu
} from '../VacacionesViews/styled';
import { fetchAgregarExtension } from '../../../store/permisosLaboralesDuck';
import DatePicker from "react-datepicker";

export const FromExtenderPermiso = React.memo(({ history }) => {
  const { permiso = {} } = useSelector((state) => state.permisos);
  const { periodoFinal = '', extensionPermiso = [] } = permiso;
  let periodoFinalExt = '';

  extensionPermiso.forEach(element => {
    periodoFinalExt = element.periodoFinalExt;
  });

  const dispatch = useDispatch();
  const { register, handleSubmit, control } = useForm({});

  const onSubmit = (data) => {
    try {
      data.periodoInicialExt = new Date(data.periodoInicialExt);
      data.periodoFinalExt = new Date(data.periodoFinalExt);

      dispatch(fetchAgregarExtension(data));
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Extensión de Permiso</SubTitleMenu>
        </Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de inicio</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Controller
            name='periodoInicialExt'
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value ? value : periodoFinalExt ? new Date(periodoFinalExt) : new Date(periodoFinal)}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
              />
            )}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de conclusión</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Controller
            name='periodoFinalExt'
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
              />
            )}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Causa</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            placeholder='Causa de extensión'
            name='causa'
            type='text'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Solicitud de Extensión</LabelForms>
        </Label>
        <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
          <InputFile name='solicitudExtension' id='solicitudExtension' register={register} />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Autorizacion de Extension</LabelForms>
        </Label>
        <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
          <InputFile name='autorizacionExtension' id='autorizacionExtension' register={register} />
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '60px' }}>
        <Col sm={{ offset: 4, size: 3 }}>
          <CancelButton type='button'
            onClick={() => {
              history.push('/');
            }}
            style={{}}
          >
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <SaveButton type='submit'>Guardar</SaveButton>
        </Col>
      </FormGroup>
    </Form>
  )
});

export default FromExtenderPermiso;