import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { agregarUtil } from '../../../store/utilitarioDucks';
import colors from '../../../constans/colors';
import PulsateDot from '../../ui/PulsateDot';
import { Tr } from './styled';
import CurrencyFormat from 'react-currency-format';

const TableBody = React.memo(({ listaUtilitarios }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showUtil = useCallback(
    (utilitario) => {
      dispatch(agregarUtil(utilitario));
      history.replace('/utilitario');
    },
    [dispatch, history]
  );

  return (
    <>
      {listaUtilitarios.map((utilitario, index) => (
        <Tr
          key={utilitario.nombre + index}
          onClick={() => showUtil(utilitario)}
        >
          <td style={{ paddingLeft: 40,  width: '30%' }}>{utilitario.nombre}</td>
          <td style={{ width: '25%' }}>{utilitario.numeroSerie}</td>
          <td style={{ width: '20%' }}>{utilitario.status === 'asignado' ? (
              <PulsateDot />
            ) : (
              <PulsateDot color={colors.closeStatus} />
            )}</td>
          <td style={{ width: '25%' }}><CurrencyFormat value={utilitario.valorFactura ? utilitario.valorFactura.toFixed(2):0} displayType={'text'} thousandSeparator={true} prefix={'$'} decimal={2} /></td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;