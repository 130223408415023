import React from 'react';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import {
	faTrashAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../constans/colors';

const TableAntecedentesBody = ({ data = [], deleteAntecedente}) => {
	
  return (
    <>
      {data.map((info,index) => (
        <Tr
          key={index}
        >
          	<td 
		 		style={{paddingTop:'22px' }}
			>{moment(info.fecha).format('DD/MM/YYYY')}</td>
			<td 
		 		style={{ paddingTop:'22px' }}
			>{info.delito}</td>
      <td style={{ paddingTop:'22px' }}>
		  	<a href='#' 
          onClick={(e) => deleteAntecedente(e,index)} 
          style={{textDecoration:'none',color:colors.tashColor}}
          >
          <FontAwesomeIcon 
            color={colors.tashColor}
            icon={faTrashAlt} 
          />
        </a>
		  </td>
      </Tr>
      ))}
    </>
  );
};

export default TableAntecedentesBody;
