import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';
import colors from '../../../constans/colors';
import { TableContainer } from '../VacacionesViews/styled';
import TableCommon from '../VacacionesViews/TableVacaciones';
import TableBody from './TableBody';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { fetchActas } from '../../../store/actasDuck';
import FirstAdd from '../../ui/FirstAdd';

const ActasView = ({history, disabled = false}) => {
    const dispatch = useDispatch();
    const { lista = [] } = useSelector((state) => state.actas);
    const { permisos = {} } = useSelector((state) => state.user);

    useEffect(() => {
        (async () => {
            try {
                await dispatch(fetchActas());
            } 
            catch (error) {
                console.log(error);
            }
        })();
    }, []);

    if (lista.length > 0)
        return (
            <React.Fragment>
                <FormGroup row style={{ marginLeft: 16}}>
                    <h5>Actas administrativas</h5>
                </FormGroup>
                <Row>
                    <Col>
                        <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                            <TableCommon
                                keysAceepted={['No. Acta', 'Tipo acta', 'Fecha', 'Elaborado por', '']}
                                striped >
                                <TableBody lista={lista} />
                            </TableCommon>
                        </TableContainer>
                    </Col>
                </Row>
                {
                    !disabled &&  <Row style={{ marginLeft: 16 }} hidden={permisos.colaboradorActas !== 'write'}>
                        <Col sm={12}>
                            <Button type="button" onClick={() => {
                                history.push('/actas/agregar')
                            }}
                                style={{ background: colors.secondary, width: '203px', height: '51px', borderRadius: '4px' }}>+ Agregar</Button>
                        </Col>
                    </Row>
                }
               
            </ React.Fragment>
        );
    else return (
        <div style={{textAlign: "center"}}>
            {
                disabled 
                ? <div style={{marginTop: "20%", fontWeight: "bold"}}>Sin actas administrativas</div>
                : <FirstAdd mensaje="Agrega aquí las actas administrativas de tu colaborador" vista='actas' disabled={permisos.colaboradorActas !== 'write'}/>

            }
        </div>)
};

export default ActasView;