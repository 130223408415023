import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import DescuentosSalarioView from './DescuentosViews/DescuentosSalarioView';
import FormDescuentoSalario from './DescuentosViews/FormDescuentoSalario';

const DescuentosSalario = ({disabled = false}) => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/descuentos/agregar' component={(props) => <FormDescuentoSalario disabled={disabled} {...props} />} />
                <Route path='/' component={(props) => <DescuentosSalarioView disabled={disabled} {...props} />} /> 
            </Switch>
        </HashRouter>
    );
};

export default DescuentosSalario;