import React from "react"
import {Container, Row, Col} from "reactstrap";

const NotFound = () =>{

    return(
        <Container>
            <Row>
                <Col sm="9" md="3" lg="3" >
                    <img src={require('../assets/img/page_not_found.svg')} alt="Not found"/>
                </Col>
            </Row>

        </Container>

    )
}
export default NotFound