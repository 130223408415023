import React from 'react';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import {
	faTrashAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../constans/colors';

const TableImssBody = ({ data = [], deleteImss}) => {
	
  return (
    <>
      {data.map((info,index) => (
        <Tr
          key={index}
        >
        	<td 
		  		style={{ paddingTop:'22px' }}
			>{info.empresa}</td>
          	<td 
		 		style={{paddingTop:'22px' }}
			>{moment(info.alta).format('DD/MM/YYYY')}</td>
          	<td 
		 		style={{paddingTop:'22px' }}
			>{moment(info.baja).format('DD/MM/YYYY')}</td>
			<td 
		 		style={{ paddingTop:'22px' }}
			>{info.sbc}</td>
      <td style={{ paddingTop:'22px' }}>
		  	<a href='#' 
				onClick={(e) => deleteImss(e,index)} 
				style={{textDecoration:'none',color:colors.tashColor}}
				>
				<FontAwesomeIcon 
					color={colors.tashColor}
					icon={faTrashAlt} 
				/>
				</a>
			</td>
      </Tr>
      ))}
    </>
  );
};

export default TableImssBody;
