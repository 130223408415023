import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table } from "reactstrap";
import { BtnGroup, HeaderStyle, ListContent } from "../../components/ui/styles/StyleNomina";
import { TitleMenu } from "../../components/ui/styles/styles";
import InputCurrency from "react-currency-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Fragment } from "react";
import ResumenPtu from "./ResumenPtu";
const api = process.env.REACT_APP_API_URL;

const Extraordinaria = ({ title = "" }) => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const { state } = location;
    const [empresa, setEmpresa] = useState({ informacionLegal: {} });
    const { empresas } = useSelector(state => state.empresas);
    const [showYearOptions, setShowYearOptions] = useState(false);
    const [periodo, setPeriodo] = useState(state.nomina ? state.nomina.periodo : moment(Date.now()).format("YYYY"));
    const [years] = useState(Array(3).fill(parseInt(periodo)).map((year, i) => (year - i).toString()));
    const [colaboradores, setColaboradores] = useState([]);
    const [buscaColaborador, setBuscaColaborador] = useState("");
    const [type, setType] = useState("");
    const [distUtilidades, setDistUtilidades] = useState([]);
    const [calculados, setCalculados] = useState([])
    const [colaboradoresSelect, setColaboradoresSelect] = useState([]);
    const [historyPeriodo, setHistoryPeriodo] = useState('')
    const headers = {
        headers: {
            token: localStorage.getItem("pv2token"),
        },
    };

    useEffect(() => {
        setEmpresa(empresas.find(e => e._id === params.idEmpresa) || {});
    }, [])

    useEffect(() => {
        setHistoryPeriodo(periodo)
    }, [periodo])

    useEffect(() => {
        if (periodo === historyPeriodo)
            if (state.nomina) obtenerPendientes()
            else obtenerColaboradores()
    }, [periodo, historyPeriodo])

    const obtenerPendientes = async () => {
        let query = "";
        query += `periodo=${periodo}&path=aguinaldo`;
        let { data } = await axios.get(`${api}/extraordinaria/pendientes/${params.idEmpresa}/?${query}`, headers);
        setType("resumen");
        setDistUtilidades(data.response.dist);
        setCalculados(data.response.res);
    };

    const obtenerColaboradores = async (nombre = "") => {
        let query = `?periodo=${periodo}&path=aguinaldo`;
        if (nombre !== "") (query += `&filtro=${nombre}`);

        let { data } = await axios.get(`${api}/extraordinaria/colaboradores/${params.idEmpresa}/${query}`, headers);
        if (nombre === "" && !data.response.length) {
            await Swal.fire("Nómina Extraordinaria", "No hay colaboradores que se les aplique Aguinaldos para este periodo", "info");
            setColaboradores([])
            return
        }
        setColaboradores([...data.response]);
    }

    const onKeyDownBusqueda = (e) => e.key === 'Enter' && obtenerColaboradores(buscaColaborador);

    const calcularExtraordinaria = async () => {
        Swal.showLoading();
        let body = {};
        body.colaboradores = colaboradores.map(m => m._id);
        body.periodo = periodo

        let { data } = await axios.post(`${api}/extraordinaria/aguinaldos/${params.idEmpresa}`, body, headers)
        setType('resumen')
        setDistUtilidades(data.response.dist);
        setCalculados(data.response.res);
        Swal.close();
    }

    const aprobarPrenomina = async () => {
        Swal.showLoading();
        await axios.put(`${api}/extraordinaria/estado/${params.idEmpresa}/?path=aguinaldo&estado=aprobada&periodo=${periodo}`, colaboradoresSelect, headers);
        Swal.close();
        history.goBack();
    };

    const onVolver = async () => {
        await obtenerColaboradores();
        setType("");
    };

    const onChangeSelected = (selected) => {
        let data = Object.keys(selected).filter((k) => selected[k]);
        setColaboradoresSelect(data);
    };

    return (
        <Fragment>
            <Row>
                <TitleMenu></TitleMenu>
                <HeaderStyle>
                    <h1>{title} {state.page ? `- ${state.page.toUpperCase()}` : ''} </h1>
                    {type !== "" && <h3>Periodo {periodo}</h3>}
                    <h3>{type === "" ? 'Configura el cálculo de tu nómina Extraordinaria' : 'Cálculos de tu nómina Extraordinaria'}</h3>
                    <h2>{empresa.informacionLegal.razon_social}</h2>
                    <span style={{ color: 'rgb(43, 56, 143)' }}>* Al realizar los calculos se omiten los colaboradores que no cumplan las condiciones para el Aguinaldo</span>
                    <br />
                    <span style={{ color: 'rgb(43, 56, 143)' }}>&nbsp;&nbsp;Ejemplo: si tienen menos de 60 dias laborados</span>
                </HeaderStyle>
            </Row>
            <BtnGroup>
                <Row className="my-4">
                    {
                        type === "" && (
                            <Fragment>
                                <Col md={"auto"}>
                                    <ButtonDropdown isOpen={showYearOptions} toggle={() => setShowYearOptions(!showYearOptions)}>
                                        <DropdownToggle style={{ width: 'auto' }} caret>Periodo {periodo}</DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                years.map((year, i) => (
                                                    <DropdownItem key={i} onClick={() => setPeriodo(year)}>{year}</DropdownItem>
                                                ))
                                            }
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </Col>
                                <Col md={"auto"}>
                                    <Button outline onClick={calcularExtraordinaria} disabled={!colaboradores.length}>
                                        Calcular
                                    </Button>
                                </Col>
                            </Fragment>
                        )
                    }
                    {
                        type !== "" && (
                            <Fragment>
                                <Col md={"auto"}>
                                    <Button outline onClick={onVolver}>
                                        Volver
                                    </Button>
                                </Col>
                                <Col md={"auto"}>
                                    <Button outline disabled={!colaboradoresSelect.length} onClick={aprobarPrenomina}>
                                        Aprobar
                                    </Button>
                                </Col>
                            </Fragment>
                        )
                    }
                </Row>
            </BtnGroup>
            {type === "resumen" && <ResumenPtu colaboradores={calculados} onChange={onChangeSelected} dist={distUtilidades} />}
            {type === "" && (
                <Row>
                    <Col>
                        <ListContent>
                            <Row>
                                <Col className="my-2 mb-4">
                                    <h2>Colaboradores</h2>
                                </Col>
                                <Col>
                                    <Button close>
                                        <span>
                                            <FontAwesomeIcon icon={faRedo} color={"black"} cursor="pointer" onClick={() => obtenerColaboradores()} />
                                        </span>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <InputGroup>
                                        <Input onChange={e => setBuscaColaborador(e.target.value)} type="text" placeholder="Buscar colaboradores" onKeyDown={onKeyDownBusqueda} />
                                        <InputGroupAddon addonType="prepend" style={{ background: "white", border: "none" }}>
                                            <InputGroupText style={{ background: "white", border: "none" }}>
                                                <FontAwesomeIcon size={"2x"} icon={faSearch} />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table color="white" style={{ background: "white", marginTop: "10px" }}>
                                        <thead>
                                            <tr>
                                                <th>Número</th>
                                                <th>Nombre</th>
                                                <th className="text-center">Salario Diario</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {colaboradores.map((c, i) => (
                                                <tr key={i}>
                                                    <td>{c.numero}</td>
                                                    <td>{c.nombre}</td>
                                                    <td className="text-center">
                                                        {c.salario.toLocaleString("en-US", {
                                                            style: "currency",
                                                            currency: "USD",
                                                        }) || ""}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </ListContent>
                    </Col>
                </Row>
            )}
        </Fragment>
    )
}

export default Extraordinaria;
