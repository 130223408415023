const initialState = {
    loading: false,
    error: null,
    listaVerificaciones: [],
    editar: false,
    verificacion: {},
  };
  
  export const FETCH_VERIFICACIONES = 'FETCH_VERIFICACIONES';
  export const FETCH_VERIFICACIONES_EXITO = 'FETCH_VERIFICACIONES_EXITO';
  export const FETCH_VERIFICACIONES_ERROR = 'FETCH_VERIFICACIONES_ERROR';
  
  export const FETCH_LISTA_VERIFICACIONES = 'FETCH_LISTA_VERIFICACIONES';
  export const FETCH_LISTA_VERIFICACIONES_EXITO = 'FETCH_LISTA_VERIFICACIONES_EXITO';
  export const FETCH_LISTA_VERIFICACIONES_ERROR = 'FETCH_LISTA_VERIFICACIONES_ERROR';
  
  export const FETCH_VERIFICACION = 'FETCH_VERIFICACION';
  export const FETCH_VERIFICACION_EXITO = 'FETCH_VERIFICACION_EXITO';
  export const FETCH_VERIFICACION_ERROR = 'FETCH_VERIFICACION_ERROR';
  
  export const EDITAR_VERIFICACION = 'EDITAR_VERIFICACION';
  export const EDITAR_VERIFICACION_EXITO = 'EDITAR_VERIFICACION_EXITO';
  export const EDITAR_VERIFICACION_ERROR = 'EDITAR_VERIFICACION_ERROR';
  export const EDITAR_BUTTON = 'EDITAR_BUTTON';
  export const CERRAR_VERIFICACION = 'CERRAR_VERIFICACION';
  export const CERRAR_VERIFICACION_EXITO = 'CERRAR_VERIFICACION_EXITO';
  export const CERRAR_VERIFICACION_ERROR = 'CERRAR_VERIFICACION_ERROR';
  
  export const CREAR_VERIFICACION = 'CREAR_VERIFICACION';
  export const CREAR_VERIFICACION_EXITO = 'CREAR_VERIFICACION_EXITO';
  export const CREAR_VERIFICACION_ERROR = 'CREAR_VERIFICACION_ERROR';
  
  export default function citatorioReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case FETCH_VERIFICACIONES:
        return { ...state, loading: true };
      case FETCH_VERIFICACIONES_EXITO:
        return { ...state, listaVerificaciones: payload.respuesta, loading: false };
      case FETCH_VERIFICACIONES_ERROR:
        return { ...state, error: payload, loading: false };
      case FETCH_LISTA_VERIFICACIONES:
        return { ...state, loading: true };
      case FETCH_LISTA_VERIFICACIONES_EXITO:
        return { ...state, loading: false, listaCitatorios: [...payload] };
      case FETCH_LISTA_VERIFICACIONES_ERROR:
        return { ...state, error: payload, loading: false };
      case FETCH_VERIFICACION:
        return { ...state, loading: true };
      case FETCH_VERIFICACION_EXITO:
        return { ...state, verificacion: payload, loading: false };
      case EDITAR_VERIFICACION:
        return { ...state, loading: true };
      case EDITAR_VERIFICACION_EXITO:
        return { ...state, loading: false };
      case EDITAR_VERIFICACION_ERROR:
        return { ...state, loading: false };
      case EDITAR_BUTTON:
        return { ...state, editar: !state.editar };
      case CERRAR_VERIFICACION:
        return { ...state, loading: true };
      case CERRAR_VERIFICACION_EXITO:
        return { ...state, loading: false };
      case CERRAR_VERIFICACION_ERROR:
        return { ...state, loading: false };
      case CREAR_VERIFICACION:
        return { ...state, loading: true };
      case CREAR_VERIFICACION_EXITO:
        return { ...state, loading: false };
      case CREAR_VERIFICACION_ERROR:
        return { ...state, loading: false };
      default:
        return state;
    }
  }
  
  export const getVerificaciones = () => ({
    type: FETCH_VERIFICACIONES,
  });
  
  export const getVerificacionesExito = (payload) => ({
    type: FETCH_VERIFICACIONES_EXITO,
    payload,
  });
  
  export const getVerificacionesError = (payload) => ({
    type: FETCH_VERIFICACIONES_ERROR,
    payload,
  });
  
  export const cerrarVerificacion = () => ({
    type: CERRAR_VERIFICACION,
  });
  
  export const cerrarVerificacionExito = () => ({
    type: CERRAR_VERIFICACION_EXITO,
  });
  
  export const cerrarVerificacionError = () => ({
    type: CERRAR_VERIFICACION_ERROR,
  });
  export const eliminarVerificacion = () => ({
    type: CERRAR_VERIFICACION,
  });
  
  export const eliminarVerificacionExito = () => ({
    type: CERRAR_VERIFICACION_EXITO,
  });
  
  export const eliminarVerificacionError = () => ({
    type: CERRAR_VERIFICACION_ERROR,
  });
  
  export const getVerificacion = () => ({
    type: FETCH_VERIFICACION,
  });
  
  export const getVerificacionExito = (payload) => ({
    type: FETCH_VERIFICACION_EXITO,
    payload,
  });
  
  export const getVerificacionError = () => ({
    type: FETCH_VERIFICACION_ERROR,
  });
  
  export const getListaVerificacionesStart = () => ({
    type: FETCH_LISTA_VERIFICACIONES,
  });
  
  export const getListaVerificacionesSucces = (payload) => ({
    type: FETCH_LISTA_VERIFICACIONES_EXITO,
    payload,
  });
  
  export const getListaVerificacionesError = () => ({
    type: FETCH_LISTA_VERIFICACIONES_ERROR,
  });
  
  export const editarVerificacion = () => ({
    type: EDITAR_VERIFICACION,
  });
  
  export const editarVerificacionExito = (payload) => ({
    type: EDITAR_VERIFICACION_EXITO,
    payload,
  });
  
  export const editarVerificacionError = (payload) => ({
    type: EDITAR_VERIFICACION_ERROR,
    payload,
  });
  
  export const editarBoton = () => ({
    type: EDITAR_BUTTON,
  });
  
  export const creaVerificacion = () => ({
    type: CREAR_VERIFICACION,
  });
  
  export const crearVerificacionExito = () => ({
    type: CREAR_VERIFICACION_EXITO,
  });
  
  export const crearVerificacionError = () => ({
    type: CREAR_VERIFICACION_ERROR,
  });
  
  export const getVerificacionesAction = () => async (
    dispatch,
    getState,
    { axios }
  ) => {
    dispatch(getVerificaciones());
    try {
      const { respuesta } = (
        await axios.get(`/verificaciones`, {
          headers: { token: localStorage.getItem('pv2token') },
        })
      ).data;
  
      dispatch(getVerificacionesExito({ respuesta }));
    } catch (e) {
      const { data } = e.response;
      dispatch(getVerificacionesError(data));
      throw data;
    }
  };
  
  //TODO: Citatorios por empresa
  
  export const verificacionAction = (idVerificacion) => async (
    dispatch,
    getState,
    { axios }
  ) => {
    dispatch(getVerificacion());
    try {
      const { respuesta } = (
        await axios.get(`/verificaciones/${idVerificacion}`, {
          headers: { token: localStorage.getItem('pv2token') },
        })
      ).data;
      dispatch(getVerificacionExito({ respuesta }));
    } catch (e) {
      const { data } = e.response;
      dispatch(getVerificacionError(data));
      throw data;
    }
  };
  
  export const createVerificacion = (dataVerificacion) => async (
    dispatch,
    getState,
    { axios }
  ) => {
    const { foto, ...verificacion } = dataVerificacion;
    dispatch(creaVerificacion());
    try {
      console.log(foto);
      const formData = new FormData();
      if (foto.length !== 0){
        formData.append('foto', foto[0]);
      }
      formData.append('verificacion', JSON.stringify(verificacion));
      
      const {
        data: { resultado },
      } = await axios.post('/verificaciones/', formData, {
        headers: {
          token: localStorage.getItem('pv2token'),
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(crearVerificacionExito(resultado));
    } catch (error) {
      // const { response } = error;
      // const { request, ...errorObject } = response;
      // const { data } = errorObject;
      console.log(error);
      
      dispatch(crearVerificacionError(error));
    }
  };

  export const updateVerificacion = (dataVerificacion) => async (
    dispatch,
    getState,
    { axios }
  ) => {
    const { _id } = getState().verificaciones.verificacion;
    const { foto, ...verificacion } = dataVerificacion;
    dispatch(editarVerificacion());
    try {
      const formData = new FormData();
      if (foto.length !== 0){
        formData.append('foto', foto[0]);
      }
      formData.append('verificacion', JSON.stringify(verificacion));
      const {
        data: { resultado },
      } = await axios.put(`/verificaciones/${_id}`, formData, {
        headers: {
          token: localStorage.getItem('pv2token'),
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(editarVerificacionExito(resultado));
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      const { data } = errorObject;
      dispatch(editarVerificacionError(data));
    }
  };
  