import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import VacacionesAdd from './VacacionesViews/VacacionesAdd';
import VacacionesEdit from './VacacionesViews/VacacionesEdit';
import VacacionesView from './VacacionesViews/VacacionesView';

const Vacaciones = ({disabled = false}) => {
  return (
    <HashRouter basename='/'>
      <Switch>
        <Route path='/vacaciones/agregar' component={VacacionesAdd} />
        <Route path='/vacaciones' component={(props) => <VacacionesEdit disabled={disabled} {...props}/>} />
        <Route path='/' component={(props) => <VacacionesView disabled={disabled} {...props}/>} />
      </Switch>
    </HashRouter>
  );
};

export default Vacaciones;