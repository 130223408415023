import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Sidebar from '../components/layout/Sidebar/Sidebar';
import routes from '../routes/routes';
import logoImage from '../assets/img/logo.png';

import './Dashborad.css';
const Dashboard = (props) => {

  useEffect(() => {
    let section = document.getElementById("mainApp").getElementsByTagName("section")
    var logoWrapper = document.getElementById("pc1");
    if (section.length > 0) {
      logoWrapper.classList.add("hide");
    } else {
      logoWrapper.classList.remove("hide");
    }
  }, [props])

  return (
    <Container fluid>
      <Row>
        <Col lg='12' id='mainApp' style={{ position: 'fixed', width: '100vw' }}>
          <Sidebar {...props} routes={routes} />
        </Col>
        <div id="pc1" className='nomizor_welcome hide'>
          <img src={logoImage}></img>
        </div>
      </Row>
    </Container>
  );
};
export default Dashboard;
