import styled from 'styled-components';
import logoImage from '../../../../assets/img/nomizorRojo.svg';

export const LogoContainer = styled.div`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${logoImage});
  margin-top: 28px;
  margin-bottom: 20px;
  min-height: 26px;
  width: 100%;
`;