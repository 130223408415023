import React, { useState, useEffect, useCallback } from "react";
import { faUserFriends, faRedo, faPen, faSearch, faMoneyBill, faFile, faArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BtnGroup, ListContent, StyleInputs } from "../ui/styles/StyleNomina";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  //Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Label,
  FormGroup,
} from "reactstrap";
//import moment from "moment";
import "../ui/styles/Nomina.css";
import DistSalarios from "./DistSalarios/DistSalariosPlot";
import ResumenModal from "./ResumenModal";

const TimbrarResumen = ({ type = "timbrados", colaboradores = [], onChange = () => { }, metrics = true, dist = [] }) => {

  const [selectColaborador, setSelectColaborador] = useState({});
  const [resumenNomina, setResumenNomina] = useState({});
  const [openFile, setOpenFile] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [colabs, setColabs] = useState([])

  useEffect(() => {
    if (!colaboradores.length) return;

    setColabs(colaboradores);
    if (colaboradores.length !== Object.keys(selectColaborador).length) {
      setSelectColaborador({});
    }
    if (metrics) setResumenNomina(resumenCalculos(colaboradores));
  }, [colaboradores]);

  // useEffect(() => {
  //   console.log(colaboradores);
  //   if (type === "errores") {
  //     setResumenNomina(resumenCalculos(colaboradores.filter((c) => c.estado === "error")));
  //   }
  // }, [type]);

  useEffect(() => {
    onChange(selectColaborador);
  }, [selectColaborador]);

  const addColaborador = (id) => {
    selectColaborador[id] = !selectColaborador[id] && true;
    setSelectColaborador({ ...selectColaborador });
  };

  const selectedAll = (select) => {
    colaboradores.map((e, i) => (selectColaborador[e.empleadoId] = select));
    setSelectColaborador({ ...selectColaborador });
  };

  const openFiles = (id) => {
    openFile[id] = !openFile[id];
    setOpenFile({ ...openFile });
  };
  const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const getDocumento = (data) => {
    setDataModal(data);
    toggleModal();
  };

  const filtrarColaboradores = (e) => {
    const value = e.target.value
    setColabs(colaboradores.filter(f => f.nombre.toLowerCase().includes(value.toLowerCase()) || f.numero.toString().includes(value)));
  }

  const getWidth = (id) => document.getElementById(id) ? document.getElementById(id).clientWidth : 321

  console.log(colabs);

  return (
    <>
      {metrics && (
        <Row>
          <Card data={resumenNomina.totalEmpleados || []} />
          <Card data={resumenNomina.pagoTotal || []} />
          <Card id="carta-dist">{dist.length > 0 && <DistSalarios title="Distribución de sueldos" data={dist} width={getWidth('carta-dist')} height={185} />}</Card>
          <Card data={resumenNomina.nominaDeducciones || []} />
        </Row>
      )}
      <ResumenModal isOpen={isOpen} data={dataModal} setIsOpen={setIsOpen} />
      <Row className="mt-4">
        <Col>
          <ListContent>
            <Row>
              <Col className="my-2 mb-4">
                <h2>Colaboradores</h2>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <InputGroup>
                  <Input type="text" placeholder="Buscar colaboradores" onChange={filtrarColaboradores} />
                  <InputGroupAddon addonType="prepend" style={{ background: "white", border: "none" }}>
                    <InputGroupText style={{ background: "white", border: "none" }}>
                      <FontAwesomeIcon size={"2x"} icon={faSearch} color={"black"} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {type === "errores" ? (
                  <TableErrors colaboradores={colaboradores} />
                ) : (
                  <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
                    <thead>
                      <tr>
                        <th>
                          <FormGroup check inline>
                            <Label check inline>
                              <Input
                                type="checkbox"
                                onClick={() => selectedAll(!Object.keys(selectColaborador).some((v) => selectColaborador[v]))}
                                checked={Object.keys(selectColaborador).some((v) => selectColaborador[v])}
                              />
                              Todo
                            </Label>
                          </FormGroup>
                        </th>
                        <th>Numero</th>
                        <th>Nombre</th>
                        <th className="text-center">Total Percepciones</th>
                        <th className="text-center">Total Deducciones</th>
                        <th className="text-center">Total Otros pagos</th>
                        <th className="text-center">Total a pagar</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {colabs.map((d, i) => (
                        <tr key={i}>
                          <td>
                            <FormGroup check inline>
                              <Label check inline>
                                <Input type="checkbox" onClick={() => addColaborador(d.empleadoId)} checked={selectColaborador[d.empleadoId]} />
                              </Label>
                            </FormGroup>
                          </td>
                          <th>{d.numero}</th>
                          <td>{d.nombre}</td>
                          <td className="text-center">
                            {(d.totales.totalPercepciones || 0).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td className="text-center">
                            {(d.totales.totalDeducciones || 0).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td className="text-center">
                            {(d.totales.totalOtrosPagos || 0).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td className="text-center">
                            {(d.totales.total || 0).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td className="text-center">
                            {(d.xml !== undefined || d.pdf !== undefined) && (
                              // <StyleInputs>
                              <ButtonDropdown size="sm" isOpen={openFile[d.empleadoId]} toggle={() => openFiles(d.empleadoId)}>
                                <DropdownToggle style={{ height: "30px", background: "rgb(43, 56, 143)" }} caret>
                                  Descargar
                                </DropdownToggle>
                                <DropdownMenu>
                                  {d.xml !== undefined && (
                                    <a href={`data:application/xml;base64,${d.xml}`} download={`XML-${d.nombre}-${d.numero}-${d.periodo}.xml`}>
                                      <DropdownItem>XML </DropdownItem>
                                    </a>
                                  )}
                                  {d.pdf !== undefined && (
                                    <a href={`data:application/pdf;base64,${d.pdf}`} download={`PDF-${d.nombre}-${d.numero}-${d.periodo}.pdf`}>
                                      <DropdownItem>PDF </DropdownItem>
                                    </a>
                                  )}
                                </DropdownMenu>
                              </ButtonDropdown>
                              // </StyleInputs>
                            )}
                          </td>
                          <td>{d.status}</td>
                          <td>
                            <FontAwesomeIcon icon={faFile} onClick={(e) => getDocumento(d)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
          </ListContent>
        </Col>
      </Row>
    </>
  );
};

const TableErrors = ({ colaboradores = [] }) => {
  return (
    <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
      <thead>
        <tr>
          {/* <th>
            <FormGroup check inline>
              <Label check inline>
                <Input
                  type="checkbox"
                  onClick={() => selectedAll(!Object.keys(selectColaborador).some((v) => selectColaborador[v]))}
                  checked={Object.keys(selectColaborador).some((v) => selectColaborador[v])}
                />
                Todo
              </Label>
            </FormGroup>
          </th> */}
          <th>Numero</th>
          <th>Nombre</th>
          <th className="text-center">Total a pagar</th>
          <th>Error</th>
        </tr>
      </thead>
      <tbody>
        {colaboradores.map((d, i) => (
          <tr key={i}>
            {/* <td>
              <FormGroup check inline>
                <Label check inline>
                  <Input type="checkbox" onClick={() => addColaborador(d.empleadoId)} checked={selectColaborador[d.empleadoId]} />
                </Label>
              </FormGroup>
            </td> */}
            <th>{d.numero}</th>
            <td>{d.nombre}</td>

            <td className="text-center">
              {(d.totales.total || 0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>

            <td>{d.message}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Card = ({ id, icon, data = [{ icon: "" }], children }) => {
  if (!data.length) return null;
  return (
    <Col id={id} md="3" style={{ color: "#404041" }}>
      <Row className="m-0" style={{ background: "#EBECF4", height: "200px" }}>
        {children !== undefined ? (
          children
        ) : (
          <>
            <Col md={{ size: 3, offset: 9 }} style={{ height: "50px" }} className="pr-4 pt-4 text-right">
              <FontAwesomeIcon size="2x" icon={data[0].icon} />
            </Col>
            {data.map((d, i) => (
              <div key={i}>
                <Col md={`12`} style={{ height: "50px", marginTop: i === 0 && data.length > 1 ? "-34px" : "0px" }}>
                  <h2 style={{ fontSize: "32px" }} className={`text-left mx-3 mt-0 mb-0`}>
                    {d.value.toLocaleString("en-US", {
                      minimunFractionDigits: 1,
                      maximumFractionDigits: 2,
                      style: d.currency ? "currency" : undefined,
                      currency: d.currency ? "USD" : undefined,
                    })}
                  </h2>
                </Col>
                <Col md={`12`}>
                  <h5 style={{ fontSize: "20px", color: "#929292", marginTop: "-6px" }} className={`text-left`}>
                    {d.title}
                  </h5>
                </Col>
              </div>
            ))}
          </>
        )}
      </Row>
    </Col>
  );
};

export default TimbrarResumen;

const resumenCalculos = (colaboradores = []) => {
  const totalEmpleados = [mapCalculos("Total Empleados", faUserFriends, colaboradores.length)];
  const totalesArray = colaboradores.map((cv) => cv.totales.total || 0);
  const pagoTotal = [
    mapCalculos(
      "Pago Total",
      faMoneyBill,
      colaboradores.reduce((a, cv) => (cv.totales.total || 0) + a, 0),
      true
    ),
  ];
  const nominaMinMax = [mapCalculos("Nómina Menor", faMoneyBill, Math.min(...totalesArray), true)];
  nominaMinMax.push(mapCalculos("Nómina Mayor", faMoneyBill, Math.max(...totalesArray), true));
  const nominaDeducciones = [mapCalculos("ISR", faMoneyBill, calculateDeduccion(colaboradores, "002"), true)];
  nominaDeducciones.push(mapCalculos("IMSS", faMoneyBill, calculateDeduccion(colaboradores, "001"), true));

  return { totalEmpleados, pagoTotal, nominaDeducciones };
};

const mapCalculos = (title, icon, value, currency = false) => ({ title, icon, value, currency });

const calculateDeduccion = (colaborador = [], clave) => {
  let value = 0;
  colaborador.map((c) => (value += (c.deducciones.ordinarias.find((cd) => cd.codigo === clave) || {}).monto || 0));
  return value;
};
