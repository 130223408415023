import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Button, Col, FormGroup } from 'reactstrap';
import colors from '../../../constans/colors';
import TableCommon from './TableUtilitarios';
import { TableContainer, InfoHeader, InfoHeaderBlue } from './styled';
import TableBody from './TableBody';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TablaUtilitario = React.memo(({ disabled = false }) => {
  const history = useHistory()
  const { listaUtilitarios = [] } = useSelector((state) => state.utilitario);
  const { permisos = {} } = useSelector((state) => state.user);

  const total = listaUtilitarios
    .filter(x =>x.active === true && x.status === 'asignado')
    .reduce((sum, value) => (typeof value.valorFactura == "number" ? sum + value.valorFactura : sum), 0);

  function numberWithCommas(valor) {
    var parts = valor.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return (
    <Form>
      <FormGroup row style={{ marginLeft: '30px' }}>
        <Col sm={2}>
          <InfoHeader>Valor total:</InfoHeader>
        </Col>
        <Col sm={1}>
          <InfoHeaderBlue>${numberWithCommas(total)}</InfoHeaderBlue>
        </Col>
      </FormGroup>
      <TableContainer style={{ marginLeft: '30px', textAlign: 'left', marginTop: '0px' }}>
        <TableCommon
          keysAceepted={['Nombre', 'Serie', 'Estatus', 'Valor Factura']}
          striped
        >
          <TableBody listaUtilitarios={listaUtilitarios} />
        </TableCommon>
        {
          !disabled && <Button onClick={() => {
            history.push('/agregar-utilitario')
          }} style={{ background: colors.secondary, width: '203px', height: '51px', borderRadius: '4px' }}
          hidden={permisos.colaboradorUtilitario !== 'write'}
          ><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Agregar</Button>
        }
      
      </TableContainer>
    </Form>
  );
});

export default TablaUtilitario;
