import thunk from "redux-thunk";

export const BUSCAR_COLABORADOR = "BUSCAR_COLABORADOR";
export const CREANDO_COLABORADOR = "CREANDO_COLABORADOR";
export const CREANDO_COLABORADOR_EXITO = "CREANDO_COLABORADOR_EXITO";
export const CREANDO_COLABORADOR_ERROR = "CREANDO_COLABORADOR_ERROR";
export const MODIFICANDO_COLABORADOR_EXITO = "MODIFICANDO_COLABORADOR_EXITO";
export const AGREGA_INFORMACIONLUGARTRABAJO = "AGREGA_INFORMACIONLUGARTRABAJO";

export const AGREGAR_SALUD_LABORAL = "AGREGAR_SALUD_LABORAL";
export const EDITAR_SALUD_LABORAL = "EDITAR_SALUD_LABORAL";
export const EDITAR_SALUD_LABORAL_EXITO = "EDITAR_SALUD_LABORAL_EXITO";
export const EDITAR_SALUD_LABORAL_ERROR = "EDITAR_SALUD_LABORAL_ERROR";

export const CONDICIONES_LABORALES = "CONDICIONES_LABORALES";
export const UPDATE_FORM_FINISHED = "UPDATE_FORM_FINISHED";
export const CLEAN_STATE_COLABORADOR = "CLEAN_STATE_COLABORADOR";
export const OBTENIENDO_LISTA_COLABORADORES = "OBTENIENDO_LISTA_COLABORADORES";
export const OBTENIENDO_COLABORADORESXEMPRESA = "OBTENIENDO_COLABORADORESXEMPRESA";
export const BAJA_COLABORADOR = "BAJA_COLABORADOR";
export const BAJA_COLABORADOR_EXITO = "BAJA_COLABORADOR_EXITO";
export const BAJA_COLABORADOR_ERROR = "BAJA_COLABORADOR_ERROR";
export const COMPLETAR_COLABORADOR = "COMPLETAR_COLABORADOR";
export const EXPEDIENTE_COLABORADOR = "EXPEDIENTE_COLABORADOR";
export const ELIMINAR_COLABORADOR = "ELIMINAR_COLABORADOR";
export const LISTA_COLABORADORES_FINIQUITOS = "LISTA_COLABORADORES_FINIQUITOS";
export const VALIDAR_RFC = "VALIDAR_RFC";

const initialState = {
  loading: false,
  error: null,
  formsFinished: [],
  listaColaboradores: [],
  listaForFiniquitos:[]
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case BUSCAR_COLABORADOR:
      return { ...state, listaColaboradores: [payload] };
    case CREANDO_COLABORADOR:
      return { ...state, loading: true };

    case CREANDO_COLABORADOR_EXITO:
      return {
        ...state,
        loading: false,
        ...payload,
      };

    case MODIFICANDO_COLABORADOR_EXITO:
      return {
        ...state,
        loading: false,
        ...payload,
      };

    case UPDATE_FORM_FINISHED:
      return {
        ...state,
        formsFinished: [...state.formsFinished, ...payload],
      };
    case CREANDO_COLABORADOR_ERROR:
      return { ...state, loading: false, error: payload };

    case AGREGA_INFORMACIONLUGARTRABAJO:
      return {
        ...state,
        condicionesLaborales: {
          ...state.condicionesLaborales,
          lugarTrabajo: { ...payload },
        },
      };
    case CONDICIONES_LABORALES:
      return {
        ...state,
        condicionesLaborales: {
          ...state.condicionesLaborales,
          ...payload,
        },
      };
    case AGREGAR_SALUD_LABORAL:
      return {
        ...state,
        saludLaboral: {
          ...state.saludLaboral,
          ...payload,
        },
      };
    case EDITAR_SALUD_LABORAL:
      return { ...state, loading: true };
    case EDITAR_SALUD_LABORAL_EXITO:
      return { ...state, loading: false };
    case EDITAR_SALUD_LABORAL_ERROR:
      return { ...state, loading: false };
    case OBTENIENDO_LISTA_COLABORADORES:
      return { ...state, loading: false, listaColaboradores: [...payload] };
    case LISTA_COLABORADORES_FINIQUITOS:
      return { ...state, loading: false, listaForFiniquitos: [...payload] };
    case CLEAN_STATE_COLABORADOR:
      return initialState;
    case OBTENIENDO_COLABORADORESXEMPRESA:
      return { ...state, loading: false, listaColaboradores: [...payload] };
    case BAJA_COLABORADOR:
      return { ...state, loading: true };
    case BAJA_COLABORADOR_EXITO:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case BAJA_COLABORADOR_ERROR:
      return { ...state, loading: false, error: payload };

    case COMPLETAR_COLABORADOR:
      return { ...state, loading: false, ...payload };
    case EXPEDIENTE_COLABORADOR:
      return { ...state, loading: false, ...payload };

    case ELIMINAR_COLABORADOR:
        return {...state, loading: false, ...payload};

    default:
      return state;
  }
};

export const buscarColaborador = (payload) => ({
  type: BUSCAR_COLABORADOR,
  payload,
});

export const creandoColaboradorStart = () => ({
  type: CREANDO_COLABORADOR,
});

export const creandoColaboradorSuccees = (payload) => ({
  type: CREANDO_COLABORADOR_EXITO,
  payload,
});

export const creandoColaboradorError = (payload) => ({
  type: CREANDO_COLABORADOR_ERROR,
  payload,
});

export const agregaLugarTrabajo = (payload) => ({
  type: AGREGA_INFORMACIONLUGARTRABAJO,
  payload,
});
export const agregaCondicionesLaborales = (payload) => ({
  type: CONDICIONES_LABORALES,
  payload,
});

export const modificandoColaboradorExito = (payload) => ({
  type: MODIFICANDO_COLABORADOR_EXITO,
  payload,
});

export const resetState = () => ({
  type: CLEAN_STATE_COLABORADOR,
});

export const updateFormFinished = (payload) => ({
  type: UPDATE_FORM_FINISHED,
  payload,
});

export const getListaColaboradoresExito = (payload) => ({
  type: OBTENIENDO_LISTA_COLABORADORES,
  payload,
});

export const getListaColaboradoresForFiniquitos = (payload) => ({
  type: LISTA_COLABORADORES_FINIQUITOS,
  payload,
});

export const agregarSaludLaboral = (payload) => ({
  type: AGREGAR_SALUD_LABORAL,
  payload,
});
export const editarSaludLaboral = (payload) => ({
  type: EDITAR_SALUD_LABORAL,
  payload,
});
export const editarSaludLaboralExito = (payload) => ({
  type: EDITAR_SALUD_LABORAL_EXITO,
  payload,
});
export const editarSaludLaboralError = (payload) => ({
  type: EDITAR_SALUD_LABORAL_ERROR,
  payload,
});
export const getColaboradoresListSucess = (payload) => ({
  type: OBTENIENDO_COLABORADORESXEMPRESA,
  payload,
});
export const bajaColaborador = (payload) => ({
  type: BAJA_COLABORADOR,
  payload,
});
export const bajaColaboradorExito = (payload) => ({
  type: BAJA_COLABORADOR_EXITO,
  payload,
});
export const bajaColaboradorError = (payload) => ({
  type: BAJA_COLABORADOR_ERROR,
  payload,
});
export const completarColaborador = (payload) => ({
  type: COMPLETAR_COLABORADOR,
  payload,
});
export const expedienteColaborador = (payload) => ({
  type: EXPEDIENTE_COLABORADOR,
  payload,
});

export const buscarColaboradorPorNombre = (searchText, filter = "") => async (dispatch, getState, { axios }) => {
  if (searchText == "") {
    getListaColaboradores(filter)(dispatch, getState, { axios });
  } else if (filter === "") {
    const lista = getState().colaborador.listaColaboradores.filter(
      (c) =>
        c.informacionPersonal.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
        c.informacionPersonal.apellidoPaterno.toLowerCase().includes(searchText.toLowerCase()) ||
        c.informacionPersonal.apellidoMaterno.toLowerCase().includes(searchText.toLowerCase()) ||
        c.condicionesLaborales.puesto.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(getListaColaboradoresExito(lista));
  } else {
    const lista = getState().colaborador.listaColaboradores.filter(
      (c) =>
        c.informacionPersonal.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
        c.informacionPersonal.apellidoPaterno.toLowerCase().includes(searchText.toLowerCase()) ||
        c.informacionPersonal.apellidoMaterno.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(getListaColaboradoresExito(lista));
  }
};

export const createColaborador = (dataColaborador) => async (dispatch, getState, { axios }) => {
  const { sucursal, empresa, departamento, foto, ...rest } = dataColaborador;
  const colaborador = {
    pertenencia: {
      sucursal,
      empresa,
      departamento,
    },
    informacionPersonal: {
      ...rest,
    },
  };
  dispatch(creandoColaboradorStart());
  dispatch(updateFormFinished(["creado"]));
  try {
    const Formdata = new FormData();
    Formdata.append("foto", foto[0]);
    Formdata.append("colaborador", JSON.stringify(colaborador));
    const {
      data: { resultado },
    } = await axios.post("/colaborador/registrar", Formdata, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(creandoColaboradorSuccees({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};

export const updateColaboradorCondicionesLaborales = () => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  dispatch(updateFormFinished(["laboral"]));
  try {
    const { _id, condicionesLaborales } = getState().colaborador;
    const body = {
      idColaborador: _id,
      condicionesLaborales: condicionesLaborales,
    };
    const {
      data: { resultado },
    } = await axios.put("/colaborador/modificar-colaborador", body, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    dispatch(modificandoColaboradorExito({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};

export const updateColaborador = (dataUpdate) => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  //dispatch(updateFormFinished(['laboral']));
  console.log(dataUpdate)
  try {
    const { _id } = getState().colaborador;
    const body = {
      idColaborador: _id,
      ...dataUpdate,
    };
    const {
      data: { resultado },
    } = await axios.put("/colaborador/modificar-colaborador", body, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    dispatch(modificandoColaboradorExito({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};

export const getListaColaboradores = (filter = "") => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/colaborador/obtener_colaboradores?estatus=${filter}`, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    dispatch(getListaColaboradoresExito(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};

export const updateInformacionPersonal = (dataColaborador) => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  try {
    const { _id } = getState().colaborador;
    const { departamento, empresa, sucursal, foto, ...rest } = dataColaborador;
    const colaborador = {
      pertenencia: {
        departamento,
        empresa,
        sucursal
      },
      informacionPersonal: {
        ...rest,
      },
    };
    const Formdata = new FormData();
    Formdata.append("foto", foto[0]);
    Formdata.append("colaborador", JSON.stringify(colaborador));
    const {
      data: { resultado },
    } = await axios.put(`colaborador/modificar-informacionPersonal/${_id}`, Formdata, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(creandoColaboradorSuccees({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};
export const colaboradorPorEmpresaAction = (idEmpresa) => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/colaborador/obtener_colaboradores/${idEmpresa}`, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    const list = resultado.map(({ _id, informacionPersonal: { nombre, apellidoPaterno, apellidoMaterno } }) => {
      return {
        value: _id,
        label: `${nombre} ${apellidoPaterno} ${apellidoMaterno}`,
      };
    });

    dispatch(getColaboradoresListSucess(list));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};
export const getListaColaboradoresByEmpresa = (idEmpresa, filter = "", toDropDown = true) => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/colaborador/obtener_colaboradores/${idEmpresa}?estatus=${filter}`, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    let colaboradorList = [];
    if (toDropDown)
      colaboradorList =
        resultado.length > 0
          ? resultado.map(({ _id, informacionPersonal: { nombre, apellidoMaterno, apellidoPaterno } }) => {
              return {
                value: _id,
                label: `${apellidoPaterno} ${apellidoMaterno}, ${nombre} `,
              };
            })
          : [];
    else {
      colaboradorList = resultado;
    }
    dispatch(getListaColaboradoresExito(colaboradorList));
    dispatch(getListaColaboradoresForFiniquitos(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};

export const dropColaborador = (dataBajaColaborador) => async (dispatch, getState, { axios }) => {
  
  try {
    //dispatch(bajaColaborador());
    const { finiquitoAutoridad, finiquitoPrivado, bajaImss, renuncia, _id, ...rest } = dataBajaColaborador;
   
    const formData = new FormData();
    formData.append("finiquitoAutoridad", finiquitoAutoridad[0]);
    formData.append("finiquitoPrivado", finiquitoPrivado[0]);
    formData.append("bajaImss", bajaImss[0]);
    formData.append("renuncia", renuncia[0]);
    formData.append("bajaColaborador", JSON.stringify(rest));
    const {
      data: { resultado },
    } = await axios.post(`/colaborador/baja/${_id}`, formData, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(bajaColaboradorExito({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(bajaColaboradorError({ error: data }));
    throw data;
  }
};

export const fecthCompletarColaborador = () => async (dispatch, getState, { axios }) => {
  try {
    const { _id } = getState().colaborador;
    const {
      data: { resultado },
    } = await axios.patch(
      `/colaborador/completado/${_id}`,
      {},
      {
        headers: {
          token: localStorage.getItem("pv2token"),
        },
      }
    );
    dispatch(completarColaborador({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(bajaColaboradorError({ error: data }));
    throw data;
  }
};

export const fecthColaborador = (id) => async (dispatch, getState, { axios }) => {
  try {
    const {
      data: { resultado },
    } = await axios.get(`/colaborador/obtener_colaborador/${id}`, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    console.log(resultado);
    dispatch(completarColaborador({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(bajaColaboradorError({ error: data }));
    throw data;
  }
};

export const fecthExpedienteColaborador = (objeto, archivo) => async (dispatch, getState, { axios }) => {
  try {
    const { _id } = getState().colaborador;
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("datos", JSON.stringify(objeto));
    const {
      data: { resultado },
    } = await axios.post(`/colaborador/expediente/${_id}`, formData, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(expedienteColaborador({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(bajaColaboradorError({ error: data }));
    throw data;
  }
};

export const fetchAgregarSaludLaboral = (saludLaboral) => async (dispatch, getState, { axios }) => {
  try {
    const { _id } = getState().colaborador;
    const body = {
      saludLaboral,
    };
    console.log(body)
    const {
      data: { resultado },
    } = await axios.post(`/colaborador/salud_laboral/${_id}`, body, {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    });
    dispatch(modificandoColaboradorExito({ ...resultado }));
  } catch (error) {
    // const { response } = error;
    // const { request, ...errorObject } = response;
    // const { data } = errorObject;
    dispatch(bajaColaboradorError({ error }));
    throw error;
  }
};

export const updateSaludLaboral = () => async (dispatch, getState, { axios }) => {
  dispatch(creandoColaboradorStart());
  try {
    const { saludLaboral, _id } = getState().colaborador;
    // const isNotValidRequest = Object.keys(saludLaboral).length === 0 && saludLaboral.constructor === Object;
    // if (isNotValidRequest) return;
    // const { archivoExamenMedico = [], ...rest } = saludLaboral;
    // const Formdata = new FormData();
    // Formdata.append("foto", archivoExamenMedico[0] ? archivoExamenMedico[0] : "");
    // Formdata.append("colaborador", JSON.stringify(rest));
    const {
      data: { resultado },
    } = await axios.post(`/colaborador/salud_laboral/${_id}`, saludLaboral, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(modificandoColaboradorExito({ ...resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(creandoColaboradorError({ error: data }));
    throw data;
  }
};

export const eliminarColaboradorAction = (_id) => async (dispatch, getState, {axios}) => {
  try {
    const { data: { resultado } } = await axios.delete(`/colaborador/${_id}`, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(modificandoColaboradorExito({... resultado}));
  } catch (error) {
    dispatch(creandoColaboradorError({error: error.response.data}));
  }
};
