import React, { useCallback, useEffect, useState } from "react"
import { Col, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { demandaSchema } from "../../../constans/yupSchemas";
import CustomSelect from "../../ui/CustomSelect";
import SweetAlert from "../../ui/SweetAlet";
import { fetchEmpresasToDropdown } from "../../../store/empresasDuck";
import { getListaColaboradoresByEmpresa } from '../../../store/colaboradorDucks';
import { crearDemandaAction } from "../../../store/demandaDuck";
import moment from "moment";
import { HeaderStyle } from "../../../components/ui/styles/StyleNomina";
import { CancelButton } from '../../ui/styles/styles';
import estados from '../../../constans/estados';
import CurrencyFormat from 'react-currency-format';
import InputMultiFile from '../../ui/InputMultiFile';
import axios from '../../../services/axios';
import BotonCargando from '../../BotonCargando';
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

const NuevaDemanda = () => {
	const { register, handleSubmit, errors, control, setValue } = useForm({
		resolver: yupResolver(demandaSchema)
	})
	const [err, setErr] = useState({});
	const history = useHistory();
	const dispatch = useDispatch();
	const [salario, setSalario] = useState({ salarioDiario: '' });
	const [depto, setDepto] = useState('');
	const [pues, setPues] = useState('');
	const [jefe, setJefe] = useState('');
	const [notEmail, setNotEmail] = useState(false);
	const { salarioDiario } = salario;
	const { empresas = [] } = useSelector(state => state.empresas)
	const { listaColaboradores = [] } = useSelector((state) => state.colaborador);
	const headers = {
		headers: {
			token: localStorage.getItem("pv2token"),
		},
	};
	const [saving, setSaving] = useState(false);
	
	const getFiles = (files) => {
		setValue('archivos', files);
	};

	const registraDemanda = useCallback(async (objDemanda) => {
		try {
			dispatch(crearDemandaAction(objDemanda))
			//SweetAlert({icon: 'success', title: 'Creado'});
			history.push('/dashboard/juridico/demandas')
		} catch (error) {
			await SweetAlert({ icon: 'error', title: 'Error!', text: error });
		}
	}, [dispatch])

	const getEmpresas = useCallback(async () => {
		try {
			dispatch(fetchEmpresasToDropdown())

		} catch (error) { }
	},
		[dispatch]
	);

	useEffect(() => {
		register({ name: 'archivos' }, { required: false });
	}, [register])

	const onChange = ({ target }) => {
		if (!target.value) return;
		if (listaColaboradores.length > 0) {
			setPues('');
			setSalario({ salarioDiario: '' });
			setDepto('');
		}
		try {
			dispatch(getListaColaboradoresByEmpresa(target.value));
		} catch (error) { }
	};
	const submit = async (data) => {
		try {
			setSaving(true);

			const { empresa, fechaDemanda, domicilioTrabajo, puesto, horario, email,
				jefeInmediato, fechaDespido, fechaActualizacion, noExpediente, estado, ciudad, autoridadLaboral, colaborador,
				demandado, abogado, departamento, correo, telefono, celular, direccion, antecedentes, estadoActual, audiencias, archivos } = data;
			const objDemanda = {
				empresa: empresa,
				fechaDemanda: fechaDemanda,
				domicilioTrabajo: domicilioTrabajo,
				puesto: puesto,
				salario: salarioDiario,
				horario: horario,
				email: email,
				jefeInmediato: jefeInmediato,
				fechaDespido: moment(new Date(fechaDespido)).format('x'),
				fechaActualizacion: moment(new Date(fechaActualizacion)).format('x'),
				noExpediente: noExpediente,
				departamento: departamento,
				estado: estado,
				ciudad: ciudad,
				autoridadLaboral: autoridadLaboral,
				colaborador: colaborador,
				demandado: demandado,
				contraparte: {
					abogado: abogado,
					correo: correo,
					telefono: telefono,
					celular: celular,
					direccion: direccion
				},
				antecedentes: antecedentes,
				estadoActual: estadoActual,
				audiencias: audiencias,
				archivos: archivos
			}

			registraDemanda(objDemanda);

			await SweetAlert({
        icon: 'success',
        title: 'Demanda agregada correctamente',
				showConfirmButton: false,
				timer: 2500
      });
			
      history.goBack();
		} catch (error) {
			await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
			console.log(error);
		} finally {
			setSaving(false);
		}
	}
	
	const handleChange = async ({ target }) => {
		const id = target.value;
		const { data } = await axios.get(`${api}/colaborador/obtener_colaborador/${id}`, headers)
		const { resultado } = data;
		setSalario({ salarioDiario: resultado.InformacionNomina.salarioDiario });
		setPues(resultado.condicionesLaborales.puesto);
		setDepto(resultado.pertenencia.nombreDepartamento);
		setJefe(resultado.condicionesLaborales.jefeInmediato);
	};

	useEffect(() => {
		if (Object.keys(errors).length) setErr(errors);
		getEmpresas()
	}, [dispatch, errors])
	return (
		<>
			<HeaderStyle style={{ marginBottom: 50 }}>
				<h1>Nueva Demanda</h1>
				<h3>Agrega una nueva demanda</h3>
			</HeaderStyle>
			<Form onSubmit={handleSubmit(submit)}>
				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Datos de la Empresa
					</h5>
				</FormGroup>
				<FormGroup row>
					<Label for='empresa' sm={2}>Empresa relacionada*</Label>
					<Col sm={10}>
						<CustomSelect
							type='select'
							name='empresa'
							id='empresa'
							control={control}
							invalid={errors.empresa ? true : false}
							innerRef={register({ required: true })}
							options={empresas}
							formText=''
							onChange={onChange}
							register={register({ required: true })}
						/>
						{err.empresa && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								La Empresa es requerida
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='domicilioTrabajo' sm={2}>
						Domicilio del Trabajo*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='domicilioTrabajo'
							placeholder='Ingrese el domicilio'
							innerRef={register()}
						/>
						{err.domicilioTrabajo && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Domicilio del trabajo es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Datos del colaborador
					</h5>
				</FormGroup>
				<FormGroup row>
					<Label sm={2}>Colaborador</Label>
					<Col sm={10}>
						<CustomSelect
							control={control}
							options={listaColaboradores}
							disabled={listaColaboradores.length > 0 ? false : true}
							type='select'
							name='colaborador'
							innerRef={register()}
							onChange={handleChange}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label sm={2}>Salario</Label>
					<Col sm={10}>
						<CurrencyFormat
							fixedDecimalScale={true}
							decimalScale={2}
							className='inputMoneda'
							thousandSeparator={true}
							value={salarioDiario}
							name='salario'							
							prefix={'$'}
							onValueChange={(values) => {
								const { formattedValue, value } = values;
								setSalario({ salarioDiario: value })
							}}
							innerRef={register()}
							placeholder='Salario'
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label sm={2}>Departamento</Label>
					<Col sm={10}>
						<Input
							type='text'
							defaultValue={depto}
							name='departamento'
							innerRef={register()}
							placeholder='Departamento'
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label sm={2}>Puesto</Label>
					<Col sm={10}>
						<Input
							type='text'
							defaultValue={pues}
							name='puesto'
							innerRef={register()}
							placeholder='Puesto'
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='jefeInmediato' sm={2}>
						Jefe Inmediato*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='jefeInmediato'
							defaultValue={jefe}
							placeholder='Ingrese el nombre del jefe inmediato'
							innerRef={register()}
						/>
						{err.jefeInmediato && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Jefe inmediato es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='fechaDespido' sm={2}>
						Fecha de Despido*
					</Label>
					<Col sm={3}>
						<Controller
							name='fechaDespido'
							control={control}
							innerRef={register()}
							render={({ onChange, value }) => (
								<DatePicker
									selected={value}
									onChange={onChange}
									dateFormat={'dd/MM/yyyy'}
									placeholderText='Ingrese la fecha de despido'
								/>
							)}
						/>
						{err.fechaDespido && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								La Fecha de despido es requerida
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Datos de la Demanda
					</h5>
				</FormGroup>
				<FormGroup row>
					<Label for='noExpediente' sm={2}>
						N° de expediente*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='noExpediente'
							placeholder='Ingrese el n° de expediente'
							innerRef={register()}
						/>
						{err.noExpediente && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Numero de expediente es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='fechaDemanda' sm={2}>
						Fecha Demanda*
					</Label>
					<Col sm={3}>
						<Controller
							name='fechaDemanda'
							control={control}
							innerRef={register()}
							render={({ onChange, value }) => (
								<DatePicker
									selected={value}
									onChange={onChange}
									dateFormat={'dd/MM/yyyy'}
									placeholderText='Ingrese la fecha de demanda'
								/>
							)}
						/>
						{err.fechaDemanda && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								La Fecha de la demanda es requerida
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='horario' sm={2}>
						Horario*
					</Label>
					<Col sm={3}>
						<Input
							type='time'
							name='horario'
							placeholder='Ingrese el horario'
							innerRef={register()}
						/>
						{err.horario && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Horario es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='demandado' sm={2}>
						Demandado*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='demandado'
							placeholder='Ingrese el demandado'
							innerRef={register()}
						/>
						{err.demandado && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Demandado es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='estado' sm={2}>
						Estado*
					</Label>
					<Col sm={10}>
						<CustomSelect
							control={control}
							options={estados}
							type='select'
							name='estado'
							placeholder='Ingrese el estado'
							innerRef={register()}
						/>
						{err.estado && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Estado es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='ciudad' sm={2}>
						Ciudad*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='ciudad'
							placeholder='Ingrese la ciudad'
							innerRef={register()}
						/>
						{err.ciudad && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								La Ciudad es requerida
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='autoridadLaboral' sm={2}>
						Autoridad Laboral*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='autoridadLaboral'
							placeholder='Ingrese la autoridad Laboral'
							innerRef={register()}
						/>
						{err.autoridadLaboral && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								La Autoridad laboral es requerida
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Datos de la contraparte
					</h5>
				</FormGroup>
				<FormGroup row>
					<Label for='abogado' sm={2}>
						Abogado*
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='abogado'
							placeholder='Ingrese el abogado'
							innerRef={register()}
						/>
						{err.abogado && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El abogado es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='correo' sm={2}>
						Correo
					</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='correo'
							placeholder='Ingrese el correo del abogado'
							innerRef={register()}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='telefono' sm={2}>
						Teléfono
					</Label>
					<Col sm={10}>
						<Input
							type='number'
							name='telefono'
							placeholder='Ingrese el teléfono'
							innerRef={register()}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='celular' sm={2}>
						Celular
					</Label>
					<Col sm={10}>
						<Input
							type='number'
							name='celular'
							placeholder='Ingrese el teléfono'
							innerRef={register()}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='direccion' sm={2}>
						Dirección
					</Label>
					<Col sm={10}>
						<Input
							type='string'
							name='direccion'
							placeholder='Ingresa la dirección'
							innerRef={register()}
						/>
					</Col>
				</FormGroup>

				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Atención
					</h5>
				</FormGroup>
				<FormGroup row>
					<Label sm={2}>Representante</Label>
					<Col sm={10}>
						<Input
							type='text'
							name='representante'
							innerRef={register()}
							placeholder='Representante'
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label sm={2}>Notificar</Label>
					<Col sm={{ offset: 1, size: 1 }} style={{ marginLeft: 0 }}>
						<CustomInput
							innerRef={register}
							type="radio"
							label="SI"
							value={true}
							name="notificar"
							onClick={() => setNotEmail(true)}
						/>
					</Col>
					<Col sm={1}>
						<CustomInput
							innerRef={register}
							type="radio"
							label="NO"
							value={false}
							name="notificar"
							onClick={() => setNotEmail(false)}
						/>
					</Col>
				</FormGroup>
				{notEmail ?
					<FormGroup row>
						<Label sm={2}>Email</Label>
						<Col sm={10}>
							<Input
								type='text'
								name='email'
								innerRef={register}
								placeholder='Email'
							/>
						</Col>
					</FormGroup> : null}
				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Antecedentes
					</h5>
				</FormGroup>
				<FormGroup row>
					<Label for='antecedentes' sm={2}>
						Antecedentes*
					</Label>
					<Col sm={10}>
						<Input
							type='textarea'
							name='antecedentes'
							placeholder='Ingrese los antecedentes'
							innerRef={register()}
						/>
						{err.antecedentes && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								Los Antecedentes son requeridos
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='estadoActual' sm={2}>
						Estado actual*
					</Label>
					<Col sm={10}>
						<Input
							type='textarea'
							name='estadoActual'
							placeholder='Ingrese el estado actual'
							innerRef={register()}
						/>
						{err.estadoActual && (
							<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
								El Estado actual es requerido
							</span>
						)}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for='fechaActualizacion' sm={2}>
						Fecha de Recordatorio
					</Label>
					<Col sm={3}>
						<Controller
							name='fechaActualizacion'
							control={control}
							innerRef={register()}
							render={({ onChange, value }) => (
								<DatePicker
									selected={value}
									onChange={onChange}
									dateFormat={'dd/MM/yyyy'}
									placeholder='Ingrese la fecha de actualizacion'
								/>
							)}
						/>
						{/* {err.fechaActualizacion && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							La Fecha de recordatorio es requerida
						</span>
					)} */}
					</Col>
				</FormGroup>

				<FormGroup row>
					<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
						Adjuntar archivos
					</h5>
				</FormGroup>
				<FormGroup row>
					<Col sm={10}>
						<InputMultiFile name='archivos' getFiles={getFiles} />
					</Col>
				</FormGroup>

				<FormGroup row>
					<Col sm={{ offset: 6, size: 3 }}>
						<CancelButton
							onClick={() => {
								history.replace('/dashboard/juridico/demandas');
							}}
							style={{ marginLeft: 'auto' }}>
							Cancelar
						</CancelButton>
					</Col>
					<Col sm={3}>
						<BotonCargando 
							cargando={saving}
							disabled={saving}
							texto='Guardar'
							type='submit'
						/>
					</Col>
				</FormGroup>
			</Form>
		</>
	)
}

export default NuevaDemanda;