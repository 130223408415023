import React from 'react';
import { Stage, Layer, Line, Text, Group, Circle } from 'react-konva';
import { scaleLinear, max, min } from 'd3';
import _ from 'lodash';

const linearFactoryX = (col, filter, v) => scaleLinear().domain([min(col, d => d[filter]), max(col, d => d[filter])]).range([0, v]).nice();

const linearFactoryY = (col, filter, v) => scaleLinear().domain([min(col, d => d[filter]), max(col, d => d[filter])]).range([v, 0]).nice();

const ScatterPlot = ({ width, height, xOffset = 0, yOffset = 0, filtroX, filtroY, colaboradores }) => {
    const yScale = linearFactoryY(colaboradores, filtroY, height - yOffset);
    const xScale = linearFactoryX(colaboradores, filtroX, width - xOffset);

    return (
        <Stage width={width} height={height}>
            <Layer>
                <Text
                    text={filtroY.charAt(0).toUpperCase() + filtroY.slice(1)}
                    x={10}
                    y={(height - yOffset)}
                    width={((height - yOffset))}
                    fontSize={16}
                    fill="#8E8883"
                    rotation={-90}
                    align="center"
                />
                <Text
                    text={filtroX.charAt(0).toUpperCase() + filtroX.slice(1)}
                    x={70}
                    y={(height - 20)}
                    width={((width - xOffset))}
                    fontSize={16}
                    fill="#8E8883"
                    align="center"
                    verticalAlign="center"
                />
                <Group x={70} y={15}>
                    {
                        colaboradores.map((colaborador, i) => {
                            return (
                                <Circle
                                    key={`circle-${i}`}
                                    x={xScale(colaborador[filtroX])}
                                    y={yScale(colaborador[filtroY])}
                                    fill={'rgb(43, 56, 143)'}
                                    radius={5}
                                    shadowBlur={1}
                                    shadowColor="white"
                                    stroke="white"
                                    strokeWidth={1}
                                />
                            )
                        })
                    }
                </Group>
                {
                    xScale.ticks().map((v, i) => (
                        <Group key={`axisX-${i}`} x={70} y={10}>
                            <Line
                                width={2}
                                x={xScale(v)}
                                y={height - yOffset + 5}
                                stroke="rgb(43, 56, 143)"
                                points={[0, 0, 0, 10]}
                            />
                            <Text text={`${v / 1000}K`} x={xScale(v) - 12} y={height - yOffset + 20} fontSize={14} fill="#8E8883" />
                        </Group>
                    ))
                }
                {
                    yScale.ticks().map((v, i) => (
                        <Group key={`axisY-${i}`} x={0} y={15}>
                            <Line
                                width={2}
                                x={60}
                                y={yScale(v)}
                                stroke="rgb(43, 56, 143)"
                                points={[0, 0, 10, 0]}
                            />
                            <Text text={v} x={40} y={yScale(v) - 6} fontSize={14} fill="#8E8883" />
                        </Group>
                    ))
                }
            </Layer>
        </Stage>
    )
}

export default ScatterPlot;
