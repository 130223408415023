import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active', // default to "disabled"
})`
  display: flex;
  justify-content: center;
  flex-direction: row;
  list-style-type: none;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li {
    min-width: 30px;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #2B388F;
    border-color: transparent;
    color: white;
    min-width: 30px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const Paginacion = ({pagina, totalPaginas, cambioPagina}) => {
  if(!totalPaginas) return null;

  return (
    <MyPaginate
      nextLabel="Siguiente"
      previousLabel="Anterior"
      pageCount={totalPaginas}
      onPageChange={_page => cambioPagina(_page.selected + 1)}
      forcePage={pagina -1}
    />
  )
}

export default Paginacion;