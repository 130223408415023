import React from 'react';
import './Search.css'

const Search = ({...rest}) => {
  return (
    <div>
      <input
        {...rest}
        style={{
          border: '1px #979797 solid',
          fontWeight: '600'
        }}
        className='search-control-with-border'
      />
    </div>
  );
};

export default Search;
