import React, { useState } from "react";
import PrestacionForm from "./PrestacionForm";
import { useEffect } from "react";
import axios from "../../../services/axios";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { StyleInputs } from "../../empresa/styles/styles";
import sweet from "sweetalert2";
import BotonCargando from "../../BotonCargando";
import SweetAlert from "../../ui/SweetAlet";

export default ({disabled = false}) => {
  const { _id, pertenencia = {} } = useSelector((state) => state.colaborador);
  const { permisos = {} } = useSelector((state) => state.user);

  const headers = {
    headers: {
      token: localStorage.getItem("pv2token")
    }
  };
  const [prestacionesOr, setPrestacionesOr] = useState({ percepciones: [], deducciones: [] });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getPrestacionesOrdinarias();
  }, []);

  const getPrestacionesOrdinarias = async () => {
    // /nomina/paquetes/prestaciones/ordinarias/5ffbc923da5f9508b90d474c/5ffbd2aef740fd20a970e2d6/
    const { data } = await axios.get(`/nomina/colaborador/prestaciones/ordinarias/${pertenencia.empresa}/${_id}/`, headers);
    setPrestacionesOr(data.response);
  };
  const onGuardar = async () => {
    // /nomina/paquetes/prestaciones/ordinarias/5ffbc923da5f9508b90d474c/5ffbd2aef740fd20a970e2d6/
    let montoDeducEmpty = prestacionesOr['deducciones'].findIndex((p) => p.monto === '' || p.monto === null);
    let montoPerceEmpty = prestacionesOr['percepciones'].findIndex((p) => p.monto === '' || p.monto === null);
    if (montoDeducEmpty !== -1 || montoPerceEmpty !== -1) {
      await sweet.fire("Guardar", "El monto es requerido", "warning");
    } else {
      try {
        setSaving(true);

        await axios.post(`/nomina/colaborador/prestaciones/ordinarias/${pertenencia.empresa}/${_id}/`, prestacionesOr, headers);
        await sweet.fire("Guardar", "Se actualizo correctamente", "success");
      } catch(error) {
        await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
        console.log(error);
      } finally {
        setSaving(false);
      }
    }
  };
  const addPrestacion = (item, type) => {
    let idx = prestacionesOr[type].findIndex((p) => p.codigo === item.codigo);
    if (idx >= 0) {
      prestacionesOr[type][idx] = item;
    } else {
      prestacionesOr[type].push(item);
    }
    setPrestacionesOr({ ...prestacionesOr });
  };
  const onDeletePrestacion = (i, type) => {
    prestacionesOr[type] = prestacionesOr[type].filter((p, id) => id !== i);
    setPrestacionesOr({ ...prestacionesOr });
  };
  const onChangeMonto = (value, idx, type) => {
    prestacionesOr[type][idx].monto = value;
    setPrestacionesOr({ ...prestacionesOr });
  };

  return (
    <>
      <PrestacionForm
        disabled={disabled}
        label="Percepciones"
        type="percepciones"
        prestacionesOrdinarias={prestacionesOr.percepciones}
        colaboradorId={_id}
        empresaId={pertenencia.empresa}
        addPrestacion={addPrestacion}
        onDeletePrestacion={onDeletePrestacion}
        onChange={onChangeMonto}
      />
      <PrestacionForm
        disabled={disabled}
        label="Deducciones"
        type="deducciones"
        prestacionesOrdinarias={prestacionesOr.deducciones}
        colaboradorId={_id}
        empresaId={pertenencia.empresa}
        addPrestacion={addPrestacion}
        onDeletePrestacion={onDeletePrestacion}
        onChange={onChangeMonto}
      />
      {
        !disabled && <Row hidden={permisos.colaboradorOrdinarias !== 'write'}>
          <StyleInputs>
            <Col className="text-right mt-4">
              <BotonCargando
                cargando={saving}
                disabled={saving}
                texto='Guardar'
                onClick={onGuardar}
              />
            </Col>
          </StyleInputs>
        </Row>
      }
     
    </>
  );
};
