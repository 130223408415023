import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import FormEmpresa from '../../../components/empresa/FormEmpresa';
import PaquetePercepciones from '../../../components/empresa/PaquetePrestaciones';
import SideMenu from '../../../components/layout/EmpresaMenu/SideMenu';
import TablaSucursal from '../../../components/Sucursal/TableSucursal';

const HashRouteEmpresa = ({ history }) => {
  return (
    <HashRouter basename="/">
      <SideMenu />
      <Switch>
        <Route path='/catalogo' component={PaquetePercepciones} />
        <Route path="/sucursales" component={() => <TablaSucursal history={history}/>} />
        <Route path="/" component={FormEmpresa} />
      </Switch>
    </HashRouter>
  );
};

export default HashRouteEmpresa;
