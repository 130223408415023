import { faUsdSquare, faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import {
  faList, faExclamationCircle, faNotesMedical } from '@fortawesome/pro-solid-svg-icons';

const routes = (permisos) => {
  let subRoutesTimbrado = []
  if (permisos.timbradoNomina === 'none') {
    subRoutesTimbrado = [
      {
        path: "/configuracion-nomina",
        name: "Prenomina",
        icon: faList,
      },
      {
        path: "/extraordinaria",
        name: "Extraordinaria",
        icon: faExclamationCircle,
      }
    ]
  } else {
    subRoutesTimbrado = [
      {
        path: "/configuracion-nomina",
        name: "Prenomina",
        icon: faList,
      },
      {
        path: "/extraordinaria",
        name: "Extraordinaria",
        icon: faExclamationCircle,
      },
      {
        path: "/timbrar",
        name: "Timbrado",
        icon: faUsdSquare,
      },
      {
        path: "/conciliacion-sat",
        name: "Conciliación SAT",
        icon: faFileInvoiceDollar,
      }
    ]
  }
  return [
    {
      subMenuTitle: "",

      subRoutes: subRoutesTimbrado,
    },
    {
      subMenuTitle: "IMSS/INFONAVIT",
      subRoutes: [
        {
          path: "/imss-idse",
          name: "IDSE",
          icon: faNotesMedical,
        },
        {
          path: "/imss-conciliacion",
          name: "Conciliación",
          icon: faNotesMedical,
        },
        {
          path: "/imss-variabilidad",
          name: "Variabilidad",
          icon: faNotesMedical,
        },
      ],
    },
  ]
};
export default routes;
