import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  fetchEmpresasToDropdown,
} from '../../../../store/empresasDuck';
import {
  fetchListaSucursales,
} from '../../../../store/sucursalDuck';
import {
  fetchListaDepartamentos,
} from '../../../../store/departamentosDucks';
import { updateInformacionPersonal } from '../../../../store/colaboradorDucks';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Table
} from 'reactstrap';
import CustomSelect from '../../../ui/CustomSelect';
import '../FormDatosPersonales.css';
import estadoCivilOptions from '../../../../constans/estadoCivil';
import gradoEstudiosOptions from '../../../../constans/gradosEstudio';
import { SchemaDatosEditPersonales } from '../../../../constans/yupSchemas';
import { cleanInvalidObjectsInsideArray } from '../../../../helpers';
import { tiposSangre } from '../../../../constans/tipoSangre';
import InputImage from '../../../ui/InputImage';
import { CancelButton, SaveButton } from '../../../ui/styles/styles';
import Row from 'reactstrap/lib/Row';
import CustomSelectEstados from '../../../ui/CustomSelectEstados';
import SweetAlert from '../../../ui/SweetAlet';
import BotonCargando from '../../../BotonCargando';
import DatePicker from "react-datepicker";

const FormDatosPersonales = React.memo(({disabled = false}) => {
  const [err, setErr] = useState({});
  const { listaSucursales } = useSelector((state) => state.sucursal);
  const { listaDepartamentos } = useSelector((state) => state.departamentos);
  const { empresas } = useSelector((state) => state.empresas);
  const { permisos = {} } = useSelector((state) => state.user);
  const { pertenencia = {}, informacionPersonal = {}, foto = '' } = useSelector((state) => state.colaborador);
  const [saving, setSaving] = useState(false);
  const [estadoCivilVal, setEstadoCivilVal] = useState(informacionPersonal['estadoCivil']);
  const addEstadoCivil = (e) => {
    onChange(e);
    setEstadoCivilVal(e.target.value);
  };
  const {
    contactoEmergencia: {
      nombre = '',
      domicilio = '',
      telefono = null,
      parentesco = '',
    } = {},
  } = informacionPersonal;
  const [informacionFamiliarTabla, setInformacionFamiliarTabla] = useState(
    informacionPersonal['informacionFamiliar']
  );

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(SchemaDatosEditPersonales),
  });
  const addingForm = useCallback(() => {
    const { informacionFamiliar } = getValues();
    if (!informacionFamiliar) return;
    if (!informacionFamiliar.nombre) return;
    const cleanInputData = {
      fechaNacimiento: '',
      nombre: '',
      sexo: '',
      tipoSangre: '',
    };
    setValue('informacionFamiliar', cleanInputData, { shouldDirty: true });
    setInformacionFamiliarTabla((state) => [...state, informacionFamiliar]);
  }, [getValues, setValue,]);

  const onChange = useCallback(
    async ({ target }) => {
      if (!target.value) return;
      try {
        if (target.name === 'empresa') {
          dispatch(fetchListaSucursales(target.value));
        } else if (target.name === 'sucursal') {
          dispatch(fetchListaDepartamentos(target.value));
        }
      } catch (error) { }
    },
    [dispatch]
  );

  const onSubmit = useCallback(async (data) => {
    try {
      setSaving(true);

      data.informacionFamiliar = cleanInvalidObjectsInsideArray(
        informacionFamiliarTabla
      );
      data.fechaNacimiento = parseInt(moment(new Date(data.fechaNacimiento)).format('x'));
      dispatch(updateInformacionPersonal(data));

      await SweetAlert({
        icon: 'success',
        title: 'Colaborador registrado correctamente!',
        showConfirmButton: false,
        timer: 2500,
      });
    
    } catch (error) {
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
      console.log(error);
    } finally {
      setSaving(false);
    }
  }, [dispatch, informacionFamiliarTabla]);
  
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
    (async () => {
      try {
        dispatch(fetchEmpresasToDropdown());
        dispatch(fetchListaSucursales(pertenencia.empresa));
        dispatch(fetchListaDepartamentos(pertenencia.sucursal._id || pertenencia.sucursal));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [dispatch,errors]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label for='empresa' sm={3}>
          Empresa*
        </Label>
        <Col sm={9}>
          <CustomSelect
            disabled={disabled}
            className='mb-3'
            type='select'
            name='empresa'
            id='empresa'
            control={control}
            innerRef={register({ required: true })}
            onChange={onChange}
            options={empresas}
            defaultValue={pertenencia['empresa']}
          />
          {err.empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='sucursal' sm={3}>
          Sucursal*
        </Label>
        <Col sm={9}>
          <CustomSelect
            className='mb-3'
            type='select'
            name='sucursal'
            id='sucursal'
            control={control}
            innerRef={register({ required: true })}
            onChange={onChange}
            options={listaSucursales}
            disabled={disabled ? disabled : listaSucursales.length > 0 ? false : true}
            defaultValue={pertenencia['sucursal']?._id || pertenencia['sucursal']}
          />
          {err.sucursal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Sucursal es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='departamento' sm={3}>
          Departamento*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type='select'
            name='departamento'
            defaultValue={pertenencia['departamento']?._id || pertenencia['departamento']}
            id='departamento'
            control={control}
            disabled={disabled ? disabled : listaDepartamentos.length > 0 ? false : true}
            innerRef={register({ required: true })}
            options={listaDepartamentos}
          />
          {err.departamento && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Departamento es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for='foto'>
          Foto
        </Label>
        <Col sm={9}>
          <InputImage
            disabled={disabled}
            type='file'
            id='foto'
            name='foto'
            accept='image/jpg,image/jpeg,image/png'
            register={register}
            loadImageUrl={`${foto ? process.env.REACT_APP_API_URL + '/archivo/descarga/' + foto : ''}`}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='nombre' sm={3}>
          Nombre*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='nombre'
            defaultValue={informacionPersonal['nombre']}
            placeholder='Ingrese los nombrses del colaborador'
            innerRef={register()}
          />
          {err.nombre && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='apellidoPaterno' sm={3}>
          Apellido Paterno*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='apellidoPaterno'
            defaultValue={informacionPersonal['apellidoPaterno']}
            placeholder='Ingrese el apellido paterno del colaborador'
            innerRef={register()}
          />
          {err.apellidoPaterno && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El apellido paterno es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='apellidoMaterno' sm={3}>
          Apellido Materno*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='apellidoMaterno'
            defaultValue={informacionPersonal['apellidoMaterno']}
            placeholder='Ingrese el apellido materno del colaborador'
            innerRef={register()}
          />
          {err.apellidoMaterno && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El apellido materno es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='Domicilio' sm={3}>
          Domicilio*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='domicilio'
            defaultValue={informacionPersonal['domicilio']}
            placeholder='Ingrese domicilio completo con número exterior'
            innerRef={register()}
          />
          {err.domicilio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El domicilio es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='colonia' sm={3}>
          Colonia*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='colonia'
            defaultValue={informacionPersonal['colonia']}
            placeholder='Ingresa la colonia donde se encuentra la direccion interior'
            innerRef={register()}
          />
          {err.colonia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La colonia es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='telefonoFijo' sm={3}>
          Teléfono*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='number'
            name='telefonoFijo'
            defaultValue={informacionPersonal['telefonoFijo']}
            placeholder='Ingrese el número Teléfono fijo'
            innerRef={register()}
          />
          {err.telefonoFijo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El telefono es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='telefonoMovil' sm={3}>
          Celular*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='number'
            name='telefonoMovil'
            defaultValue={informacionPersonal['telefonoMovil']}
            placeholder='Ingrese el número Celular'
            innerRef={register()}
          />
          {err.telefonoMovil && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El celular es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Sexo*</Label>
        <Col sm={9}>
          <CustomSelect
            type='select'
            name='sexo'
            defaultValue={informacionPersonal['sexo']}
            disabled={disabled}
            control={control}
            innerRef={register()}
            options={[    { value: 'F', label: 'Femenino' },    { value: 'M', label: 'Masculino' },  ]}
          />
          {err.sexo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El sexo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='fechaNacimiento' sm={3}>
          Fecha de Nacimiento*
        </Label>
        <Col sm={4} md={3}>
          <Controller
            name='fechaNacimiento'
            control={control}
            defaultValue={informacionPersonal['fechaNacimiento']}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
                placeholderText='Ingrese la fecha de nacimiento'
              />
            )}
          />
          {err.fechaNacimiento && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de nacimiento es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='lugarNacimiento' sm={3}>
          Lugar de Nacimiento*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='lugarNacimiento'
            defaultValue={informacionPersonal['lugarNacimiento']}
            placeholder='Ingrese el lugar de nacimiento'
            innerRef={register()}
          />
          {err.lugarNacimiento && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El lugar de nacimiento es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='nacionalidad' sm={3}>
          Nacionalidad*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='nacionalidad'
            defaultValue={informacionPersonal['nacionalidad']}
            placeholder='Ingrese la nacionalidad'
            innerRef={register()}
          />
          {err.nacionalidad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La nacionalidad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='ciudadResidencia' sm={3}>
          Ciudad donde radica*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='ciudadResidencia'
            defaultValue={informacionPersonal['ciudadResidencia']}
            placeholder='Ingrese la ciudad donde radica'
            innerRef={register()}
          />
          {err.ciudadResidencia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='estadoResidencia' sm={3}>
          Estado*
        </Label>
        <Col sm={9}>
          <CustomSelectEstados
            disabled={disabled}
            type='select'
            name='estadoResidencia'
            id='estadoResidencia'
            defaultValue={informacionPersonal['estadoResidencia']}
            control={control}
            innerRef={register({ required: true })}
          />
          {err.estadoResidencia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for='estadoCivil'>
          Estado civil*
        </Label>
        <Col sm={9}>
          <CustomSelect
            disabled={disabled}
            type='select'
            name='estadoCivil'
            defaultValue={informacionPersonal['estadoCivil']}
            id='estadoCivil'
            control={control}
            innerRef={register({ required: true })}
            options={estadoCivilOptions}
            onChange={addEstadoCivil}
          />
          {err.estadoCivil && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado civil es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      { estadoCivilVal === 'Casado' || estadoCivilVal === 'Concubinato' ?
        <React.Fragment>
          <FormGroup row>
            <Label for='nombreConyuge' sm={3}>
              Nombre del {estadoCivilVal === 'Casado'?"Cónyuge":"Concubino(a)"}
            </Label>
            <Col sm={9}>
              <Input
                disabled={disabled}
                type='text'
                name='nombreConyuge'
                defaultValue={informacionPersonal['nombreConyuge']}
                placeholder={`Ingrese el nombre del ${estadoCivilVal === 'Casado' ? 'cónyuge' : 'concubino(a)'}`}
                innerRef={register()}
              />
            </Col>
          </FormGroup>
        </React.Fragment>
      : '' }
      <FormGroup row>
        <Label for='email' sm={3}>
          Correo Personal
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='email'
            name='email'
            defaultValue={informacionPersonal['email']}
            placeholder='Ingrese el correo personal'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroIne' sm={3}>
          No. de INE*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='numeroIne'
            defaultValue={informacionPersonal['numeroIne']}
            placeholder='Ingrese el número de INE'
            innerRef={register()}
          />
          {err.numeroIne && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El numero de ine es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroLicenciaConducir' sm={3}>
          No. de Licencia
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='numeroLicenciaConducir'
            defaultValue={informacionPersonal['numeroLicenciaConducir']}
            placeholder='Ingrese el número de licencia'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroPasaporte' sm={3}>
          Pasaporte
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='numeroPasaporte'
            defaultValue={informacionPersonal['numeroLicenciaConducir']}
            placeholder='Ingrese el pasaporte'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='curp' sm={3}>
          CURP*
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='curp'
            defaultValue={informacionPersonal['curp']}
            placeholder='Ingrese la CURP'
            innerRef={register()}
          />
          {err.curp && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La curp es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='gradoEstudios' sm={3}>
          Último Grado de Estudios*
        </Label>
        <Col sm={9}>
          <CustomSelect
              disabled={disabled}
              type='select'
              name='gradoEstudios'
              defaultValue={informacionPersonal['gradoEstudios']}
              id='gradoEstudios'
              control={control}
              innerRef={register({ required: true })}
              options={gradoEstudiosOptions}
            />
            {err.gradoEstudios && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                Por favor inidque el último grado de estudios realizado.
              </span>
            )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <h5>Contacto de Emergencia</h5>
      </FormGroup>
      <FormGroup row>
        <Label for='nombre' sm={3}>
          Nombre completo
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='contactoEmergencia.nombre'
            defaultValue={nombre}
            placeholder='Ingrese el nombre completo'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='domicilio' sm={3}>
          Domicilio
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='contactoEmergencia.domicilio'
            defaultValue={domicilio}
            placeholder='Ingrese el domicilio'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='telefono' sm={3}>
          Teléfono
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='number'
            name='contactoEmergencia.telefono'
            defaultValue={telefono}
            placeholder='Ingrese el teléfono'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='parentesco' sm={3}>
          Parentesco
        </Label>
        <Col sm={9}>
          <Input
            disabled={disabled}
            type='text'
            name='contactoEmergencia.parentesco'
            defaultValue={parentesco}
            placeholder='Ingrese el parentesco'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <h5>Información Familiar</h5>
      </FormGroup>
      <FormGroup row>
        <Col sm={5}>
          <Input
            disabled={disabled}
            type='text'
            name='informacionFamiliar.nombre'
            placeholder='Nombre hijo(a)'
            innerRef={register()}
          />
        </Col>
        <Col sm={2}>
          <CustomSelect
            disabled={disabled}
            control={control}
            options={tiposSangre}
            placeholder={'Tipo de sangre'}
            type='select'
            name='informacionFamiliar.tipoSangre'
            innerRef={register()}
          />
        </Col>
        <Col sm={2}>
          <CustomSelect
            disabled={disabled}
            control={control}
            placeholder={'Sexo'}
            options={[
              { value: 'F', label: 'Femenino' },
              { value: 'M', label: 'Masculino' },
            ]}
            type='select'
            name='informacionFamiliar.sexo'
            innerRef={register()}
          />
        </Col>
        <Col sm={3}>
          <Controller
            name='informacionFamiliar.fechaNacimiento'
            control={control}
            innerRef={register()}
            defaultValue={new Date()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={onChange}
                dateFormat={'dd/MM/yyyy'}
                placeholderText='Fecha de nacimiento'
              />
            )}
          />
        </Col>
      </FormGroup>
      {
        !disabled && <FormGroup row>
          <Col sm={3} className='text-center'>
            <CancelButton type='button' onClick={() => addingForm()}>
              + Agregar
            </CancelButton>
          </Col>
        </FormGroup>
      }
      <FormGroup row>
        {informacionFamiliarTabla?.length > 0 ? (
          <Table striped>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Tipo de Sangre</th>
                <th>Sexo</th>
                <th>Fecha de Nacimiento</th>
                <th>Edad</th>
              </tr>
            </thead>
            <tbody>
              {informacionFamiliarTabla.map(
                ({ nombre, tipoSangre, sexo, fechaNacimiento }, index) => (
                  <tr key={nombre + index}>
                    <td>{nombre}</td>
                    <td>{tipoSangre}</td>
                    <td>{sexo}</td>
                    <td>{moment(new Date(fechaNacimiento)).format("DD/MM/YYYY")}</td>
                    <td>
                      {moment(Date.now()).diff(new Date(fechaNacimiento), 'years')} años
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : null}
      </FormGroup>
      {
        !disabled && <FormGroup className='text-center' hidden={permisos.colaboradorPersonales !== 'write'}>
          <Row>
            <Col sm={{ offset: 9, size: 4 }}>
              <BotonCargando 
                cargando={saving}
                disabled={saving}
                texto='Guardar'
                type='submit'
              />
            </Col>
          </Row>
        </FormGroup>
      }
    </Form>
  );
});

export default FormDatosPersonales;
