import React from "react";
import { Route, useHistory } from "react-router-dom";

import NuevaSolicitud from "../../../components/nomina/NuevaSolicitud";
import ReclutamientoActivas from "../../../components/nomina/ReclutamientoActivas";
import ReclutamientoAsignadas from "../../../components/nomina/ReclutamientoAsignadas";
import ReclutamientoConcluidas from "../../../components/nomina/ReclutamientoConcluidas";
import DarAlta from "../../../pages/RecursosHumanos/DarAlta";
import EditarColaborador from "../../../pages/RecursosHumanos/EditarColaborador";
import ListadoColaboradores from "../../../pages/RecursosHumanos/ListadoColaboradores";
import ListadoColaboradoresProceso from "../../../pages/RecursosHumanos/ListadoColaboradoresProceso";
import ListaColaboradoresBajas from "../../../pages/RecursosHumanos/ListaColaboradoresBajas";
import BajaColaboradores from "../../../pages/RecursosHumanos/BajaColaboradores";
import BajaProceso from "../../../pages/RecursosHumanos/BajaProceso";
import VerColaborador from "../../../pages/RecursosHumanos/EditarColaborador";
import Calendario from "../../../pages/Calendario/Calendario";
import Verificaciones from "../../../components/nomina/Verificaciones";
import AgregarVerificacion from "../../../components/verificaciones/AgregarVerificacion";
import DetalleVerificacion from "../../../components/verificaciones/DetalleVerificacion";
import HorasExtra from "../../../pages/HorasExtra/HorasExtra";
import Faltas from "../../../pages/Faltas/Faltas";
import Ajustes from "../../../pages/Ajustes/Ajustes";

const RecursosHumanosRoutes = ({ path }) => {

  const history = useHistory()

  return (
    <>
      <Route path={`${path}/lista-colaboradores`} component={ListadoColaboradores} />
      <Route path={`${path}/lista-bajas`} component={ListaColaboradoresBajas} />
      <Route path={`${path}/proceso`} component={ListadoColaboradoresProceso} />
      <Route path={`${path}/bajas`} component={BajaColaboradores} />
      <Route path={`${path}/altas`} component={DarAlta} />
      <Route path={`${path}/bajas-proceso/:id`} component={BajaProceso} />
      <Route path={`${path}/editar-colaborador/:id`} component={EditarColaborador} />
      <Route path={`${path}/ver-colaborador/:id`} component={VerColaborador} />
      <Route path={`${path}/nueva-solicitud`} component={NuevaSolicitud} />
      <Route path={`${path}/reclutamiento-activas`} component={ReclutamientoActivas} />
      <Route path={`${path}/reclutamiento-asignadas`} component={ReclutamientoAsignadas} />
      <Route path={`${path}/reclutamiento-concluidas`} component={ReclutamientoConcluidas} />
      <Route path={`${path}/verificaciones`} component={Verificaciones} />
      <Route path={`${path}/agregar-verificacion`} component={AgregarVerificacion} />
      <Route path={`${path}/detalle-verificacion`} component={DetalleVerificacion} />
      <Route path={`${path}/horas-extra`} component={HorasExtra} />
      <Route path={`${path}/faltas-masivo`} component={Faltas} />
      <Route path={`${path}/ajustes-masivo`} component={Ajustes} />
 
      <Route path={`${path}/calendario`} component={Calendario} />
    </>
  );
};

export default RecursosHumanosRoutes;
