import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Col, CustomInput, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportesSchema } from '../../constans/yupSchemas';
import getXls from '../../services/dowloadXls';
import { fetchEmpresasToDropdown, fetchreport, cleanReportes } from '../../store/empresasDuck';
import { TitleMenu, SubTitleMenu, CancelButton, SaveButton } from '../ui/styles/styles';
import { ContainerReportes } from './styles/styles';
import CustomSelect from '../ui/CustomSelect';
import TableDinamic from '../ui/TableDinamic';
import modulos from '../../constans/modulos';
import radiosReportes from '../../constans/radiosReportes';
import Spiner from '../ui/Spiner';
import './styles/PaginationComponent.css';
import DatePicker from "react-datepicker";
import axios from '../../services/axios';
import Paginacion from '../Paginacion';
import Filtros from './Filtros';

const api = process.env.REACT_APP_API_URL;
const headers = {
  headers: {
    token: localStorage.getItem("pv2token"),
  },
};

let loadingFirst = true;

const Reportes = () => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const dispatch = useDispatch();
  const { empresas, loading, reportes = {} } = useSelector(
    (state) => state.empresas
  );
  const [radioSelected, setRadioSelected] = useState('');
  const [dataBody, setDataBody] = useState({});
  const [page, setPage] = useState(0);
  const [request, setRequest] = useState({})
  const [showPaginador, setPaginador] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterNomina, setFilterNomina] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [registroSelected, setRegistroSelected] = useState('');
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const { register, control, reset, getValues } = useForm(
    {
      resolver: yupResolver(reportesSchema),
    }
  );
  const [filtrosBusqueda, setFiltrosBusqueda] = useState(null);
  const handleSelect = (e) => {
    let valor = e.target.value;
    console.log(valor);
    setRequest({ [valor]: 'true' });

    setFilter(valor === "historial");
    setFilterNomina(valor === "nomina");
  }
 
  const registrosToDwopDown = async (empresa) =>{
    const { data } = await axios.get(`${api}/empresa/obtener_registros/${empresa}`, headers)
    if (data) {
      const rp = []
      data.response.map(r => {
        rp.push({ label: r, value: r })
      });
      setRegistros(rp)
    }
    console.log('fetch registros to dd');
  } 

  useEffect(() => {
      const data = getValues();

      if (request) {
        data.filtroInicio = data.filtroInicio ? new Date(data.filtroInicio) : "";
        data.filtroFin = data.filtroFin ? new Date(data.filtroFin) : "";
        data.requestedData = request;
        
        if(registroSelected) {
          data.registro = registroSelected;
        }
        
        data.page = page;
        setDataBody(() => data);
        dispatch(fetchreport(data));
      }
  }, [page])

  useEffect(() => {
    dispatch(cleanReportes());
  }, [])

  useEffect(() => {
    if (registros.length > 0) {
      setError1(false);
    } if (radioSelected !== '') {
      setError2(false);
    }
  }, [registros, radioSelected])

  useEffect(() => {
    try {
      dispatch(fetchEmpresasToDropdown());
    } catch (error) { }
    loadingFirst = false;
  }, [dispatch]);

  if (loadingFirst) {
    return <Spiner />;
  }

  const onChangeSatate = ({ target }) => {
    if (target.value !== '') {
      const radios = radiosReportes[target.value];
      let val; 
      
      if(radios) {
        val = radios[0].value;
      }

      if (target.value) setRadioSelected(() => target.value);

      setRequest({ [val || target.value]: 'true' });

      if (val === "historial" || val === "nomina") {
        setFilter(true);
        setFilterNomina(true);
      } else {
        setFilter(false);
        setFilterNomina(false);
      }

    } else {
      setRadioSelected('');
      setFilter(false);
      setFilterNomina(false);
    }
    setFiltrosBusqueda({});
  };

  const onSubmit = () => {
    if (registros.length === 0) {
      setError1(true);
    } else if (radioSelected === '') {
      setError2(true);
    } else {
      try {
        const data = getValues();
        data.filtroInicio = data.filtroInicio ? new Date(data.filtroInicio) : "";
        data.filtroFin = data.filtroFin ? new Date(data.filtroFin) : "";
        data.requestedData = request;

        if(registroSelected) {
          data.registro = registroSelected;
        }
        
        setDataBody(() => data);
        setPaginador(true);
        setPage(1);
        dispatch(fetchreport(data));
      } catch (error) { 
        console.log(error);
      }
    }
  };

  console.log(dataBody)

  const dowloadXls = () => {
    getXls({ body: {...dataBody, ...filtrosBusqueda}, reporte: radioSelected });
  }

 /*  const CreateRadios = React.memo(() => {
    return (
    
    );
  }); */

  return (
    <ContainerReportes>
      <div style={{ minHeight: '15vh' }}>
        <TitleMenu>Reportes</TitleMenu>
        <SubTitleMenu style={{ color: '#404041', fontWeight: '400' }}>
          Genera reportes específicos de tus colaboradores
        </SubTitleMenu>
      </div>
      <Form>
        <FormGroup row>
          <Label for='empresa' sm={3}>
            Seleccionar Empresa
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='empresas[0]'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={empresas}
              onChange={e => registrosToDwopDown(e.target.value)}
            />
          </Col>
          
        {/* </FormGroup>
        <FormGroup row> */}
          <Label for='empresa' sm={3}>
            Seleccionar Reporte
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='nombreReporte'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={modulos}
              onChange={onChangeSatate}
            />
          </Col>
        </FormGroup>
        {error1 && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Se debe seleccionar una empresa.
            </span>
          )}
        {error2 && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Se debe seleccionar un reporte.
            </span>
          )}
          <>
      <FormGroup row>
        {radiosReportes[radioSelected]
          ? radiosReportes[radioSelected].map(({ label, value, check}) => (
            <Label sm={4} check className='radio-organize' inline='true'>
              <CustomInput
                type='radio'
                name={`requestedData`}
                onClick={(e) => handleSelect(e)}
                id='radio'
                value={value}
                checked={request[value]}
                innerRef={register()}
              />
              {label}
            </Label>
          ))
          : null}
      </FormGroup>
      {filter || filterNomina ?
      <FormGroup row>
        <Label sm={1}>Inicio</Label>
          <Col sm={3}>
            <Controller
              name='filtroInicio'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                />
              )}
            />
          </Col>
          <Label sm={1}>Fin</Label>
          <Col sm={3}>
            <Controller
              name='filtroFin'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                />
              )}
            />
          </Col>

          {filterNomina ? 
          <>
          <Label sm={1}>Registro</Label>
          <Col sm={3}>
            <Input
              type='select'
              name='registro'
              id='registro'
              value={registroSelected}
              onChange={e => setRegistroSelected(e.target.value)}
            >
              {registros && registros.length > 0 ?
                registros.map((r, index) => (<option key={index} value={r.value}>{r.label}</option>))
              : null}
            </Input>
          </Col>
          </> : null}
      </FormGroup>
      
      :null}
      </>
        <FormGroup row>
        <Col sm={3}>
            <CancelButton type='button' onClick={() => {
              dispatch(cleanReportes())
              reset();
              setDataBody(() => { });
              setRadioSelected(() => '')
              setFilter(false)
            }}>Limpiar</CancelButton>
          </Col>
          <Col sm={3}>
            <SaveButton type='button' onClick={onSubmit}>
              Generar
            </SaveButton>
          </Col>
          <Col sm={{ offset: 3, size: 3 }}>
            <SaveButton type='button' onClick={() => dowloadXls()}>Exportar</SaveButton>
          </Col>
        </FormGroup>
      </Form>
      <Col sm={12}>
        {loading ? <Spiner /> : <TableDinamic reportes={reportes} />}
      </Col>
      <br />
      <br />
      <Paginacion
        pagina={page}
        totalPaginas={reportes.totalPages}
        cambioPagina={page  => setPage(page)}
      />
    </ContainerReportes>
  );
};

export default Reportes;
