import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  cleanStateEmpresas,
  fetchEmpresasToDropdown,
} from '../../../store/empresasDuck';
import {
  cleanStateSucursal,
  fetchListaSucursales,
} from '../../../store/sucursalDuck';
import {
  cleanStateDepartamentos,
  fetchListaDepartamentos,
} from '../../../store/departamentosDucks';
import { createColaborador } from '../../../store/colaboradorDucks';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap';
import CustomSelect from '../../ui/CustomSelect';
import CustomSelectEstados from '../../ui/CustomSelectEstados';
import './FormDatosPersonales.css';
import Spiner from '../../ui/Spiner';
import estadoCivilOptions from '../../../constans/estadoCivil';
import gradosEstudioOptions from '../../../constans/gradosEstudio';
import { schemaDatosPersonales } from '../../../constans/yupSchemas';
import { cleanInvalidObjectsInsideArray } from '../../../helpers';
import { tiposSangre } from '../../../constans/tipoSangre';
import SweetAlert from '../../ui/SweetAlet';
import InputImage from '../../ui/InputImage';
import { CancelButton, SaveButton } from '../../ui/styles/styles';
import { useHistory } from 'react-router-dom';
import BotonCargando from '../../BotonCargando';
import DatePicker from "react-datepicker";

let loading = true;
const FormDatosPersonales = React.memo(() => {
  const history = useHistory();
  const { listaSucursales } = useSelector((state) => state.sucursal);
  const { listaDepartamentos } = useSelector((state) => state.departamentos);
  const { empresas } = useSelector((state) => state.empresas);
  const { permisos = {} } = useSelector((state) => state.user);
  const [cleanImage, setcleanImage] = useState('');
  const [err, setErr] = useState({});
  const [informacionFamiliarTabla, setInformacionFamiliarTabla] = useState([]);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schemaDatosPersonales),
  });
  const [saving, setSaving] = useState(false);

  const [estadoCivilVal, setEstadoCivilVal] = useState('');
  const addEstadoCivil = (e) => {
    onChange(e);
    setEstadoCivilVal(e.target.value);
  };

  const onChange = useCallback(
    async ({ target }) => {
      if (!target.value) return;
      try {
        if (target.name === 'empresa') {
          dispatch(fetchListaSucursales(target.value));
        } else if (target.name === 'sucursal') {
          dispatch(fetchListaDepartamentos(target.value));
        }
      } catch (error) { }
    },
    [dispatch]
  );
  
  const onSubmit = useCallback(
    async (data) => {
      try {
        setSaving(true);
        data.informacionFamiliar = cleanInvalidObjectsInsideArray(
          informacionFamiliarTabla
        );
        data.fechaNacimiento = parseInt(
          moment(new Date(data.fechaNacimiento)).format('x')
        );

        dispatch(createColaborador(data));
        
        await SweetAlert({
          icon: 'success',
          title: 'Colaborador registrado correctamente!',
          showConfirmButton: false,
          timer: 2500,
        });
      
        reset()
        setInformacionFamiliarTabla([])
        setValue('foto', null)
        setcleanImage(() => 'clean');
        setcleanImage(() => '');
        history.push('editar-colaborador');
      } catch (error) {
        await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
        console.log(error);
      } finally {
        setSaving(false);
      }
    },
    [dispatch, informacionFamiliarTabla, reset, setValue]
  );

  const addingForm = useCallback(() => {
    const { informacionFamiliar } = getValues();
    if (!informacionFamiliar) return;
    if (!informacionFamiliar.nombre || !informacionFamiliar.fechaNacimiento || 
        !informacionFamiliar.sexo || !informacionFamiliar.tipoSangre) {
          SweetAlert({ icon: "error", title: "Error!", text: 'Debes llenar todo' });
    }else{
      const cleanInputData = {
        fechaNacimiento: '',
        nombre: '',
        sexo: '',
        tipoSangre: '',
      };
      setValue('informacionFamiliar', cleanInputData, { shouldDirty: true });
      setInformacionFamiliarTabla((state) => [...state, informacionFamiliar]);
    }
    
  }, [getValues, setValue]);

  const cleanState = useCallback(() => {
    dispatch(cleanStateEmpresas());
    dispatch(cleanStateDepartamentos());
    dispatch(cleanStateSucursal());
  }, [dispatch]);
  
  useEffect(() => {
    if (!loading) {
      cleanState();
    }

    if (Object.keys(errors).length) setErr(errors);

    (async () => {
      try {
        await dispatch(fetchEmpresasToDropdown());
      } catch (error) {
        console.log(error);
      }
    })();
    loading = false;
    return () => {
      cleanState();
    };
  }, [dispatch, cleanState,errors]);

  if (loading) {
    return <Spiner />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label for='empresa' sm={3}>
          Empresa*
        </Label>
        <Col sm={9}>
          <CustomSelect
            className='mb-3'
            type='select'
            name='empresa'
            id='empresa'
            control={control}
            innerRef={register({ required: true })}
            onChange={onChange}
            options={empresas}
          />
          {err.empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='sucursal' sm={3}>
          Sucursal*
        </Label>
        <Col sm={9}>
          <CustomSelect
            className='mb-3'
            type='select'
            name='sucursal'
            id='sucursal'
            control={control}
            innerRef={register({ required: true })}
            onChange={onChange}
            options={listaSucursales}
            disabled={listaSucursales.length > 0 ? false : true}
          />
          {err.sucursal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Sucursal es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='departamento' sm={3}>
          Departamento*
        </Label>
        <Col sm={9}>
          <CustomSelect
            className='mb-3'
            type='select'
            name='departamento'
            id='departamento'
            control={control}
            disabled={listaDepartamentos.length > 0 ? false : true}
            innerRef={register({ required: true })}
            onChange={onChange}
            options={listaDepartamentos}
          />
          {err.departamento && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Departamento es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for='foto'>
          Foto
        </Label>
        <Col sm={4}>
          <InputImage
            type='file'
            id='foto'
            name='foto'
            accept='image/jpg,image/jpeg,image/png'
            loadImageUrl={cleanImage}
            register={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='nombre' sm={3}>
          Nombre*
        </Label>
        <Col sm={9}>
          <Input
            className='mb-3'
            type='text'
            name='nombre'
            placeholder='Ingrese los nombres del colaborador'
            innerRef={register()}
          />
          {err.nombre && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='apellidoPaterno' sm={3}>
          Apellido Paterno*
        </Label>
        <Col sm={9}>
          <Input
            className='mb-3'
            type='text'
            name='apellidoPaterno'
            placeholder='Ingrese apellido paterno del colaborador'
            innerRef={register()}
          />
          {err.apellidoPaterno && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El apellido paterno es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='apellidoMaterno' sm={3}>
          Apellido Materno*
        </Label>
        <Col sm={9}>
          <Input
            className='mb-3'
            type='text'
            name='apellidoMaterno'
            placeholder='Ingrese apellido materno del colaborador'
            innerRef={register()}
          />
          {err.apellidoMaterno && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El apellido materno es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='Domicilio' sm={3}>
          Domicilio*
        </Label>
        <Col sm={9}>
          <Input
            className='mb-3'
            type='text'
            name='domicilio'
            placeholder='Ingrese domicilio completo con número exterior'
            innerRef={register()}
          />
          {err.domicilio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El domicilio es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='colonia' sm={3}>
          Colonia*
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='colonia'
            placeholder='Ingresa la colonia donde se encuentra la dirección interior'
            innerRef={register()}
          />
          {err.colonia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La colonia es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='telefonoFijo' sm={3}>
          Teléfono*
        </Label>
        <Col sm={9}>
          <Input
            type='number'
            name='telefonoFijo'
            placeholder='Ingrese el número teléfono fijo'
            innerRef={register()}
          />
          {err.telefonoFijo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El telefono es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='telefonoMovil' sm={3}>
          Celular*
        </Label>
        <Col sm={9}>
          <Input
            type='number'
            name='telefonoMovil'
            placeholder='Ingrese el número celular'
            innerRef={register()}
          />
          {err.telefonoMovil && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El celular es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Sexo*</Label>
        <Col sm={9}>
          <CustomSelect
            control={control}
            options={[
              { value: 'F', label: 'Femenino' },
              { value: 'M', label: 'Masculino' },
            ]}
            type='select'
            name='sexo'
            innerRef={register()}
          />
          {err.sexo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El sexo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='fechaNacimiento' sm={3}>
          Fecha de Nacimiento*
        </Label>
        <Col sm={3}>
          <Controller
            name='fechaNacimiento'
            dateFormat={'dd/MM/yyyy'}
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                onChange={onChange}
                placeholderText='Fecha de nacimiento'
              />
            )}
          />
          {err.fechaNacimiento && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de nacimiento es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='lugarNacimiento' sm={3}>
          Lugar de Nacimiento*
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='lugarNacimiento'
            placeholder='Ingrese el lugar de nacimiento'
            innerRef={register()}
          />
          {err.lugarNacimiento && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El lugar de nacimiento es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='nacionalidad' sm={3}>
          Nacionalidad*
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='nacionalidad'
            placeholder='Ej. Mexicana'
            innerRef={register()}
          />
          {err.nacionalidad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La nacionalidad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='ciudadResidencia' sm={3}>
          Ciudad de residencia*
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='ciudadResidencia'
            placeholder='Ej. Hermosillo'
            innerRef={register()}
          />
          {err.ciudadResidencia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='estadoResidencia' sm={3}>
          Estado de residencia*
        </Label>
        <Col sm={9}>
          <CustomSelectEstados
            type='select'
            name='estadoResidencia'
            id='estadoResidencia'
            control={control}
            innerRef={register({ required: true })}
          />
          {err.estadoResidencia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for='estadoCivil'>
          Estado civil*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type='select'
            name='estadoCivil'
            id='estadoCivil'
            control={control}
            innerRef={register({ required: true })}
            options={estadoCivilOptions}
            onChange={addEstadoCivil}
          />
          {err.estadoCivil && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado civil es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      { estadoCivilVal === 'Casado' || estadoCivilVal === 'Concubinato' ? 
        <React.Fragment>
          <FormGroup row>
            <Label for='nombreConyuge' sm={3}>
              Nombre del {estadoCivilVal === 'Casado'?"Cónyuge":"Concubino(a)"}
            </Label>
            <Col sm={9}>
              <Input
                type='text'
                name='nombreConyuge'
                placeholder={`Ingrese el nombre del ${estadoCivilVal === 'Casado' ? 'cónyuge' : 'concubino(a)'}`}
                innerRef={register()}
              />
            </Col>
          </FormGroup>
        </React.Fragment>
      :''}
      <FormGroup row>
        <Label for='email' sm={3}>
          Correo Personal
        </Label>
        <Col sm={9}>
          <Input
            type='email'
            name='email'
            placeholder='ejemplo@correo.com'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroIne' sm={3}>
          No. de INE*
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='numeroIne'
            placeholder='Ingrese el número de INE'
            innerRef={register()}
          />
          {err.numeroIne && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El numero de ine es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroLicenciaConducir' sm={3}>
          No. de Licencia
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='numeroLicenciaConducir'
            placeholder='Ingrese el No. de licencia de conducir'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroPasaporte' sm={3}>
          Pasaporte
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='numeroPasaporte'
            placeholder='Ingrese el No. pasaporte'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='curp' sm={3}>
          CURP*
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='curp'
            placeholder='Ingrese CURP'
            innerRef={register()}
          />
          {err.curp && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La curp es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='gradoEstudios' sm={3}>
          Último Grado de Estudios*
        </Label>
        <Col sm={9}>
          <CustomSelect
              type='select'
              name='gradoEstudios'
              id='gradoEstudios'
              control={control}
              innerRef={register({ required: true })}
              options={gradosEstudioOptions}
            />
            {err.gradoEstudios && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                Por favor inidque el último grado de estudios realizado.
              </span>
            )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <h5>Contacto de Emergencia</h5>
      </FormGroup>
      <FormGroup row>
        <Label for='nombre' sm={3}>
          Nombre completo
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='contactoEmergencia.nombre'
            placeholder='Ingrese el nombre del contacto de emergencia'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='domicilio' sm={3}>
          Domicilio
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='contactoEmergencia.domicilio'
            placeholder='Ingrese domicilio del contacto de emergencia'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='telefono' sm={3}>
          Teléfono
        </Label>
        <Col sm={9}>
          <Input
            type='number'
            name='contactoEmergencia.telefono'
            placeholder='Ingrese el número del contacto de emergencia'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='parentesco' sm={3}>
          Parentesco
        </Label>
        <Col sm={9}>
          <Input
            type='text'
            name='contactoEmergencia.parentesco'
            placeholder='Ej. Madre'
            innerRef={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <h5>Información Familiar</h5>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <Input
            type='text'
            name='informacionFamiliar.nombre'
            placeholder='Nombre hijo(a)'
            innerRef={register()}
          />
        </Col>
        <Col sm={3}>
          <CustomSelect
            control={control}
            options={tiposSangre}
            type='select'
            placeholder={'Tipo de sangre'}
            name='informacionFamiliar.tipoSangre'
            innerRef={register()}
          />
        </Col>
        <Col sm={3}>
          <CustomSelect
            control={control}
            options={[
              { value: 'F', label: 'Femenino' },
              { value: 'M', label: 'Masculino' },
            ]}
            type='select'
            name='informacionFamiliar.sexo'
            innerRef={register()}
          />
        </Col>
        <Col sm={4} md={3}>
          <Controller
            name='informacionFamiliar.fechaNacimiento'
            control={control}
            innerRef={register()}
            render={({ onChange, value }) => (
              <DatePicker
                dateFormat={'dd/MM/yyyy'}
                selected={value}
                onChange={onChange}
                placeholderText='Fecha de nacimiento'
              />
            )}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} className='text-center'>
          <CancelButton type='button' onClick={() => addingForm()}>
            + Agregar
          </CancelButton>
        </Col>
      </FormGroup>
      <FormGroup>
        {informacionFamiliarTabla.length > 0 ? (
          <Table striped>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Tipo de Sangre</th>
                <th>Sexo</th>
                <th>Fecha de Nacimiento</th>
                <th>Edad</th>
              </tr>
            </thead>
            <tbody>
              {informacionFamiliarTabla.map(
                ({ nombre, tipoSangre, sexo, fechaNacimiento }, index) => (
                  <tr key={nombre + index}>
                    <td>{nombre}</td>
                    <td>{tipoSangre}</td>
                    <td>{sexo}</td>
                    <td>{moment(new Date(fechaNacimiento)).format("DD/MM/YYYY")}</td>
                    <td>
                      {moment(Date.now()).diff(new Date(fechaNacimiento), 'years')} años 
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : null}
      </FormGroup>
      <FormGroup className='text-center'>
        <Col sm={{ offset: 9, size: 5 }} hidden={permisos.colaboradorPersonales !== 'write'}>
          <BotonCargando 
            cargando={saving}
            disabled={saving}
            texto='Guardar'
            type='submit'
          />
        </Col>
      </FormGroup>
    </Form>
  );
});

export default FormDatosPersonales;
