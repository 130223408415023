import React from 'react';
import { NavMenu } from './styles';
import { useLocation, useHistory } from 'react-router-dom';

const rutas = [
  {
    url: '/',
    activa: false,
    titulo: "Datos empresa",
  },
  {
    url: '/sucursales',
    activa: false,
    titulo: "Sucursales",
  },
  {
    url: '/catalogo',
    activa: false,
    titulo: "Catalogos",
  }
];

const SideMenu = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <NavMenu>
      <ul style={{listStyle:'none'}}>
        {
          rutas.map(ruta => (
            <li key={ruta.url}>
              <span
                className={ruta.url === pathname ? 'activeEmpresa' : ''}
                onClick={() => {
                  history.replace(ruta.url)
                }}
              >
              { ruta.titulo }
              </span>
            </li>
          ))
        }
      </ul>
    </NavMenu>
  );
};

export default SideMenu;
