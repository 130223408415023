import React, { useEffect, useState } from 'react';
import ComponentDescrip from '../../components/ui/ComponentDescrip';
import HttpService, { getAgregarParametrosExtra } from '../../services/HttpService';
import EmptyList from '../../components/ui/EmptyList';
import Spiner from '../../components/ui/Spiner';
import { BackgroundImage } from '../../pages/styled';
import Tabla from '../../components/Tabla';
import FiltroColaboradores from '../../components/FiltroColaboradores';
import { useDispatch } from 'react-redux';
import SweetAlert from '../../../src/components/ui/SweetAlet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const cabecera = [
  {
    id: 'puesto',
    titulo: 'Puesto'
  }
];

const ListaColaboradoresProceso = ({history}) => {
  const [cargando, setCargando] = useState(true);
  const [colaboradores, setColaboradores] = useState([]);
  const [paginacion, setPaginacion] = useState(null);
  const [filtros, setFiltros] = useState({});
  const dispatch = useDispatch();
  const columnasExtra = [
    {
      id: 'foto',
      titulo: 'Foto',
      render: (colaborador) => (colaborador.foto && <BackgroundImage imageUrl={`${process.env.REACT_APP_API_URL}/archivo/descarga/${colaborador.foto}`} />),
      empujarAlInicio: true
    },
    {
      id: "nombreCompleto",
      titulo: "Nombre",
      render: (colaborador) => {
        const { nombre, apellidoMaterno, apellidoPaterno } = colaborador.informacionPersonal;
        
        return (<div> {`${nombre} ${apellidoPaterno} ${apellidoMaterno}`} </div>)
      },
      empujarAlInicio: true
    },
    {
      id: "correo",
      titulo: "Correo",
      render: (colaborador) => {
        const { email } = colaborador.informacionPersonal;
        
        return (<div> {email} </div>)
      },
      empujarAlInicio: true
    },
    {
      id: "borrar",
      titulo: "Borrar",
      render: (colaborador) => {
        return (<FontAwesomeIcon icon={faTrash} onClick={(e) => { 
          e.stopPropagation();
          borrarColaborador(colaborador._id)} 
        }/>)
      },
    }
  ];

  useEffect(() => {
    let montado = true;

    const init = async () => {
      if(!montado) return;

      try {
        await obetenerColaborades(null, 1, 5);
      } catch (error) {
        console.log(error);
      } finally {
        setCargando(false);
      }
    }
    
    init();

    return () => {
      montado = false
    }
  }, []);

  const obetenerColaborades = async (parametrosExtra, pagina, limite) => {
    const respuesta = await HttpService.get(`/colaborador/colaboradores?pagina=${pagina}&limite=${limite}&active=true&estatus=proceso${getAgregarParametrosExtra(parametrosExtra)}`);

    if(respuesta.data) {
      const { colaboradores, paginacion } = respuesta.data;

      setPaginacion(paginacion);
      setColaboradores(
        colaboradores.map(colaborador => ({
          ...colaborador, 
          puesto: colaborador.condicionesLaborales?.puesto 
            ? colaborador.condicionesLaborales.puesto 
            : "Sin puesto"
        }))
      );
    }
  }

  const cambioPagina = async _pagina => 
  {
    setCargando(true);

    try {
      await obetenerColaborades(null, _pagina, 5);
    } catch (error) {
      console.log(error);      
    } finally {
      setCargando(false);
    }
  }
  
  const buscar = async ({buscarColaborador, idEmpresa, idSucursal, idDepartamento}) => {
    setCargando(true);

    let parametrosExtra = {
      buscarColaborador,
      idEmpresa,
      idSucursal,
      idDepartamento
    }

    for (const key in parametrosExtra) {
      if(!parametrosExtra[key]) {
        delete parametrosExtra[key];
      }
    }

    setFiltros(parametrosExtra);

    try {
      await obetenerColaborades(parametrosExtra, 1, 5);
    } catch (error) {
      console.log(error);
    } finally {
      setCargando(false);
    }
  }

  const borrarColaborador = async _id => {
    const { isConfirmed } = await SweetAlert({
      title: '¿Seguro que desea eliminar?',
      text: 'Esta acción no se puede deshacer',
      showCancelButton: 'Cancelar',
    });

    if (isConfirmed) {
      try {
        await HttpService.delete(`/colaborador/${_id}`);
        await obetenerColaborades(filtros, 1, 5);
        await SweetAlert({
          title: 'Colaborador eliminado exitosamente',
          icon: 'success'
        })
      } catch (error) {
        await SweetAlert({
          title: 'Ocurrió un error al eliminar',
          icon: 'error'
        })
      }
    }
  }

  return (
    <div>
      <ComponentDescrip
        title={'Colaboradores en proceso'}
        description={""}
      />     
      <FiltroColaboradores
        buscar={buscar}
        cargandoBoton={cargando}
      />
      {
        cargando
        ?
          <div style={{marginTop: "20%"}}>
            <Spiner />
          </div>
        :
          colaboradores.length 
          ?
            <Tabla 
              cabecera={cabecera}
              datos={colaboradores}
              columnasExtra={columnasExtra}
              paginacion={paginacion}
              cambioPagina={cambioPagina}
              clickRenglon={(colaborador) => {
                history.push('/dashboard/recursos-humanos/editar-colaborador/' + colaborador.id);
              }}
            />
          :
            <EmptyList
              align={'center'}
              title={'Sin colaboradores'}
            />
      }
    </div>
  )
}

export default ListaColaboradoresProceso;