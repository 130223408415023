import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Button, Col, Label, Row } from "reactstrap";
import { BtnGroup, HeaderStyle } from "../../components/ui/styles/StyleNomina";
import { TitleMenu } from "../../components/ui/styles/styles";
import InputCurrency from "react-currency-format";
import Swal from "sweetalert2";
import { Fragment } from "react";
import ResumenPtu from "./ResumenPtu";
const api = process.env.REACT_APP_API_URL;

const Finiquitos = ({ title = "" }) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const [empresa, setEmpresa] = useState({ informacionLegal: {} });
  const { empresas } = useSelector(state => state.empresas);
  const [renta, setRenta] = useState('');
  const [type, setType] = useState("");
  const [distUtilidades, setDistUtilidades] = useState([]);
  const [calculados, setCalculados] = useState([])
  const [colaboradoresSelect, setColaboradoresSelect] = useState([]);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {
    setEmpresa(empresas.find(e => e._id === params.idEmpresa) || {});
    obtenerPendientes()
  }, [])

  const obtenerPendientes = async () => {
    let { data } = await axios.get(`${api}/extraordinaria/finiquitos/${params.idEmpresa}`, headers);
    setType("resumen");
    setDistUtilidades(data.response.dist);
    setCalculados(data.response.res);
  };

  const calcularExtraordinaria = async () => {
    Swal.showLoading();
    setType('resumen')
    Swal.close();
  }

  const aprobarPrenomina = async () => {
    Swal.showLoading();
    await axios.put(`${api}/extraordinaria/estado/${params.idEmpresa}/?path=finiquito&estado=aprobada`, colaboradoresSelect, headers);
    Swal.close();
    history.goBack();
  };

  const onVolver = async () => {
    await obtenerPendientes();
    setType("");
  };

  const onChangeSelected = (selected) => {
    let data = Object.keys(selected).filter((k) => selected[k]);
    setColaboradoresSelect(data);
  };

  return (
    <Fragment>
      <Row>
        <TitleMenu></TitleMenu>
        <HeaderStyle>
          <h1>{title} {state.page ? `- ${state.page.toUpperCase()}` : ''} </h1>
          <h2>{empresa.informacionLegal.razon_social}</h2>
          <span style={{ color: 'rgb(43, 56, 143)' }}>* Al realizar los calculos se omiten los colaboradores que no cumplan las condiciones para el Finiquito</span>
          <br />
        </HeaderStyle>
      </Row>
      <BtnGroup>
        <Row className="my-4">
          {
            type === "" && (
              <Fragment>
                <Col md={"auto"}>
                  <Label style={{ marginTop: 13 }}>Utilidad:</Label>
                </Col>
                <Col md={"auto"}>
                  <InputCurrency
                    style={{ marginTop: 7 }}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    className="inputMoneda"
                    value={renta}
                    thousandSeparator={true}
                    prefix={"$"}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setRenta(value)
                    }}
                  />
                </Col>
                <Col md={"auto"}>
                  <Button outline onClick={calcularExtraordinaria} disabled={renta === ''}>
                    Calcular
                  </Button>
                </Col>
              </Fragment>
            )
          }
          {
            type !== "" && (
              <Fragment>
                <Col md={"auto"}>
                  <Button outline onClick={onVolver}>
                    Volver
                  </Button>
                </Col>
                <Col md={"auto"}>
                  <Button outline disabled={!colaboradoresSelect.length} onClick={aprobarPrenomina}>
                    Aprobar
                  </Button>
                </Col>
              </Fragment>
            )
          }
        </Row>
      </BtnGroup>
      {type === "resumen" && <ResumenPtu colaboradores={calculados} onChange={onChangeSelected} dist={distUtilidades} />}
    </Fragment>
  )
}

export default Finiquitos;
