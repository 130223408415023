const modulos = [
  { value: 'visor', label: 'Visor de Colaboradores' },
  { value: 'laborales', label: 'Información Laboral' },
  { value: 'personales', label: 'Información Personal' },
  { value: 'herramientas', label: 'Herramientas de trabajo' },
  { value: 'uniformes', label: 'Uniformes de trabajo' },
  /* { value: 'utilitario', label: 'Utilitario' }, */
  { value: 'expediente', label: 'Plantilla Laboral' },
  { value: 'vacaciones', label: 'Vacaciones' },
  { value: 'medico', label: 'Salud Laboral' },
  { value: 'imss', label: 'IMSS/INFONAVIT' },
  { value: 'nomina', label: 'Nómina' },
  { value: 'permisos', label: 'Permisos'},
  { value: 'incapacidades', label:'Incapacidades'},
  { value: 'citatorios', label:'Citatorios'},
  { value: 'demandas', label:'Demandas'},
  { value: 'finiquitos', label:'Finiquitos'},
  { value: 'kardex', label:'Kardex'}
];
export default modulos;
