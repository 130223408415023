import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  Form,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
} from "reactstrap";
import { StyleInputs } from "./styles/styles";
import { H3 } from "./styles/PrestacionesStyles";
import PrestacionesFormula from "./PrestacionesModal";
import axios from "../../services/axios";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import sweet from "sweetalert2";

export default ({ onCancel, text, vacaciones }) => {
  const { _id } = useSelector((state) => state.empresas);
  const [paquete, setPaquete] = useState({ antiguedad: '', dias: '' });
  const [prestaciones, setPrestaciones] = useState([]);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token")
    }
  };

  useEffect(() => {
    if (vacaciones.length) {
      setPrestaciones(vacaciones);
    } else {
      setPrestaciones([]);
    }
  }, [vacaciones]);

  const addPrestacion = () => {
    if (isPreatacionInvalid()) return;
    setPrestaciones([...prestaciones, paquete])
    setPaquete({ antiguedad: '', dias: '' });
  };

  const isPreatacionInvalid = () => {
    return paquete.antiguedad == null && paquete.dias == null
  };

  const onDeletePrestacion = async (antiguedad) => {
    let result = await sweet.fire({
      title: "Borrar ",
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      setPrestaciones(prestaciones.filter(fl => fl.antiguedad != antiguedad))
      setPaquete({ antiguedad: null, dias: null });
    }
  };

  const onGuardar = async (empresaId) => {
    const { data } = await axios.post(`/nomina/prestaciones/vacaciones/${empresaId}/`, prestaciones, headers);
    setPrestaciones(data.response)
    onCancel();
  };

  return (
    <>
      <Row>
        <StyleInputs>
          <Col>
            <H3>{text}</H3>
          </Col>
          <Col className="text-right">
            <Button color="secondary" onClick={onCancel}>
              Cancelar
            </Button>{" "}
            <Button disabled={!prestaciones.length} color="primary" onClick={() => onGuardar(_id)}>
              Guardar
            </Button>
          </Col>
        </StyleInputs>
      </Row>
      <Row>
        <Col>
          <StyleInputs>
            <h4>Agregar {text}</h4>
          </StyleInputs>
        </Col>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>
                <Input type="text" min="0" value={paquete.antiguedad} placeholder="Antiguedad" required onChange={(e) => setPaquete({ ...paquete, antiguedad: e.target.value })} />
              </th>
              <th>
                <Input type="text" min="0" value={paquete.dias} placeholder="Días" required onChange={(e) => setPaquete({ ...paquete, dias: e.target.value })} />
              </th>
              <th>
                <StyleInputs>
                  <Button disabled={isPreatacionInvalid()} size="sm" style={{ width: "50px" }} color="primary" onClick={addPrestacion}>
                    +
                  </Button>
                </StyleInputs>
              </th>
            </tr>
          </thead>
          <tbody>
            {prestaciones.map((pr, id) => {
              return (
                <tr key={id}>
                  <td>{pr.antiguedad}</td>
                  <td>{pr.dias}</td>
                  <td>
                    <FontAwesomeIcon icon={faTrash} className="mx-1" cursor={"pointer"} onClick={() => onDeletePrestacion(pr.antiguedad)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
};
