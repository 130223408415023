import React, {useCallback} from 'react';
import colors from '../../../constans/colors';
import { Tr } from '../VacacionesViews/styled';
import moment from 'moment';
import { agregarIncapacidad } from '../../../store/incapacidadesDuck';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TableBody = React.memo(({listaIncapacidades = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((incapacidad) => {
        dispatch(agregarIncapacidad(incapacidad))
        history.push('/incapacidades')
    }, [dispatch]);
    console.log(listaIncapacidades);
    const now = moment().format('YYYY-MM-DD');
    return(
        <>
            {listaIncapacidades.map((incapacidad, index) => (
          <Tr
            key={incapacidad._id + index}
            onClick={() => showUtil(incapacidad)}
          >
            <td>{moment(incapacidad.fechaInicio).format('DD/MM/YYYY')}</td>
            <td>{moment(incapacidad.fechaFin).format('DD/MM/YYYY')}</td>
            <td>{incapacidad.duracion}</td>
            <td>{incapacidad.tipo}</td>
            <td>{incapacidad.observaciones}</td>
          </Tr>
        ))}
        </>
    );
});

export default TableBody;