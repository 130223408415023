import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  Table,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
} from "reactstrap";
import { StyleInputs } from "./styles/styles";
import { H3 } from "./styles/PrestacionesStyles";
import PrestacionesFormula from "./PrestacionesModal";
import axios from "../../services/axios";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import sweet from "sweetalert2";
import BotonCargando from "../BotonCargando";
import SweetAlert from "../ui/SweetAlet";

const tipoSalario = {
  $: "$",
  "%": "% del salario diario",
  vecesSalarioMinimo: "Veces salario mínimo",
  UMA: "UMA",
};

export default ({ onCancel, prestacion, text, prestacionPaquete }) => {
  const [toggleCantidad, setToggleCantidad] = useState(false);
  const [tipoOp, setTipoOp] = useState("$");
  const [showModal, setShowModal] = useState(false);
  const { _id } = useSelector((state) => state.empresas);
  const [paquete, setPaquete] = useState({ nombre: "", empresaId: _id, prestaciones: [] });
  const [prestaciones, setPrestaciones] = useState([]);
  const [tmpPaqt, settmpPaqt] = useState({ cuenta: "", nombreCorto: "", prestacion: {}, tipoCalc: "fijo" });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token")
    }
  };
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (prestacionPaquete && prestacionPaquete._id) {
      setPaquete(prestacionPaquete);
    } else {
      setPaquete({ nombre: "", empresaId: _id, prestaciones: [] });
    }
  }, []);

  useEffect(() => {
    getPrestaciones();
  }, [prestacion]);

  const getPrestaciones = async () => {
    let query = `sat=true&catalogo=${prestacion}`
    if (prestacion === 'percepciones') query += '&tipo=Ordinaria'
    const { data } = await axios.get(`catalogos/?${query}`, headers);
    setPrestaciones(data.response);
  };

  useEffect(() => {
    if (tmpPaqt.tipoCalc === "formula") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [tmpPaqt.tipoCalc]);

  const addPrestacion = () => {
    if (isPreatacionInvalid()) return;
    const prest = { ...tmpPaqt.prestacion };
    delete tmpPaqt.prestacion;
    tmpPaqt.codigo = prest.codigo;
    tmpPaqt.descripcion = prest.descripcion;
    if (tmpPaqt.tipoCalc === "fijo") {
      tmpPaqt.tipoOp = tipoOp;
    }
    console.log(tmpPaqt);
    paquete.prestaciones = [...paquete.prestaciones, tmpPaqt];
    setPaquete({ ...paquete });
    settmpPaqt({ cuenta: "", nombreCorto: "", prestacion: { codigo: "" }, tipoCalc: "fijo" });
  };

  const onSetFormula = (formula) => {
    console.log(formula);
    settmpPaqt({ ...tmpPaqt, ...formula });
    setShowModal(false);
  };
  const isPreatacionInvalid = () => {
    let invalid = false;
    if (tmpPaqt.tipoCalc === "fijo") {
      invalid = tmpPaqt.cantidad === "" || tmpPaqt.cantidad === undefined;
    }
    return [undefined, ""].includes(tmpPaqt.prestacion.codigo) || tmpPaqt.cuenta === "" || invalid;
  };
  const onDeletePrestacion = async (id) => {
    let result = await sweet.fire({
      title: "Borrar ",
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      paquete.prestaciones = paquete.prestaciones.filter((v, i) => i !== id);
      setPaquete({ ...paquete });
    }
  };

  const onGuardar = async () => {
    try {
      setSaving(true);

      if (!paquete._id) {
        console.log("new");
        paquete.prestacion = prestacion;
        paquete.fechaCreacion = Date.now();
        await axios.post("/nomina/prestaciones/paquetes/", paquete, headers);
      } else {
        await axios.put(`/nomina/prestaciones/paquetes/${paquete.empresaId}/${paquete._id}/`, paquete, headers);
      }
      onCancel();
    } catch (error) {
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {showModal && <PrestacionesFormula open={showModal} toggle={() => settmpPaqt({ ...tmpPaqt, tipoCalc: "fijo" })} onSave={onSetFormula} />}
      <Row>
        <StyleInputs>
          <Col>
            <H3>Paquete {text}</H3>
          </Col>
          <Col className="text-right">
            <Button color="secondary" onClick={onCancel}>
              Cancelar
            </Button>{" "}
            <BotonCargando
              cargando={saving}
              disabled={saving || paquete.nombre === "" || paquete.prestaciones.length === 0}
              texto='Guardar'
              onClick={onGuardar}
            />
          </Col>
        </StyleInputs>
      </Row>
      <FormGroup row>
        <Label className="col-2">Nombre*</Label>
        <Col className="col-6">
          <Input type="text" value={paquete.nombre} placeholder="Escribe el  nombre del paquete" required onChange={(e) => setPaquete({ ...paquete, nombre: e.target.value })} />
        </Col>
      </FormGroup>
      <Row>
        <Col>
          <StyleInputs>
            <h4>Agregar {text}</h4>
          </StyleInputs>
        </Col>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>
                <Input
                  style={{ width: "200px" }}
                  type="select"
                  name="select"
                  value={tmpPaqt.prestacion.codigo}
                  onChange={(e) => settmpPaqt({ ...tmpPaqt, prestacion: prestaciones.find((p) => p.codigo === e.target.value) || { codigo: "" } })}
                >
                  <option value={""}>Seleccione..</option>
                  {prestaciones.map((p, idx) => (
                    <option key={p.codigo} value={p.codigo}>
                      {p.descripcion}
                    </option>
                  ))}
                </Input>
              </th>
              <th>
                <Input type="number" min="0" placeholder="Cuenta" value={tmpPaqt.cuenta} onChange={(e) => settmpPaqt({ ...tmpPaqt, cuenta: e.target.value })} />
              </th>
              <th>
                <Input type="text" placeholder="Nombre corto" value={tmpPaqt.nombreCorto} onChange={(e) => settmpPaqt({ ...tmpPaqt, nombreCorto: e.target.value })} />
              </th>
              <th>
                <Input type="select" name="select" onChange={(e) => settmpPaqt({ ...tmpPaqt, tipoCalc: e.target.value })} value={tmpPaqt.tipoCalc}>
                  <option value="personalizado">Personalizado</option>
                  <option value="fijo">Fijo</option>
                  {/* <option value="formula">Formula</option> */}
                </Input>
              </th>
              {tmpPaqt.tipoCalc === "fijo" && (
                <th>
                  <InputGroup color="secondary">
                    <Input placeholder="Cantidad" type="number" min="0" value={tmpPaqt.cantidad || ""} onChange={(e) => settmpPaqt({ ...tmpPaqt, cantidad: e.target.value })} />
                    <InputGroupButtonDropdown addonType="append" isOpen={toggleCantidad} toggle={() => setToggleCantidad(!toggleCantidad)}>
                      <DropdownToggle caret style={{ background: "#2b388f" }}>
                        {tipoSalario[tipoOp]}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.keys(tipoSalario).map((k) => (
                          <DropdownItem key={k} onClick={() => setTipoOp(k)}>
                            {tipoSalario[k]}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </InputGroupButtonDropdown>
                  </InputGroup>
                </th>
              )}
              {tmpPaqt.tipoCalc === "formula" && (
                <th>
                  <Input type="text" readOnly value={tmpPaqt.monto} />{" "}
                </th>
              )}
              {tmpPaqt.tipoCalc === "formula" && (
                <th>
                  <ButtonGroup>
                    <Button style={{ background: tmpPaqt.IMSS ? "#161c44" : "#2b388f" }} color="primary">
                      IMSS
                    </Button>
                    <Button style={{ background: tmpPaqt.ISR ? "#161c44" : "#2b388f" }} color="primary">
                      ISR
                    </Button>
                  </ButtonGroup>
                </th>
              )}
              <th>
                <StyleInputs>
                  <Button disabled={isPreatacionInvalid()} size="sm" style={{ width: "50px" }} color="primary" onClick={addPrestacion}>
                    +
                  </Button>
                </StyleInputs>
              </th>
            </tr>
          </thead>
          <tbody>
            {paquete.prestaciones.map((pr, id) => {
              return (
                <tr key={id}>
                  <td>{pr.codigo}</td>
                  <td>{pr.cuenta}</td>
                  <td>{pr.nombreCorto}</td>
                  <td>{pr.tipoCalc}</td>
                  <td>
                    {pr.tipoCalc === "fijo" && `Valor ${pr.cantidad} tipo ${tipoSalario[pr.tipoOp]}`}
                    {pr.tipoCalc === "personalizado" && `Asignado al colaborador`}
                    {pr.tipoCalc === "formula" && `${pr.monto} | ${[(pr.ISR && "ISR") || "", (pr.IMSS && "IMSS") || ""].join(",")} `}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faTrash} className="mx-1" cursor={"pointer"} onClick={() => onDeletePrestacion(id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
};
